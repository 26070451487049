import React, { ReactNode, useCallback, useState } from 'react';
import { InputAttributes, NumericFormatProps } from 'react-number-format';
import { TEXT_INPUT_DEBOUNCE_TIME, TRANSLATIONS } from '../../constants';
import { getNotNegativeValidationMessage, getPercentageValidationMessage, isNotNegative, isNotNegativeOrEmpty, isPercentage } from '../../services';
import { useCurrency } from '../../hooks';
import { useAppDispatch, useTranslate, useValidate } from '../../hooks/common';
import { setMepsWarningModalOpen } from '../../store';
import { IconType } from '../common/Icon';
import Flex from '../common/Flex';
import IconButton from '../common/IconButton';
import InputValidation from '../common/InputValidation';
import Line from '../common/Line';
import Link from '../common/Link';
import Modal from '../common/Modal';
import SinaSaveImage, { imageNames } from '../common/SinaSaveImage';
import Text from '../common/Text';
import TextInput from '../common/TextInput';

export interface PriceDataGroupProps {
    customerPrice: string;
    description: string;
    discount: string;
    expenditureForRepairs: string;
    imageId: imageNames;
    listPrice?: string;
    title: string;
    dark?: boolean;
    modalInfoData?: ReactNode;
    warning?: boolean;
    onCustomerPriceChange?: (value: string, name: string) => void;
    onDiscountChange?: (value: string, name: string) => void;
    onExpenditureForRepairsChange?: (value: string) => void;
    onTechnicalDataClick: () => void;
}

const PriceDataGroup = ({ customerPrice, description, discount, expenditureForRepairs, imageId, listPrice, title, dark, modalInfoData, warning, onDiscountChange, onCustomerPriceChange, onExpenditureForRepairsChange, onTechnicalDataClick }: PriceDataGroupProps) => {
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const handleCloseModalClick = useCallback(() => setInfoModalOpen(false), []);
    const handleOpenModalClick = useCallback(() => setInfoModalOpen(true), []);
    const { translate } = useTranslate();
    const dispatch = useAppDispatch();
    const handleWarningClick = useCallback(() => dispatch(setMepsWarningModalOpen(true)), []);
    const { currencyFormat } = useCurrency();

    const numberFormatOptions: NumericFormatProps<InputAttributes> = {
        thousandSeparator: ',',
        allowNegative: false,
        decimalSeparator: '.',
        decimalScale: 2,
        allowLeadingZeros: false
    };

    const {
        value: discountInputValue,
        setValue: setDiscountInputValue
    } = useValidate(discount, isPercentage, onDiscountChange, TEXT_INPUT_DEBOUNCE_TIME);

    const {
        value: customerPriceInputValue,
        setValue: setCustomerPriceInputValue
    } = useValidate(customerPrice, isNotNegativeOrEmpty, onCustomerPriceChange, TEXT_INPUT_DEBOUNCE_TIME);

    const {
        value: expenditureForRepairsInputValue,
        setValue: setExpenditureForRepairsInputValue
    } = useValidate(expenditureForRepairs, isNotNegative, onExpenditureForRepairsChange, TEXT_INPUT_DEBOUNCE_TIME);

    return (
        <div style={{ paddingTop: 40 }}>
            <div style={{ display: 'grid', gridTemplate: '1fr / 100px auto' }}>
                <SinaSaveImage imageName={imageId} dark={dark} style={{ maxWidth: 100, height: 'unset' }} />
                <div style={{ minWidth: 0 }}>
                    <div>
                        <Flex justification='space-between' gap={8}>
                            <Text
                                type={'subtitle-very-small'}
                                title={title}
                                dark={dark}
                                style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                            >
                                {title}
                            </Text>
                            <Flex gap={8}>
                                {warning && <IconButton statusIconType={'warning'} size={16} layer={2} dark={dark} onClick={handleWarningClick} />}
                                {modalInfoData && <IconButton dark={dark} iconType={IconType.Information} size={16} layer={2} onClick={handleOpenModalClick} />}
                            </Flex>
                        </Flex>
                        <Text
                            type={'subtitle-very-small-light'}
                            title={description}
                            dark={dark}
                            style={{ overflow: 'hidden', paddingTop: 4, textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                        >
                            {description}
                        </Text>
                    </div>
                    {listPrice ? <div>
                        <div style={{ paddingTop: 16 }}>
                            <TextInput
                                dark={dark}
                                label={translate(TRANSLATIONS.generated['Commercial.ListPrice'])}
                                name={'listPrice'}
                                numberFormatOptions={currencyFormat}
                                readOnly
                                value={listPrice}
                                withFullBorder
                            />
                        </div>
                        <div style={{ paddingTop: 16 }}>
                            <InputValidation
                                valid={isPercentage(discountInputValue)}
                                message={getPercentageValidationMessage(translate)}
                                dark={dark}
                            >
                                <TextInput
                                    dark={dark}
                                    label={translate(TRANSLATIONS.generated['Commercial.Discount'])}
                                    name={'discount'}
                                    numberFormatOptions={{ ...numberFormatOptions, suffix: '%' }}
                                    onChange={setDiscountInputValue}
                                    value={discountInputValue}
                                    error={!isPercentage(discountInputValue)}
                                />
                            </InputValidation>
                        </div>
                        <Line dark={dark} style={{ marginTop: 16, marginBottom: 16 }} />
                        <div>
                            <InputValidation
                                valid={isNotNegativeOrEmpty(customerPriceInputValue)}
                                message={getNotNegativeValidationMessage(translate)}
                                dark={dark}
                            >
                                <TextInput
                                    dark={dark}
                                    label={translate(TRANSLATIONS.generated['Commercial.CustomerPrice'])}
                                    name={'customerPrice'}
                                    numberFormatOptions={currencyFormat}
                                    onChange={setCustomerPriceInputValue}
                                    value={customerPriceInputValue}
                                    error={!isNotNegativeOrEmpty(customerPriceInputValue)}
                                />
                            </InputValidation>
                        </div>
                        <Flex style={{ paddingTop: 24, paddingBottom: 8 }}>
                            <Link
                                onClick={onTechnicalDataClick}
                                icon={IconType.LinkExtern}
                                iconPosition='left'
                                text={translate(TRANSLATIONS.generated['Motor.GetTechnicalData'])}
                                dark={dark}
                                noWrap
                            />
                        </Flex>
                    </div>
                        : <div style={{ paddingTop: 16, paddingBottom: 185 }}>
                            <InputValidation
                                valid={isNotNegative(expenditureForRepairsInputValue)}
                                message={getNotNegativeValidationMessage(translate)}
                                dark={dark}
                            >
                                <TextInput
                                    dark={dark}
                                    label={translate(TRANSLATIONS.generated['System.Price'])}
                                    name={'expenditureForRepairs'}
                                    numberFormatOptions={currencyFormat}
                                    onChange={setExpenditureForRepairsInputValue}
                                    value={expenditureForRepairsInputValue}
                                    error={!isNotNegative(expenditureForRepairsInputValue)}
                                />
                            </InputValidation>
                        </div>}
                </div>
            </div>
            {modalInfoData && (
                <Modal open={infoModalOpen} allowClickAway movable onClose={handleCloseModalClick} dark={dark} key={'info-modal'}>
                    {modalInfoData}
                </Modal>
            )}
        </div>
    );
};

export default PriceDataGroup;
