import React, { useCallback, useMemo } from 'react';
import { numericFormatter } from 'react-number-format';
import { TRANSLATIONS } from '../../../constants';
import { useHook } from '../../../hooks';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { SystemConfiguration } from '../../../models';
import Checkbox from '../../common/Checkbox';
import ComparisonCardTab from './ComparisonCardTab';
import PowerlossCalculationChart from '../Charts/PowerlossCalculationChart/PowerlossCalculationChart';
import { ApplicationType } from '../../../types';
import ComparisonCardPowerLossInfo from './ModalInfo/ComparisonCardPowerLossInfo';

export interface PowerlossCalculationTabProps {
    applicationType: ApplicationType;
}

const PowerlossCalculationTab = ({ applicationType }: PowerlossCalculationTabProps) => {
    const dark = useAppSelector(x => x.layout.darkMode);
    const isPumpSystem = applicationType === 'pump';
    const systemState = useAppSelector(state => isPumpSystem ? state.pumpSystem : state.fanSystem);
    const chartData = useAppSelector(state => isPumpSystem ? state.pumpSystemChart.powerlossCalculationChartData : state.fanSystemChart.powerlossCalculationChartData);
    const { regionSettings, numberFormatOptions } = useAppSelector(state => state.regionSettings);
    const { handlePowerLossAtRequiredShaftPowerChange, handleShowReferenceIesPowerLoss, chartOptionParameters } = useHook(x => x.powerlossCalculation({ applicationType }));
    const { dynamicTranslate, translate } = useTranslate();
    const powerUnit = useMemo(() => dynamicTranslate(TRANSLATIONS.generated, `Unit.${regionSettings?.powerUnit?.toUpperCase()}`), [dynamicTranslate, regionSettings?.powerUnit]);
    const fullScreenChart = useAppSelector(x => isPumpSystem ? x.pumpSystem.fullScreenChart : x.fanSystem.fullScreenChart);
    const formatValues = useCallback((value: number | undefined) => value === undefined ? '' : numericFormatter(value.toString(), numberFormatOptions), [numberFormatOptions]);

    const alternativeAttributeLabel = useMemo(() => !chartOptionParameters.powerLossAtRequiredShaftPower
        ? systemState.alternativeSystem?.iesSystemData?.systemEfficiencyClass ?? ''
        : '',
        [chartOptionParameters.powerLossAtRequiredShaftPower, systemState.alternativeSystem?.iesSystemData?.systemEfficiencyClass]);

    const referenceAttributeLabel = useMemo(() => !chartOptionParameters.powerLossAtRequiredShaftPower ? systemState.referenceSystem?.iesSystemData?.systemEfficiencyClass ?? '' : '',
        [chartOptionParameters.powerLossAtRequiredShaftPower, systemState.referenceSystem?.iesSystemData?.systemEfficiencyClass]);
   
    const getSystemAttributeValue = useCallback((systemConfiguration: SystemConfiguration | null) => systemConfiguration?.iesSystemData
        ? `PL ${formatValues(systemConfiguration?.iesSystemData?.powerLosses.find(x => x.speed == 100 && x.torque == 100)?.value)} ${powerUnit} ${translate(TRANSLATIONS.generated['System.At'])} ${chartOptionParameters.powerLossAtRequiredShaftPower ? 'P2' : 'PN'} ${chartOptionParameters.powerLossAtRequiredShaftPower
            ? formatValues(systemState.application?.shaftPower)
            : formatValues(systemConfiguration?.motorComponent.selectedMotor.power)} ${powerUnit}`
        : translate(TRANSLATIONS.generated['System.PowerLossNotAvailable']),
        [chartOptionParameters.powerLossAtRequiredShaftPower, formatValues, powerUnit, systemState.application?.shaftPower, translate]
    );

    const referenceIesCheckbox = [
        <Checkbox
            checked={chartOptionParameters.showReferenceIesPowerLoss ?? false}
            onChange={handleShowReferenceIesPowerLoss}
            dark={dark}
            label={translate(TRANSLATIONS.generated['System.ReferencePDS'])}
            key='iesReference-checkbox'
        />
    ];

    return (
        <ComparisonCardTab
            applicationType={applicationType}
            chart={<PowerlossCalculationChart data={chartData} />}
            checkboxes={[
                <Checkbox
                    checked={chartOptionParameters.powerLossAtRequiredShaftPower ?? false}
                    onChange={handlePowerLossAtRequiredShaftPowerChange}
                    dark={dark}
                    label={translate(TRANSLATIONS.generated['System.PowerLossAtRequiredShaftPower'])}
                    key='system-checkbox'
                />,
                ...fullScreenChart ? referenceIesCheckbox : []
                
            ]}
            attributeLabel={translate(TRANSLATIONS.generated['System.Motor.EfficiencyClass'])}
            alternativeSystemAttributes={[
                {
                    label: alternativeAttributeLabel,
                    value: getSystemAttributeValue(systemState.alternativeSystem)
                }
            ]}
            referenceSystemAttributes={[
                {
                    label: referenceAttributeLabel,
                    value: getSystemAttributeValue(systemState.referenceSystem)
                }
            ]}
            modalContent={<ComparisonCardPowerLossInfo />}
        />
    );
};

export default PowerlossCalculationTab;
