import React, { KeyboardEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../hooks/common';
import { useNavigate } from 'react-router';
import { HeaderTab } from '../../enums';

export interface Tab {
    title: string;
    to: string;
    type: HeaderTab;
}

interface HeaderTabsProps {
    hidden: boolean;
    tabs: Tab[];
    dark?: boolean;
}

const HeaderTabs = ({ dark, hidden, tabs }: HeaderTabsProps) => {
    const activeTabType = useAppSelector(state => state.layout.activeTab);
    const navigate = useNavigate();
    const [underline, setUnderline] = useState({ left: -1, width: -1 });
    const activeTabRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setUnderlineWithTab(activeTabRef.current);
    }, [activeTabType, tabs]);

    const handleSelect = (tab: Tab) => {
        navigate(tab.to);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>, tab: Tab) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleSelect(tab);
        }
    };

    const handleMouseEnter = (event: MouseEvent<HTMLDivElement>) => {
        setUnderlineWithTab(event.currentTarget);
    };

    const handleMouseLeave = () => {
        setUnderlineWithTab(activeTabRef.current);
    };

    const setUnderlineWithTab = (tab: HTMLDivElement | null) => {
        setUnderline({ left: tab?.offsetLeft ?? -1, width: tab?.offsetWidth ?? -1 });
    };

    return (
        <div className={`header-tabs ${dark ? 'dark' : ''}`}>
            {tabs.map(x =>
                <div ref={x.type === activeTabType ? activeTabRef : null} key={x.title} className='tab' tabIndex={hidden ? undefined : 0} onClick={() => handleSelect(x)} onKeyDown={e => handleKeyDown(e, x)}
                    onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <div >
                        {x.title}
                    </div>
                </div>
            )}
            {underline.left >= 0 && underline.width >= 0 &&
                <div className='header-tab-underline' style={{ left: underline.left, width: underline.width }} />
            }
        </div>
    );
};

export default HeaderTabs;
