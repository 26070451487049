import { useMemo } from 'react';
import { AllocationProfile, OperatingHour, UiText } from '../../models';
import { ApplicationType } from '../../types';
import { useAppSelector } from '../common';
import { useHook } from '../Hooks';

export interface UiTextProps {
    applicationType: ApplicationType;
}

export const useUiText = ({ applicationType }: UiTextProps) => {
    const isPumpSystem = applicationType === 'pump';
    const { application } = useAppSelector(state => isPumpSystem ? state.pumpSystem : state.fanSystem);
    const { getOperatingHourValue, getAllocationProfileName } = useHook(x => x.systemsLoad({ applicationType }));

    const allocationProfile = useMemo(() => application?.operationProfile.allocationProfile ?? {} as AllocationProfile, [application?.operationProfile.allocationProfile]);
    const operatingHour = useMemo(() => application?.operationProfile.operatingHour ?? {} as OperatingHour, [application?.operationProfile.operatingHour]);

    const allocationProfileName = getAllocationProfileName(allocationProfile);
    const workingHoursPerYearString = getOperatingHourValue(operatingHour);

    const uiText: UiText = {
        allocationProfileName: allocationProfileName,
        co2SavingsUnit: 'System.Units.Tonnsperannual',
        energySavingsUnit: 'System.Units.Megawatthourperannual',
        workingHoursPerYearString: workingHoursPerYearString
    };

    return uiText;
};
