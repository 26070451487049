import React from 'react';
import { TRANSLATIONS } from '../../../../constants';
import { Language } from '../../../../enums';
import { useAppSelector, useTranslate } from '../../../../hooks/common';
import Link from '../../../common/Link';
import Text from '../../../common/Text';
import { InputItemList } from '../../../../models';
import { GetImageDropdownItem } from '../../../../services';

export interface BasicViewSwitchGearInfoProps {
    inputs: InputItemList;
}

const BasicViewSwitchGearInfo = ({ inputs }: BasicViewSwitchGearInfoProps) => {
    const dark = useAppSelector(state => state.layout.darkMode);
    const language = useAppSelector(state => state.translation.language);
    const { translate } = useTranslate();
    const linkEnglish = 'https://new.siemens.com/global/en/products/automation/industrial-controls/media/energy-efficiency.html';
    const linkGerman = 'https://new.siemens.com/de/de/produkte/automatisierung/industrielle-schalttechnik/media/energieeffizienz.html';
    const imageDropdownItem = GetImageDropdownItem(inputs);
    const selectedValueTitle = imageDropdownItem.selectedValue.title;

    const siriusInfo = (
        <>
            <Text dark={dark} type='subtitle' style={{ paddingBottom: 8 }}>
                {translate(TRANSLATIONS.generated['Help.SwitchgearTechnical.Title'])}
            </Text>
            <ul>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.SwitchgearTechnical.Content'][0])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.SwitchgearTechnical.Content'][1])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.SwitchgearTechnical.Content'][2])}
                    </Text>
                    <ul>
                        <li>
                            <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                                <Link dark={dark} text={translate(TRANSLATIONS.generated['Help.SwitchgearTechnical.Content'][3])}
                                    onClick={() => window.open(language === Language.En ? linkEnglish : linkGerman, '_blank')} />
                            </Text>
                        </li>
                    </ul>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.SwitchgearTechnical.Content'][4])}
                    </Text>
                    <ul>
                        <li>
                            <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                                {translate(TRANSLATIONS.generated['Help.SwitchgearTechnical.Content'][5])}
                            </Text>
                        </li>
                        <li>
                            <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                                {translate(TRANSLATIONS.generated['Help.SwitchgearTechnical.Content'][6])}
                            </Text>
                        </li>
                        <li>
                            <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                                {translate(TRANSLATIONS.generated['Help.SwitchgearTechnical.Content'][7])}
                            </Text>
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    );

    const sinamicsInfoEN = (
        <>
            <Text dark={dark} type='subtitle' style={{ paddingBottom: 8 }}>
                {translate(TRANSLATIONS.generated['Help.ConverterTechnical.Title'])}
            </Text>
            <ul>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.ConverterTechnical.Content'][0])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.ConverterTechnical.Content'][1])}
                    </Text>
                </li>
                <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                    {translate(TRANSLATIONS.generated['Help.ConverterTechnical.Content'][2])}
                </Text>
                <ul>
                    <li>
                        <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                            {translate(TRANSLATIONS.generated['Help.ConverterTechnical.Content'][3])}
                        </Text>
                    </li>
                    <li>
                        <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                            {translate(TRANSLATIONS.generated['Help.ConverterTechnical.Content'][4])}
                        </Text>
                    </li>
                </ul>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.ConverterTechnical.Content'][5])}
                    </Text>
                </li>
            </ul>
        </>
    );

    const sinamicsInfoDE = (
        <>
            <Text dark={dark} type='subtitle' style={{ paddingBottom: 8 }}>
                {translate(TRANSLATIONS.generated['Help.ConverterTechnical.Title'])}
            </Text>
            <ul>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.ConverterTechnical.Content'][0])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.ConverterTechnical.Content'][1])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.ConverterTechnical.Content'][2])}
                        {translate(TRANSLATIONS.generated['Help.ConverterTechnical.Content'][3])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.ConverterTechnical.Content'][4])}
                    </Text>
                </li>
            </ul>
        </>
    );

    const renderInfo = (selectedValueTitle: string) => {
        switch (selectedValueTitle) {
            case 'System.Converter.Siemens':
            case 'System.Converter.ThirdParty':
                return language === Language.En ? sinamicsInfoEN : sinamicsInfoDE;
            case 'System.Switchgear.Siemens':
            case 'System.Switchgear.ThirdParty':
                return siriusInfo;
            default:
                break;
        }
    };

    return (
        <>
            {renderInfo(selectedValueTitle)}
        </>
    );
};

export default BasicViewSwitchGearInfo;
