import React, { CSSProperties, ReactNode } from 'react';

interface BoxProps {
    children: ReactNode;
    edgeColor?: 'reference' | 'alternative';
    dark?: boolean;
    backgroundColor?: 'green' | 'yellow';
    layer?: 1 | 2;
    smallPadding?: boolean;
    style?: CSSProperties;
}

const Box = ({ children, edgeColor, dark, backgroundColor, layer, smallPadding, style }: BoxProps) => {
    const boxBackgroundColor = backgroundColor ? `colored-background background-color-${backgroundColor}` : '';
    const boxEdgeColor = edgeColor ? `colored-edge edge-color-${edgeColor}` : '';

    return (
        <div className={`box box-layer-${layer ?? 1} ${smallPadding ? 'box-small-padding' : ''} ${dark ? 'dark' : ''} ${boxEdgeColor} ${boxBackgroundColor}`} style={style} >
            {children}
        </div>);
};

export default Box;
