import React, { CSSProperties } from 'react';

interface LineProps {
    dark?: boolean;
    style?: CSSProperties;
}

const Line = ({ dark, style }: LineProps) => (
    <div className={`line ${dark ? 'dark' : ''}`} style={style} />
);

export default Line;
