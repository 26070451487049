import React, { KeyboardEvent, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setDarkMode, setLanguage, setMegaMenuOpen } from '../../store';
import { HeaderTab, Language } from '../../enums';
import { TRANSLATIONS } from '../../constants';
import { useAppDispatch, useAppSelector, useResized, useTranslate } from '../../hooks/common';
import ContentContainer from '../common/ContentContainer';
import { IconType } from '../common/Icon';
import IconButton from '../common/IconButton';
import HoverMenu from '../common/HoverMenu';
import MenuDividerItem from '../common/MenuDividerItem';
import MenuItem from '../common/MenuItem';
import MenuLabelItem from '../common/MenuLabelItem';
import HeaderTabs, { Tab } from './HeaderTabs';
import { ReactComponent as SiemensLogo } from '../../../assets/images/siemens-logos/siemens-logo.svg';

type LanguageType = 'en' | 'de';

interface HeaderProps {
    title?: string;
}

const Header = ({ title }: HeaderProps) => {
    const dispatch = useAppDispatch();
    const activeTabType = useAppSelector(state => state.layout.activeTab);
    const dark = useAppSelector(state => state.layout.darkMode);
    const navigate = useNavigate();
    const { translate } = useTranslate();
    const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
    const mobileView = useResized(x => x <= 767);
    const languages = Object.keys(Language).slice(0, Math.ceil(Object.keys(Language).length / 2)).map(x => Number(x));
    const home = activeTabType === HeaderTab.Home;

    const tabs = useMemo((): Tab[] => [
        { title: translate(TRANSLATIONS.main.home), to: '/', type: HeaderTab.Home },
        { title: translate(TRANSLATIONS.generated['Home.PumpSystems']), to: '/pump-systems/basic', type: HeaderTab.PumpSystems },
        { title: translate(TRANSLATIONS.generated['Home.FanSystems']), to: '/fan-systems/basic', type: HeaderTab.FanSystems }
    ], [translate]);

    const handleSkipButtonKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();

            location.hash = '';
            location.hash = '#main';
        }
    };

    return (
        <header className={dark ? 'dark' : ''}>
            <div className='main-row-container'>
                <ContentContainer>
                    <div className='main-row'>
                        <div className='skip-button' tabIndex={0} onKeyDown={handleSkipButtonKeyDown}>
                            {translate(TRANSLATIONS.header.skipToMainContent)}
                        </div>
                        <SiemensLogo className='siemens-logo' />
                        {title &&
                            <div className='header-title'>{title}</div>
                        }
                        <div className='header-buttons'>
                            <IconButton dark={dark} iconType={IconType.Moon} layer={2} transparent={home} onClick={() => dispatch(setDarkMode(!dark))} />
                            <HoverMenu
                                open={languageMenuOpen}
                                toggleElement={
                                    <IconButton dark={dark} iconType={IconType.Language} open={languageMenuOpen} layer={2} transparent={home} onClick={() => setLanguageMenuOpen(!languageMenuOpen)} />
                                }
                                onOpenChange={setLanguageMenuOpen}
                                dark={dark}
                                position='left'
                            >
                                <MenuLabelItem label={translate(TRANSLATIONS.header.language)} dark={dark} />
                                <MenuDividerItem dark={dark} />
                                {languages.map(x =>
                                    <MenuItem
                                        key={x}
                                        label={translate(TRANSLATIONS.header.languages[Language[x].toLowerCase() as LanguageType])}
                                        onClick={() => dispatch(setLanguage(x))}
                                        dark={dark}
                                    />
                                )}
                            </HoverMenu>
                            <IconButton dark={dark} iconType={IconType.Settings} layer={2} transparent={home} onClick={() => navigate('/settings')} />
                            <div className='mega-menu-button'>
                                <IconButton dark={dark} iconType={IconType.Menu} layer={2} transparent={home} onClick={() => dispatch(setMegaMenuOpen(true))} />
                            </div>
                        </div>
                    </div>
                </ContentContainer>
            </div>
            <div className='addtitional-rows-container'>
                <div className='secondary-row-container'>
                    <ContentContainer>
                        <div className='secondary-row'>
                            <HeaderTabs hidden={mobileView} dark={dark} tabs={tabs} />
                        </div>
                    </ContentContainer>
                </div>
            </div>
        </header>
    );
};

export default Header;
