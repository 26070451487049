import { useCallback, useRef } from 'react';

export const useDebounceCallback = <T extends unknown[]>(
    callback: (...args: T) => void,
    delay?: number
) => {
    const argsRef = useRef<T>();
    const timerRef = useRef<ReturnType<typeof setTimeout>>();

    const debouncedCallback = useCallback(
        (...args: T) => {
            argsRef.current = args;

            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }

            timerRef.current = setTimeout(() => {
                if (argsRef.current) {
                    callback(...argsRef.current);
                }
            }, delay || 500);
        },
        [callback, delay]
    );
    return debouncedCallback;
};
