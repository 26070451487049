import React, { useCallback, useMemo } from 'react';
import { ImageDropdownItem } from '../../../models';
import { DataType } from '../../../enums';
import { TRANSLATIONS } from '../../../constants';
import { useTranslate } from '../../../hooks/common';
import ImageDropdown, { ImageDropdownData } from '../../common/ImageDropdown';
import SinaSaveImage from '../../common/SinaSaveImage';

export interface ImageDropdownElementProps {
    input: ImageDropdownItem;
    dark: boolean;
    handleSystemInputChange: (inputId: string, value: string, dataType?: DataType) => void;
    getAvailableSpace: () => number;
}

const ImageDropdownElement = ({ input, dark, handleSystemInputChange, getAvailableSpace }: ImageDropdownElementProps) => {
    const { dynamicTranslate } = useTranslate();

    const handleImageDropdownInputChange = useCallback((value: ImageDropdownData) => handleSystemInputChange(input.id, value.key.toString(), input.dataType), [handleSystemInputChange, input.dataType, input.id]);

    const selectedValue: ImageDropdownData = useMemo(() => ({
        description: dynamicTranslate(TRANSLATIONS.generated, input.selectedValue?.description),
        key: input.selectedValue?.id,
        title: dynamicTranslate(TRANSLATIONS.generated, input.selectedValue?.title),
        disabled: input.selectedValue?.isDisabled,
        image: <SinaSaveImage imageName={input.selectedValue?.imageId} dark={dark} />,
        descriptionImage: <SinaSaveImage imageName={input.selectedValue?.descriptionImageId ?? input.selectedValue?.imageId} dark={dark} />
    }), [dynamicTranslate, input.selectedValue?.description, input.selectedValue?.id, input.selectedValue?.title, input.selectedValue?.isDisabled, input.selectedValue?.imageId, input.selectedValue?.descriptionImageId, dark]);

    const availableValues: ImageDropdownData[] = useMemo(() => input.values.map(x => ({
        description: dynamicTranslate(TRANSLATIONS.generated, x.description),
        key: x.id,
        title: dynamicTranslate(TRANSLATIONS.generated, x.title),
        disabled: x.isDisabled,
        image: <SinaSaveImage imageName={x.imageId} dark={dark} />,
        descriptionImage: <SinaSaveImage imageName={x.descriptionImageId ?? x.imageId} dark={dark} />
    })), [dark, dynamicTranslate, input.values]);

    return (
        <div className='system-element-image-dropdown'>
            <ImageDropdown
                value={selectedValue}
                values={availableValues}
                dark={dark}
                disabled={input.isDisabled}
                onSelect={handleImageDropdownInputChange}
                getAvailableSpace={getAvailableSpace}
            />
        </div>);
};

export default ImageDropdownElement;
