import { Range } from '../models';

export const SPEED_RANGES_50: Range[] = [
    { min: 700, max: 750 },
    { min: 901, max: 1000 },
    { min: 1400, max: 1500 },
    { min: 2800, max: 3000 }
];

export const SPEED_RANGES_60: Range[] = [
    { min: 800, max: 900 },
    { min: 1100, max: 1200 },
    { min: 1600, max: 1800 },
    { min: 3400, max: 3600 }
];
