import { configureHooks } from '../providers';
import { useAdditionalInvestments } from './application/AdditionalInvestments';
import { useBasicView } from './application/BasicView';
import { useDocumentExport } from './application/DocumentExport';
import { useEnergyPerformance } from './application/EnergyPerformance';
import { useEnergySavings } from './application/EnergySavings';
import { useLifetimeCosts } from './application/LifetimeCosts';
import { usePowerlossCalculation } from './application/PowerlossCalculation';
import { useRegionSettings } from './application/RegionSettings';
import { useSystemsLoad } from './application/SystemsLoad';
import { useUnloadAlert } from './application/UnloadAlert';

export const hooks = configureHooks({
    additionalInvestments: { hook: useAdditionalInvestments },
    basicView: { hook: useBasicView },
    energyPerformance: { hook: useEnergyPerformance },
    energySavings: { hook: useEnergySavings },
    lifetimeCosts: { hook: useLifetimeCosts },
    powerlossCalculation: { hook: usePowerlossCalculation },
    regionSettings: { hook: useRegionSettings, global: true },
    systemsLoad: { hook: useSystemsLoad },
    documentExport: { hook: useDocumentExport },
    unloadAlert: { hook: useUnloadAlert, global: true }
});

export const useHook = hooks.useHook;
