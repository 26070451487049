import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector, useTranslate } from '../../hooks/common';
import Modal from '../common/Modal';
import { setMepsWarningModalOpen } from '../../store';
import StatusIcon from '../common/StatusIcon';
import Flex from '../common/Flex';
import Link from '../common/Link';
import Text from '../common/Text';
import { TRANSLATIONS } from '../../constants';

const MepsWarningModal = () => {
    const { translate } = useTranslate();
    const link = 'https://meps.siemens.com';
    const dark = useAppSelector(state => state.layout.darkMode);
    const dispatch = useAppDispatch();
    const handleWarningClose = useCallback(() => dispatch(setMepsWarningModalOpen(false)), []);
    const warningModalOpen = useAppSelector(state => state.system.mepsWarningModalOpen);

    return (
        <>
            <Modal open={warningModalOpen} allowClickAway movable onClose={handleWarningClose} dark={dark} >
                <Text dark={dark} type='subtitle' style={{ paddingBottom: 8 }}>
                    {translate(TRANSLATIONS.generated['MepsWarning.Title'])}
                </Text>
                <Flex gap={20} >
                    <StatusIcon type='warning' dark={dark} width={110} />
                    <div>
                        <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                            {translate(TRANSLATIONS.generated['MepsWarning.Content'][0])}
                            <strong>{translate(TRANSLATIONS.generated['MepsWarning.Content'][1])}</strong>
                            {translate(TRANSLATIONS.generated['MepsWarning.Content'][2])}
                            <strong>{translate(TRANSLATIONS.generated['MepsWarning.Content'][3])}</strong>
                            {translate(TRANSLATIONS.generated['MepsWarning.Content'][4])}
                            <strong>{translate(TRANSLATIONS.generated['MepsWarning.Content'][5])}</strong>
                            <strong>{translate(TRANSLATIONS.generated['MepsWarning.Content'][6])}</strong>
                            {translate(TRANSLATIONS.generated['MepsWarning.Content'][7])}
                            {translate(TRANSLATIONS.generated['MepsWarning.Content'][8])}
                            {translate(TRANSLATIONS.generated['MepsWarning.Content'][9])}
                        </Text>
                        <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                            {translate(TRANSLATIONS.generated['MepsWarning.Content'][10])}
                            <Link dark={dark} text={translate(TRANSLATIONS.generated['MepsWarning.Content'][11])}
                                onClick={() => window.open(link, '_blank')} />
                        </Text>
                    </div>
                </Flex>
            </Modal>
        </>
    );
};

export default MepsWarningModal;
