import React, { RefObject } from 'react';
import { useAppSelector } from '../../hooks/common';
import { Language } from '../../enums';
import { MOTOR_TECHNICAL_DATA_INITIAL_PARAMS } from '../../constants';

interface TechnicalDataFormProps {
    formRef: RefObject<HTMLFormElement>
    mlfb: string | undefined
}

const MotorTechnicalDataForm = ({ formRef, mlfb }: TechnicalDataFormProps) => {
    const { regionSettings } = useAppSelector(state => state.regionSettings);
    const language = useAppSelector(state => state.translation.language);
    const languageStr = Language[language].toLowerCase();
    
    return (
        <div>
            <form ref={formRef} id='TechnicalDataForm' method='post' target='_blank' action='https://mall.industry.siemens.com/spice/portal/portal'>
                <input type='hidden' name='KMAT' />
                <input type='hidden' name='KMAT_INIT_TYPE' value={MOTOR_TECHNICAL_DATA_INITIAL_PARAMS.KMATInitType} />
                <input type='hidden' name='CALLER' value={MOTOR_TECHNICAL_DATA_INITIAL_PARAMS.Caller} />
                <input type='hidden' name='CONFIGID' value={MOTOR_TECHNICAL_DATA_INITIAL_PARAMS.ConfigId} />
                <input type='hidden' name='CATMODE' value={MOTOR_TECHNICAL_DATA_INITIAL_PARAMS.CATMode} />
                <input type='hidden' name='RETURNTARGET' value={MOTOR_TECHNICAL_DATA_INITIAL_PARAMS.ReturnTarget} />
                <input type='hidden' name='REGION' value={regionSettings?.countryCode?.toLowerCase()} />
                <input type='hidden' name='LANGUAGE' value={languageStr} />
                <input type='hidden' name='VERSION' value={MOTOR_TECHNICAL_DATA_INITIAL_PARAMS.Version} />
                <input type='hidden' name='CISCENARIO' value={MOTOR_TECHNICAL_DATA_INITIAL_PARAMS.CIScenario} />
                <input type='hidden' name='HOOKURL' value={MOTOR_TECHNICAL_DATA_INITIAL_PARAMS.HookUrl} />
                <input type='hidden' name='RETURNURL' value={MOTOR_TECHNICAL_DATA_INITIAL_PARAMS.ReturnUrl} />
                <input type='hidden' name='RESULTTYPE' value={MOTOR_TECHNICAL_DATA_INITIAL_PARAMS.ResultType} />
                <input type='hidden' name='MLFB' value={mlfb} />
                <input type='hidden' name='DIRECTINPUTCOLLECTION' value={`1:${mlfb}#`} />
            </form>
        </div>
    );
};

export default MotorTechnicalDataForm;
