import React, { CSSProperties, KeyboardEvent, ReactNode } from 'react';

export enum ButtonType {
    CallToAction,
    Primary,
    Secondary,
    Tertiary
}

interface ButtonProps {
    type: ButtonType;
    onClick: () => void;
    children?: ReactNode;
    dark?: boolean;
    disabled?: boolean;
    roundedBorder?: boolean;
    style?: CSSProperties;
}

const Button = ({ children, dark, disabled, roundedBorder, style, type, onClick }: ButtonProps) => {
    const handleClick = () => {
        if (!disabled) {
            onClick();
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();

        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleClick();
        }
    };

    const getButtonTypeClass = () => {
        switch (type) {
            case ButtonType.CallToAction:
                return 'call-to-action';
            case ButtonType.Primary:
                return 'primary';
            case ButtonType.Secondary:
                return 'secondary';
            case ButtonType.Tertiary:
                return 'tertiary';
        }
    };

    return (
        <div
            className={`button ${getButtonTypeClass()} ${disabled ? 'disabled' : ''} ${roundedBorder ? 'rounded-border' : ''} ${dark ? 'dark' : ''} `}
            tabIndex={disabled ? undefined : 0}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            style={style}
        >
            {children}
        </div>
    );
};

export default Button;
