import React, { ReactNode, useCallback, useState } from 'react';
import { TRANSLATIONS } from '../../constants';
import { useTranslate } from '../../hooks/common';
import TextInput from '../common/TextInput';
import Text from '../common/Text';
import Flex from '../common/Flex';
import SinaSaveImage, { imageNames } from '../common/SinaSaveImage';
import IconButton from '../common/IconButton';
import { IconType } from '../common/Icon';
import Modal from '../common/Modal';

export interface InfoDataGroupProps {
    application: string;
    controlMode: string;
    title: string;
    modalInfoData?: ReactNode;
    imageId?: imageNames;
    lineSupply: string;
    dark?: boolean;
}

const InfoDataGroup = ({ application, controlMode, title, modalInfoData, imageId, lineSupply, dark }: InfoDataGroupProps) => {
    const { translate } = useTranslate();
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const handleCloseModalClick = useCallback(() => setInfoModalOpen(false), []);
    const handleOpenModalClick = useCallback(() => setInfoModalOpen(true), []);

    return (
        <div style={{ paddingTop: 24 }}>
            <div style={{ display: 'grid', gridTemplate: '1fr / 100px auto' }}>
                <SinaSaveImage imageName={imageId} dark={dark} style={{ height: 'unset', paddingRight: 24 }} />
                <div style={{ minWidth: 0 }}>
                    <Flex justification='space-between' gap={8}>
                        <Text
                            type={'subtitle-very-small-light'}
                            title={title}
                            dark={dark}
                            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            {title}
                        </Text>
                        {modalInfoData && <IconButton dark={dark} iconType={IconType.Information} size={16} layer={2} onClick={handleOpenModalClick} />}
                    </Flex>
                    <TextInput label={translate(TRANSLATIONS.generated['Main.Application'])} name='' value={application} dark={dark} style={{ paddingTop: 16 }} readOnly withFullBorder />
                    <TextInput label={translate(TRANSLATIONS.generated['System.ControlMode'])} name='' value={controlMode} dark={dark} style={{ paddingTop: 16 }} readOnly withFullBorder />
                    <TextInput label={translate(TRANSLATIONS.generated['System.LineSupply'])} name='' value={lineSupply} dark={dark} style={{ paddingTop: 16 }} readOnly withFullBorder />
                </div>
            </div>
            <Modal open={infoModalOpen} allowClickAway movable onClose={handleCloseModalClick} dark={dark} key={'info-modal'}>
                {modalInfoData}
            </Modal>
        </div>
    );
};

export default InfoDataGroup;
