import { useEffect, useState } from 'react';

export const useResized = (condition: (width: number, height: number) => boolean) => {
    const [resized, setResized] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setResized(condition(window.innerWidth, window.innerHeight));
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return resized;
};
