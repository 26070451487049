import React, { CSSProperties, ReactNode } from 'react';

export type FlexAlignment = 'baseline' | 'center' | 'end' | 'start' | 'stretch';
export type FlexDirection = 'column' | 'column-reverse' | 'row' | 'row-reverse';
export type FlexJustification = 'center' | 'flex-end' | 'flex-start' | 'space-around' | 'space-evenly' | 'space-between';
export type FlexWrap = 'no-wrap' | 'wrap' | 'wrap-reverse';

interface FlexProps {
    alignment?: FlexAlignment;
    children?: ReactNode;
    className?: string;
    columnGap?: number;
    direction?: FlexDirection;
    gap?: number;
    justification?: FlexJustification;
    rowGap?: number;
    style?: CSSProperties;
    wrap?: FlexWrap;
}

const Flex = ({ alignment, children, className, columnGap, direction, gap, justification, rowGap, style, wrap }: FlexProps) => {
    const alignmetnClass = alignment ? `alignment-${alignment}` : 'alignment-center';
    const directionClass = direction ? `direction-${direction}` : 'direction-row';
    const justificationClass = justification ? `justification-${justification}` : 'justification-flex-start';
    const wrapClass = wrap ? `wrap-${wrap}` : 'wrap-no-wrap';
    const gapStyle: CSSProperties = { columnGap: columnGap ?? gap, rowGap: rowGap ?? gap };

    return (
        <div className={`flex ${alignmetnClass} ${directionClass} ${justificationClass} ${wrapClass} ${className}`} style={{ ...gapStyle, ...style }}>
            {children}
        </div>
    );
};

export default Flex;
