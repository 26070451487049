import React from 'react';
import { TRANSLATIONS } from '../../../../constants';
import { Language } from '../../../../enums';
import { useAppSelector, useTranslate } from '../../../../hooks/common';
import Text from '../../../common/Text';

const CommercialViewSwitchgearInfo = () => {
    const dark = useAppSelector(state => state.layout.darkMode);
    const language = useAppSelector(state => state.translation.language);
    const { translate } = useTranslate();

    const infoEnglish = (
        <>
            <ul>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Content'][0])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Content'][1])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Content'][2])}
                    </Text>
                </li>
            </ul>
        </>
    );

    const infoGerman = (
        <>
            <ul>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Content'][0])}
                        <strong>{translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Content'][1])}</strong>
                        {translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Content'][2])}
                        <strong>{translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Content'][3])}</strong>
                        {translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Content'][4])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Content'][5])}
                        <strong>{translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Content'][6])}</strong>
                        {translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Content'][7])}
                        <strong>{translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Content'][8])}</strong>
                        {translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Content'][9])}
                        <strong>{translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Content'][10])}</strong>
                        {translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Content'][11])}
                        <strong>{translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Content'][12])}</strong>
                        {translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Content'][13])}
                        <strong>{translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Content'][14])}</strong>
                        {translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Content'][15])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Content'][16])}
                        <strong>{translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Content'][17])}</strong>
                        {translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Content'][18])}
                    </Text>
                </li>
            </ul>
        </>
    );

    return (
        <>
            <Text dark={dark} type='subtitle' style={{ paddingBottom: 8 }}>
                {translate(TRANSLATIONS.generated['Help.SwitchgearCommercial.Title'])}
            </Text>
            {language === Language.En ? infoEnglish : infoGerman}
        </>
    );
};

export default CommercialViewSwitchgearInfo;
