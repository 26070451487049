import { ChartState } from '../models/chart/ChartState';
import { DEFAULT_ENERGY_PERFORMANCE_CHART_TAB_PARAMS } from './DefaultEnergyPerformanceChartTabParamsParams';
import { DEFAULT_ENERGY_SAVINGS_CHART_TAB_PARAMS } from './DefaultEnergySavingsChartTabParams';
import { DEFAULT_POWERLOSS_CHART_TAB_PARAMS } from './DefaultPowerlossChartTabParams';
import { DEFAULT_SELECTED_COMPARISON_CARD_TAB_ID } from './DefaultSelectedComparisonCardTabId';
import { DEFAULT_LIFETIME_COST_CHART_TAB_PARAMS } from './DefaultlifetimeCostChartTabParams';

export const CHART_INITIAL_STATE: ChartState = {
    chartDataLoading: false,
    lifetimeCostsChartTabParams: DEFAULT_LIFETIME_COST_CHART_TAB_PARAMS,
    powerlossCalculationChartTabParams: DEFAULT_POWERLOSS_CHART_TAB_PARAMS,
    selectedComparisonCardTabId: DEFAULT_SELECTED_COMPARISON_CARD_TAB_ID,
    energySavingsChartTabParams: DEFAULT_ENERGY_SAVINGS_CHART_TAB_PARAMS,
    energyPerformanceChartTabParams: DEFAULT_ENERGY_PERFORMANCE_CHART_TAB_PARAMS
};
