import React from 'react';

export interface MenuDividerItemProps {
    label: string | number;
    dark?: boolean;
}

const MenuLabelItem = ({ dark, label }: MenuDividerItemProps) => (
    <div className={`menu-label-item ${dark ? 'dark' : ''}`}>
        {label}
    </div>
);

export default MenuLabelItem;
