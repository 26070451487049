import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { COMMERCIAL_VIEW_INITIAL_STATE } from '../constants';
import { AdditionalInvestmentData, CommercialData, GovernmentFundingData } from '../models';

export const fanSystemsCommercialViewSlice = createSlice({
    name: 'fanSystemCommercialData',
    initialState: COMMERCIAL_VIEW_INITIAL_STATE,
    reducers: {
        setFanAlternativeSystemCommercialData: (state, action: PayloadAction<Partial<CommercialData>>) => {
            state.alternativeSystemCommercialData = {
                ...state.alternativeSystemCommercialData,
                ...action.payload
            };
        },
        setFanReferenceSystemCommercialData: (state, action: PayloadAction<Partial<CommercialData>>) => {
            state.referenceSystemCommercialData = {
                ...state.referenceSystemCommercialData,
                ...action.payload
            };
        },
        setFanAlternativeSystemGovernmentFundingData: (state, action: PayloadAction<Partial<GovernmentFundingData>>) => {
            state.alternativeSystemCommercialData.governmentFunding = {
                ...state.alternativeSystemCommercialData.governmentFunding,
                ...action.payload
            };
        },
        setFanReferenceSystemGovernmentFundingData: (state, action: PayloadAction<Partial<GovernmentFundingData>>) => {
            state.referenceSystemCommercialData.governmentFunding = {
                ...state.referenceSystemCommercialData.governmentFunding,
                ...action.payload
            };
        },
        setFanAlternativeSystemAdditionalInvestmentData: (state, action: PayloadAction<Partial<AdditionalInvestmentData>>) => {
            state.alternativeSystemCommercialData.additionalInvestments = {
                ...state.alternativeSystemCommercialData.additionalInvestments,
                ...action.payload
            };
        },
        setFanReferenceSystemAdditionalInvestmentData: (state, action: PayloadAction<Partial<AdditionalInvestmentData>>) => {
            state.referenceSystemCommercialData.additionalInvestments = {
                ...state.referenceSystemCommercialData.additionalInvestments,
                ...action.payload
            };
        }
    }
});

export const {
    setFanAlternativeSystemAdditionalInvestmentData,
    setFanAlternativeSystemCommercialData,
    setFanAlternativeSystemGovernmentFundingData,
    setFanReferenceSystemAdditionalInvestmentData,
    setFanReferenceSystemCommercialData,
    setFanReferenceSystemGovernmentFundingData
} = fanSystemsCommercialViewSlice.actions;
export const fanSystemsCommercialDataReducer = fanSystemsCommercialViewSlice.reducer;
