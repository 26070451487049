import React, { useEffect, useRef, useState } from 'react';
import { IconType } from './Icon';
import IconButton from './IconButton';
import StatusIcon from './StatusIcon';

export type SnackbarType = 'error' | 'info' | 'success' | 'warning';

export interface SnackbarProps {
    description: string;
    title: string;
    type: SnackbarType;
    dark?: boolean;
    onDisappear?: () => void;
}

const Snackbar = ({ dark, description, title, type, onDisappear }: SnackbarProps) => {
    const [status, setStatus] = useState<'closed' | 'opening' | 'closing'>('closed');
    const contentRef = useRef<HTMLDivElement>(null);
    const height = status === 'opening' ? contentRef.current?.scrollHeight ?? 0 : 0;

    useEffect(() => {
        setStatus('opening');
        setTimeout(() => setStatus('closing'), 10000);
    }, []);

    const handleTransitionEnd = (event: React.TransitionEvent<HTMLDivElement>) => {
        if (onDisappear && status === 'closing' && event.propertyName === 'height') {
            onDisappear();
        }
    };

    return (
        <div className={`snackbar ${type} ${status} ${dark ? 'dark' : ''}`} style={{ height }} onTransitionEnd={handleTransitionEnd}>
            <div ref={contentRef} className='snackbar-container'>
                <div className='snackbar-highlight' />
                <div className='snackbar-content'>
                    <StatusIcon type={type} dark={dark} />
                    <div className='snackbar-text-container'>
                        <div className='snackbar-title'>{title}</div>
                        <div className='snackbar-description'>{description}</div>
                    </div>
                    <IconButton dark={dark} iconType={IconType.CloseBig} layer={dark ? 2 : 1} onClick={() => setStatus('closing')} />
                </div>
            </div>
        </div>
    );
};

export default Snackbar;
