import { KeyboardEvent, RefObject, useCallback, useEffect, useRef } from 'react';
import { usePageSize } from './PageSize';

export interface Tresholds {
    xMax: number;
    xMin: number;
    yMax: number;
    yMin: number;
}

export const useMovability = (getTresholds: (elementRef: RefObject<HTMLDivElement>) => Tresholds) => {
    const KEYBOARD_MOVE_DISTANCE = 20;

    const pageSize = usePageSize();
    const elementRef = useRef<HTMLDivElement>(null);
    const movingRef = useRef(false);
    const offsetRef = useRef({ x: 0, y: 0 });

    const updateElement = useCallback((movementX: number, movementY: number) => {
        if (elementRef.current) {
            const { xMax, xMin, yMax, yMin } = getTresholds(elementRef);

            const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

            offsetRef.current = {
                x: clamp(offsetRef.current.x + movementX, xMin, xMax),
                y: clamp(offsetRef.current.y + movementY, yMin, yMax)
            };

            elementRef.current.style.setProperty('--offset-x', `${offsetRef.current.x}px`);
            elementRef.current.style.setProperty('--offset-y', `${offsetRef.current.y}px`);
        }
    }, [getTresholds]);

    const handleKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
        switch (event.key) {
            case 'ArrowDown':
                event.preventDefault();
                updateElement(0, KEYBOARD_MOVE_DISTANCE);
                break;
            case 'ArrowLeft':
                event.preventDefault();
                updateElement(-KEYBOARD_MOVE_DISTANCE, 0);
                break;
            case 'ArrowRight':
                event.preventDefault();
                updateElement(KEYBOARD_MOVE_DISTANCE, 0);
                break;
            case 'ArrowUp':
                event.preventDefault();
                updateElement(0, -KEYBOARD_MOVE_DISTANCE);
                break;
        }
    }, [updateElement]);

    const handleMouseDown = useCallback(() => {
        movingRef.current = true;
    }, []);

    const handleWindowMouseMove = useCallback((event: MouseEvent) => {
        if (movingRef.current) {
            updateElement(event.movementX, event.movementY);
        }
    }, [updateElement]);

    const handleWindowMouseUp = useCallback(() => {
        movingRef.current = false;
    }, []);

    useEffect(() => {
        window.addEventListener('mouseup', handleWindowMouseUp, false);

        return () => {
            window.removeEventListener('mouseup', handleWindowMouseUp, false);
        };
    }, [handleWindowMouseUp]);

    useEffect(() => {
        window.addEventListener('mousemove', handleWindowMouseMove, true);

        return () => {
            window.removeEventListener('mousemove', handleWindowMouseMove, true);
        };
    }, [handleWindowMouseMove]);

    useEffect(() => {
        updateElement(0, 0);
    }, [pageSize, updateElement]);

    return { elementRef, handleKeyDown, handleMouseDown };
};
