import React, { useState } from 'react';
import { TRANSLATIONS } from '../../constants';
import { useAppSelector, useTranslate } from '../../hooks/common';
import Modal from '../common/Modal';
import SpacingContainer from '../common/SpacingContainer';
import Text from '../common/Text';
import Flex from '../common/Flex';
import Button, { ButtonType } from '../common/Button';
import RadioButton from '../common/RadioButton';
import { DocumentType } from '../../types';

export interface DocumentExportModal {
    handleDocumentExport: (documentType: string) => void;
    handleDocumentExportModalClose: () => void;
    showDocumentExportModal: boolean;
}

const DocumentExportModal = ({ handleDocumentExport, handleDocumentExportModalClose, showDocumentExportModal }: DocumentExportModal) => {
    const { translate } = useTranslate();
    const [documentType, setDocumentType] = useState<DocumentType>('pdf');
    const dark = useAppSelector(state => state.layout.darkMode);

    return <Modal open={showDocumentExportModal} onClose={handleDocumentExportModalClose} dark={dark} movable allowClickAway>
        <SpacingContainer>
            <Text type='subtitle' dark={dark} style={{ paddingBottom: 8 }}>{translate(TRANSLATIONS.generated['Common.Download'])}</Text>
            <RadioButton onChange={() => setDocumentType('pdf')} dark={dark} label={translate(TRANSLATIONS.generated['Export.Pdf'])} selected={documentType == 'pdf'} />
            <RadioButton onChange={() => setDocumentType('docx')} dark={dark} label={translate(TRANSLATIONS.generated['Export.Docx'])} selected={documentType == 'docx'} />
            <Flex justification='flex-end' gap={10} style={{ marginTop: 30 }}>
                <Button dark={dark} type={ButtonType.Tertiary} onClick={handleDocumentExportModalClose}>
                    {translate(TRANSLATIONS.generated['Common.Cancel'])}
                </Button>
                <Button dark={dark} type={ButtonType.Primary}
                    onClick={() => {
                        handleDocumentExport(documentType);
                        handleDocumentExportModalClose();
                    }}
                >
                    {translate(TRANSLATIONS.generated['Common.Download'])}
                </Button>
            </Flex>
        </SpacingContainer>
    </Modal>;
};

export default DocumentExportModal;
