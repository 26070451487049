import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    DEFAULT_APPLICATION_POWER_DEMAND,
    DEFAULT_ALTERNATIVE_ENERGY_SAVINGS,
    DEFAULT_ALTERNATIVE_EXPECTED_ENERGY_DEMAND,
    DEFAULT_ALTERNATIVE_CO2_EMISSION_SAVINGS,
    DEFAULT_REFERENCE_ENERGY_DEMAND,
    APPLICATION_INITIAL_MODE
} from '../constants';
import {
    AllocationProfile,
    ApplicationParameters,
    DefaultApplicationInputs,
    OperatingHour,
    OperationProfile,
    Pump,
    PumpSystemState,
    SupplyType,
    SystemConfiguration,
    SystemInputParameter
} from '../models';
import { ApplicationMode } from '../types';

const initialState: PumpSystemState = {
    alternativeCo2EmissionSavings: DEFAULT_ALTERNATIVE_CO2_EMISSION_SAVINGS,
    alternativeEnergySavings: DEFAULT_ALTERNATIVE_ENERGY_SAVINGS,
    alternativeExpectedEnergyDemand: DEFAULT_ALTERNATIVE_EXPECTED_ENERGY_DEMAND,
    alternativeSystem: null,
    alternativeSystemLoading: false,
    alternativeSystemInvestmentCosts: 0,
    alternativeSystemRequestParameters: null,
    applicationPowerDemand: DEFAULT_APPLICATION_POWER_DEMAND,
    fullScreenChart: false,
    application: null,
    defaultApplicationInputs: null,
    applicationMode: APPLICATION_INITIAL_MODE,
    applicationRequestParameters: null,
    referenceSystem: null,
    referenceSystemLoading: false,
    referenceSystemInvestmentCosts: 0,
    referenceSystemRequestParameters: null,
    supplyType: null,
    supplyTypes: null,
    systemsLoading: false,
    referenceEnergyDemand: DEFAULT_REFERENCE_ENERGY_DEMAND,
    referenceHasMepsWarning: false,
    alternativeHasMepsWarning: false,
    systemStateChanged: false
  };

export const pumpSystemSlice = createSlice({
    name: 'pumpSystem',
    initialState,
    reducers: {
        setPumpSystemsLoading: (state, action: PayloadAction<boolean>) => {
            state.systemsLoading = action.payload;
        },
        setPumpRequestParameters: (state, action: PayloadAction<ApplicationParameters>) => {
            state.applicationRequestParameters = action.payload;
        },
        setPumpAlternativeSystem: (state, action: PayloadAction<SystemConfiguration>) => {
            state.alternativeSystem = action.payload;
        },
        setPumpAlternativeSystemLoading: (state, action: PayloadAction<boolean>) => {
            state.alternativeSystemLoading = action.payload;
        },
        setPumpAlternativeSystemInvestmentCosts: (state, action: PayloadAction<number>) => {
            state.alternativeSystemInvestmentCosts = action.payload;
        },
        setPumpAlternativeSystemRequestParameters: (state, action: PayloadAction<SystemInputParameter[]>) => {
            state.alternativeSystemRequestParameters = action.payload;
        },
        setPumpReferenceSystem: (state, action: PayloadAction<SystemConfiguration>) => {
            state.referenceSystem = action.payload;
        },
        setPumpReferenceSystemLoading: (state, action: PayloadAction<boolean>) => {
            state.referenceSystemLoading = action.payload;
        },
        setPumpReferenceSystemInvestmentCosts: (state, action: PayloadAction<number>) => {
            state.referenceSystemInvestmentCosts = action.payload;
        },
        setPumpReferenceSystemRequestParameters: (state, action: PayloadAction<SystemInputParameter[]>) => {
            state.referenceSystemRequestParameters = action.payload;
        },
        setPumpFullScreenChart: (state, action: PayloadAction<boolean>) => {
            state.fullScreenChart = action.payload;
        },
        setPumpSupplyType: (state, action: PayloadAction<SupplyType>) => {
            state.supplyType = action.payload;
        },
        setPumpSupplyTypes: (state, action: PayloadAction<SupplyType[]>) => {
            state.supplyTypes = action.payload;
        },
        setPump: (state, action: PayloadAction<Pump>) => {
            state.application = action.payload;
        },
        setDefaultPumpInputs: (state, action: PayloadAction<DefaultApplicationInputs>) => {
            state.defaultApplicationInputs = action.payload;
        },
        setPumpApplicationMode: (state, action: PayloadAction<ApplicationMode>) => {
            state.applicationMode = action.payload;
        },
        setPumpAllocationProfile: (state, action: PayloadAction<AllocationProfile>) => {
            if (state.application) {
                state.application.operationProfile.allocationProfile = action.payload;
            }
        },
        setPumpOperatingHour: (state, action: PayloadAction<OperatingHour>) => {
            if (state.application) {
                state.application.operationProfile.operatingHour = action.payload;
            }
        },
        setPumpOperationProfile: (state, action: PayloadAction<OperationProfile>) => {
            if (state.application) {
                state.application.operationProfile = action.payload;
            }
        },
        setPumpShaftPower: (state, action: PayloadAction<number>) => {
            if (state.application) {
                state.application.shaftPower = action.payload;
            }
        },
        setPumpSpeed: (state, action: PayloadAction<number>) => {
            if (state.application) {
                state.application.speed = action.payload;
            }
        },
        setPumpApplicationPowerDemand: (state, action: PayloadAction<number[]>) => {
            state.applicationPowerDemand = action.payload;
        },
        setPumpHasMepsWarning: (state, action: PayloadAction<{ hasMepsWarning: boolean, systemType: string }>) => {
            const hasMepsWarning = action.payload.hasMepsWarning;
            const systemType = action.payload.systemType;

            if (systemType == 'reference') {
                state.referenceHasMepsWarning = hasMepsWarning;
            } else if (systemType == 'alternative') {
                state.alternativeHasMepsWarning = hasMepsWarning;
            }
        },
        setPumpAlternativeSystemInitialHash: (state, action: PayloadAction<string>) => {
            state.alternativeSystemInitialHash = action.payload;
        },
        setPumpReferenceSystemInitialHash: (state, action: PayloadAction<string>) => {
            state.referenceSystemInitialHash = action.payload;
        },
        setPumpSystemStateChanged: (state, action: PayloadAction<boolean>) => {
            state.systemStateChanged = action.payload;
        }
    }
});

export const {
    setPumpAlternativeSystem,
    setPumpAlternativeSystemInvestmentCosts,
    setPumpAlternativeSystemLoading,
    setPumpAlternativeSystemRequestParameters,
    setPumpApplicationPowerDemand,
    setPumpFullScreenChart,
    setPump,
    setDefaultPumpInputs,
    setPumpAllocationProfile,
    setPumpOperatingHour,
    setPumpOperationProfile,
    setPumpRequestParameters,
    setPumpShaftPower,
    setPumpSpeed,
    setPumpReferenceSystem,
    setPumpReferenceSystemInvestmentCosts,
    setPumpReferenceSystemLoading,
    setPumpReferenceSystemRequestParameters,
    setPumpSupplyType,
    setPumpSupplyTypes,
    setPumpSystemsLoading,
    setPumpApplicationMode,
    setPumpHasMepsWarning,
    setPumpAlternativeSystemInitialHash,
    setPumpReferenceSystemInitialHash,
    setPumpSystemStateChanged
} = pumpSystemSlice.actions;
export const pumpSystemReducer = pumpSystemSlice.reducer;
