import React from 'react';

interface HeaderKeyVisualProps {
    height: number;
    url: string;
    hidden?: boolean;
}

const HeaderKeyVisual = ({ height, hidden, url }: HeaderKeyVisualProps) => (
    <div className={`header-key-visual ${hidden ? 'hidden' : ''}`} style={{ backgroundImage: `url(${url})`, height }} />
);

export default HeaderKeyVisual;
