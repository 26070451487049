import React, { CSSProperties } from 'react';

interface ImageProps {
  source: string;
  alt?: string;
  height?: number;
  style?: CSSProperties;
  width?: number;
}

const Image = ({ source, alt, height, style, width }: ImageProps) => {
  return <img className='image' src={source} alt={alt} height={height} style={{ width, ...style }} />;
};

export default Image;
