import React, { CSSProperties, ReactNode } from 'react';

interface InputValidationProps {
    children: ReactNode;
    message: ReactNode;
    valid: boolean;
    dark?: boolean;
    style?: CSSProperties;
}

const InputValidation = ({ children, dark, message, style, valid }: InputValidationProps) => {
    return (
        <div className={`input-validation ${valid ? 'valid' : ''} ${dark ? 'dark' : ''}`} style={style}>
            <div className='input-validation-background' />
            <div className='input-validation-input-container'>
                {children}
                <div className='input-validation-message'>
                    {message}
                </div>
            </div>
        </div>);
};

export default InputValidation;
