import React, { CSSProperties, KeyboardEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { usePageSize } from '../../hooks/common';
import Flex from './Flex';

export interface Tab {
    label: string | number;
    required?: boolean;
    content?: ReactElement;
}

interface TabSwitcherProps {
    selectedTab: number;
    tabs: Tab[];
    onSelect: (index: number) => void;
    dark?: boolean;
    layer?: 1 | 2 | 3;
    style?: CSSProperties;
}

const TabSwitcher = ({ dark, layer, selectedTab, style, tabs, onSelect }: TabSwitcherProps) => {
    const [activeTab, setActiveTab] = useState(-1);
    const [underline, setUnderline] = useState({ left: -1, width: -1 });
    const size = usePageSize();
    const selectedTabRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setUnderline({ left: selectedTabRef.current?.offsetLeft ?? -1, width: selectedTabRef.current?.offsetWidth ?? -1 });
    }, [selectedTab, size, tabs]);

    const handleSelect = (index: number) => {
        if (index !== selectedTab) {
            onSelect(index);
        }
    };

    const handleFocus = () => {
        setActiveTab(selectedTab);
    };

    const handleBlur = () => {
        setActiveTab(-1);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();

        if (event.key === 'Enter') {
            handleSelect(activeTab);
        } else if (event.key === 'ArrowLeft') {
            event.preventDefault();

            setActiveTab(activeTab === -1
                ? 0
                : activeTab > 0 ? activeTab - 1 : tabs.length - 1);
        } else if (event.key === 'ArrowRight') {
            event.preventDefault();

            setActiveTab(activeTab === -1
                ? tabs.length - 1
                : activeTab < tabs.length - 1 ? activeTab + 1 : 0);
        }
    };

    const handleTabClick = (index: number) => {
        handleSelect(index);
        setActiveTab(index);
    };

    const renderTabContent = (tab: Tab) => tab.content
            ? <Flex gap={8}>
                {tab.label}
                {tab.content}
            </Flex>
            : tab.label;

    const renderTab = (tab: Tab, index: number) => {
        return (
            <div
                key={tab.label}
                ref={index === selectedTab ? selectedTabRef : null}
                className={`tab ${index === activeTab ? 'active' : ''} ${tab.required ? 'required' : ''} ${tab.content ? 'with-children' : ''}`}
                onClick={() => handleTabClick(index)}>
                {renderTabContent(tab)}
            </div>
        );
    };

    return (
        <div className={`tab-switcher-container tab-switcher-layer-${layer ?? 1} ${dark ? 'dark' : ''}`} tabIndex={0} onFocus={handleFocus} onBlur={handleBlur}
            onKeyDown={handleKeyDown} style={style}>
            <div className='tab-switcher'>
                {tabs.map(renderTab)}
                {underline.left >= 0 && underline.width >= 0 &&
                    <div className='tab-switcher-underline' style={{ left: underline.left, width: underline.width }} />
                }
            </div>
        </div>
    );
};

export default TabSwitcher;
