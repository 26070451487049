import React, { useCallback } from 'react';
import { TRANSLATIONS } from '../../../constants';
import { DataType } from '../../../enums';
import { TextInputItem } from '../../../models';
import { useTranslate } from '../../../hooks/common';
import TextInput from '../../common/TextInput';

export interface TextInputElementProps {
    input: TextInputItem;
    dark: boolean;
    handleSystemInputChange: (inputId: string, value: string, dataType?: DataType) => void;
}

const TextInputElement = ({ input, dark, handleSystemInputChange }: TextInputElementProps) => {
    const { dynamicTranslate } = useTranslate();
    const handleTextInputElementChange = useCallback((value: string) => handleSystemInputChange(input.id, value, input.dataType), [handleSystemInputChange, input.dataType, input.id]);

    return <TextInput
        label={dynamicTranslate(TRANSLATIONS.generated, input.name)}
        value={input.value}
        dark={dark}
        disabled={input.isDisabled}
        name={input.name}
        onChange={handleTextInputElementChange}
    />;
};

export default TextInputElement;
