import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Snackbar } from '../models';

export interface SnackbarState {
    snackbars: Snackbar[];
}

const initialState: SnackbarState = {
    snackbars: []
};

export const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        addSnackbar: (state, action: PayloadAction<Omit<Snackbar, 'id'>>) => {
            state.snackbars = [{ ...action.payload, id: new Date().getTime() }, ...state.snackbars];
        },
        removeSnackbar: (state, action: PayloadAction<string | number>) => {
            state.snackbars = state.snackbars.filter(x => x.id !== action.payload);
        }
    }
});

export const { addSnackbar, removeSnackbar } = snackbarSlice.actions;
export const snackbarReducer = snackbarSlice.reducer;
