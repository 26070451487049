import React from 'react';
import { useAppSelector } from '../../../../hooks/common';

export interface TriangleProps {
    cx: number;
    cy: number;
    orientation: 'left' | 'right' | 'up' | 'down'
}

const Triangle = ({ cx, cy, orientation }: TriangleProps) => {
    let points = '';
    const dark = useAppSelector(state => state.layout.darkMode);

    switch (orientation) {
        case 'up': {
            points = `${cx - 5},${cy + 10} ${cx},${cy + 1} ${cx + 5},${cy + 10}`;
            break;
        }
        case 'down': {
            points = `${cx - 5},${cy - 10} ${cx},${cy - 1} ${cx + 5},${cy - 10}`;
            break;
        }
        case 'left': {
            points = `${cx + 10},${cy - 5} ${cx},${cy} ${cx + 10},${cy + 5}`;
            break;
        }
        case 'right': {
            points = `${cx - 10},${cy - 5} ${cx},${cy} ${cx - 10},${cy + 5}`;
            break;
        }
    }
    return <polygon
        points={points}
        style={{ fill: dark ? 'white' : 'black' }}
    />;
};

export default Triangle;
