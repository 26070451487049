import React, { useContext, useEffect } from 'react';
import { PUMP_SYSTEMS_SUB_PAGES } from '../../../../constants';
import { PumpSystemsContext } from '../PumpSystems';
import ContentContainer from '../../../common/ContentContainer';
import Text from '../../../common/Text';

const PumpSystemTechnicalView = () => {
    const { onSubPageChange } = useContext(PumpSystemsContext);

    useEffect(() => {
        onSubPageChange && onSubPageChange(PUMP_SYSTEMS_SUB_PAGES.indexOf('technical'));
    }, [onSubPageChange]);

    return (
        <ContentContainer style={{ paddingTop: 116 }}>
            <Text type='title'>Pump systems technical view</Text>
        </ContentContainer>
    );
};

export default PumpSystemTechnicalView;
