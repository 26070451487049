import React, { CSSProperties, KeyboardEvent } from 'react';
import Icon, { IconType } from './Icon';

interface LinkProps {
    onClick: () => void;
    text: string | number;
    dark?: boolean;
    icon?: IconType;
    iconPosition?: 'left' | 'right';
    noWrap?: boolean;
    style?: CSSProperties;
    textStyle?: CSSProperties;
}

const Link = ({ dark, icon, iconPosition, noWrap, onClick, style, text, textStyle }: LinkProps) => {
    const iconPositionClass = iconPosition ? `link-icon-position-${iconPosition}` : '';
    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();

        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            onClick();
        }
    };

    return (
        <div className={`link ${noWrap ? 'no-wrap' : ''} ${dark ? 'dark' : ''} ${iconPositionClass}`} tabIndex={0} onClick={onClick} onKeyDown={handleKeyDown} style={style}>
            <div className='link-text' title={noWrap ? text.toString() : undefined} style={textStyle}>
                {text}
            </div>
            {icon &&
                <div className='link-icon-container'>
                    <Icon type={icon} />
                </div>
            }
        </div>
    );
};

export default Link;
