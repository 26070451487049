import React, { Fragment, useCallback, useMemo } from 'react';
import { ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Area, Bar, Label } from 'recharts';
import { CHART_ALTERNATIVE, CHART_ALTERNATIVE_DARK, CHART_APPLICATION_POWER_DEMAND, CHART_OPERATION_PROFILE, CHART_REFERENCE, CHART_REFERENCE_DARK, TRANSLATIONS } from '../../../../constants';
import { useAppSelector, useTranslate } from '../../../../hooks/common';
import { EnergySavingsChartData, IntersectionPoint } from '../../../../models';
import ChartWrapper from '../../../common/ChartWrapper';
import EnergySavingsTooltip, { EnergySavingsTooltipProps } from './EnergySavingsTooltip';
import ChartLineDot from '../ChartElements/ChartLineDot';

export interface EnergySavingsChartProps {
    data?: EnergySavingsChartData,
    showEnergySavingPotential: boolean,
    showOperationProfile: boolean,
    showApplicationPowerDemand: boolean
}

const EnergySavingsChart = ({ data, showApplicationPowerDemand, showEnergySavingPotential, showOperationProfile }: EnergySavingsChartProps) => {
    const { translate } = useTranslate();
    const dark = useAppSelector(x => x.layout.darkMode);
    const alternativeColor = useMemo(() => dark ? CHART_ALTERNATIVE_DARK : CHART_ALTERNATIVE, [dark]);
    const referenceColor = useMemo(() => dark ? CHART_REFERENCE_DARK : CHART_REFERENCE, [dark]);
    const applicationPowerDemandColor = useMemo(() => dark ? 'white' : CHART_APPLICATION_POWER_DEMAND, [dark]);
    const xAxisDomain = useMemo(() => [10, 105], []);
    const xAxisTicks = useMemo(() => [10, 20, 30, 40, 50, 60, 70, 80, 90, 100], []);
    const yAxisTickCount = 5;
    const defaultTickLength = 0.5;
    const chartPaddingMultiplier = 1.2;
    const powerDemandMax = useMemo(() => Math.max(
        ...data?.chartData.map(x => Math.max(x.alternativePowerDemand, x.applicationPowerDemand, x.referencePowerDemand)) ?? []) * chartPaddingMultiplier
        , [data?.chartData]);
    const powerDemandDomain = useMemo(() => [0, powerDemandMax], [powerDemandMax]);
    const powerDemandTicks = useMemo(() =>
        Array.from({ length: yAxisTickCount }, (_, i) => Math.round(powerDemandMax / (yAxisTickCount - 1)) * i),
        [powerDemandMax]);
    const operatingHoursMax = useMemo(() => Math.max(...data?.chartData.map(x => x.operationProfile) ?? []) * chartPaddingMultiplier, [data?.chartData]);
    const operatingHoursDomain = useMemo(() => showOperationProfile ? [0, operatingHoursMax] : undefined, [operatingHoursMax, showOperationProfile]);
    const operatingHoursTicks = useMemo(() => showOperationProfile
        ? Array.from({ length: yAxisTickCount }, (_, i) => {
            const tickLength = operatingHoursMax / (yAxisTickCount - 1);
            const result = tickLength > defaultTickLength
                ? Math.round(tickLength) * i
                : defaultTickLength * i;

            return result;
        })
        : undefined,
        [operatingHoursMax, showOperationProfile]);
    const timeUnit = useMemo(() => translate(TRANSLATIONS.generated['Unit.Hour']), [translate]);
    const powerUnit = useMemo(() => translate(TRANSLATIONS.generated['Unit.KW']), [translate]);
    const needManualColorChange = data?.chartData.length === 11 && data?.intersectionPoints.length === 0;

    const renderResponsiveContainer = (children: JSX.Element) => (
        <ResponsiveContainer maxHeight={500} aspect={5 / 3}>
            {children}
        </ResponsiveContainer>);

    const getIntersectionColor = useCallback((intersection: IntersectionPoint, isLast: boolean | undefined) => {
        if (isLast) {
            return intersection.firstLineIsHigherNext ? referenceColor : alternativeColor;
        }

        return intersection.firstLineIsHigher ? referenceColor : alternativeColor;
    }, [alternativeColor, referenceColor]);

    const getLinearGradient = useCallback(() => data?.intersectionPoints.length
        ? data.intersectionPoints.map((intersection, i) => {

            const nextIntersection = data.intersectionPoints[i + 1];

            let closeColor = '';
            let startColor = '';

            const isLast = i === data.intersectionPoints.length - 1;
            const correctionTerm = (105 - intersection.x) / 1000;
            const offset = (intersection.x / 105) - correctionTerm;

            if (isLast) {
                closeColor = getIntersectionColor(intersection, false);
                startColor = getIntersectionColor(intersection, true);
            } else {
                closeColor = getIntersectionColor(intersection, false);
                startColor = getIntersectionColor(nextIntersection, false);
            }

            return (
                <Fragment key={i}>
                    <stop
                        key={`intersection-${i}-start-color`}
                        offset={offset}
                        stopColor={startColor}
                        stopOpacity={1}
                    />
                    <stop
                        key={`intersection-${i}-stop-color`}
                        offset={offset}
                        stopColor={closeColor}
                        stopOpacity={1}
                    />
                </Fragment>
            );
        })
        : needManualColorChange
            ?
            <>
                <stop
                    key='gradient-left'
                    offset={`${45 / 105 * 100}%`}
                    stopColor={referenceColor}
                    stopOpacity={1}
                />
                <stop
                    key='gradient-right'
                    offset={`${45 / 105 * 100}%`}
                    stopColor={alternativeColor}
                    stopOpacity={1}
                />
            </>
            :
            <stop
                key='intersection-start-color'
                offset={0}
                stopColor={
                    data && (data.chartData[0]?.alternativePowerDemand ?? 0) > (data.chartData[0]?.referencePowerDemand ?? 0) ? referenceColor : alternativeColor
                }
            />
        , [alternativeColor, data, getIntersectionColor, referenceColor]);

    return <div className='pump-systems-basic-view-energy-savings-chart'>
        <ChartWrapper dark={dark} key='energySavingsChart' >
            {data && renderResponsiveContainer(
                <ComposedChart
                    width={500}
                    height={300}
                    data={data.chartData}
                    margin={{
                        top: 5,
                        bottom: 5,
                        right: 0
                    }}
                >
                    <defs>
                        <linearGradient id='areaColor' x1='0%' y1='0%' x2='105%' y2='0%' >
                            {getLinearGradient()}
                        </linearGradient>
                    </defs>
                    <CartesianGrid />
                    <XAxis domain={xAxisDomain} dataKey='flowrate' type='number' ticks={xAxisTicks} height={45} >
                        <Label value={`${translate(TRANSLATIONS.generated['System.Flowrate'])} [%]`} offset={10} position='insideBottom' style={{ textAnchor: 'middle' }} />
                    </XAxis>
                    <YAxis orientation='left' domain={powerDemandDomain} ticks={powerDemandTicks} width={45}>
                        <Label value={`${translate(TRANSLATIONS.generated['System.PowerDemand'])} [${powerUnit}]`} offset={10} position='insideLeft' angle={-90} style={{ textAnchor: 'middle' }} />
                    </YAxis>
                    <YAxis yAxisId='right' orientation='right' domain={operatingHoursDomain} ticks={operatingHoursTicks} width={45} >
                        <Label value={`${translate(TRANSLATIONS.generated['Common.OperatingHours'])} [${timeUnit}]`} offset={10} position='insideRight' angle={-90} style={{ textAnchor: 'middle' }} />
                    </YAxis>
                    {showEnergySavingPotential &&
                        <Area
                            dataKey='range'
                            fill='url(#areaColor)'
                            isAnimationActive={false}
                            tooltipType={'none'}
                            type='monotone'
                            activeDot={false}
                        />
                    }
                    {showOperationProfile &&
                        <Bar
                            barSize={30}
                            dataKey='operationProfile'
                            fill={CHART_OPERATION_PROFILE}
                            isAnimationActive={false}
                            scale='linear'
                            strokeWidth={0}
                            yAxisId='right'
                        />
                    }
                    <Line
                        activeDot={props => <ChartLineDot {...props} active stroke={alternativeColor} fill={alternativeColor} systemType='alternative' />}
                        className='alternative-line'
                        dataKey='alternativePowerDemand'
                        dot={<ChartLineDot systemType='alternative' />}
                        fill={alternativeColor}
                        isAnimationActive={false}
                        name='Alternative System'
                        type='monotone'
                    />
                    <Line
                        activeDot={props => <ChartLineDot {...props} active stroke={referenceColor} fill={referenceColor} systemType='reference' />}
                        className='reference-line'
                        dataKey='referencePowerDemand'
                        dot={<ChartLineDot systemType='reference' />}
                        fill={referenceColor}
                        isAnimationActive={false}
                        name='Reference System'
                        type='monotone'
                    />
                    {showApplicationPowerDemand &&
                        <Line
                            dataKey='applicationPowerDemand'
                            dot={false}
                            fill={applicationPowerDemandColor}
                            isAnimationActive={false}
                            name='Application'
                            type='monotone'
                        />}
                    <Tooltip content={props => {
                        const newProps = { ...props, showApplicationPowerDemand, showOperationProfile, powerUnit, timeUnit, alternativeColor, referenceColor, applicationPowerDemandColor } as EnergySavingsTooltipProps;

                        return <EnergySavingsTooltip {...newProps} />;
                    }} />
                </ComposedChart>
            )}
        </ChartWrapper>
    </div>;
};

export default EnergySavingsChart;
