import React, { useMemo } from 'react';
import { numericFormatter } from 'react-number-format';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import Checkbox from '../../common/Checkbox';
import EnergySavingsChart from '../Charts/EnergySavingsChart/EnergySavingsChart';
import ComparisonCardTab, { Feedback } from './ComparisonCardTab';
import { ApplicationType } from '../../../types';
import { TRANSLATIONS } from '../../../constants';
import { useHook } from '../../../hooks';
import ComparisonCardEnergySavingsInfo from './ModalInfo/ComparisonCardEnergySavingsInfo';


export interface EnergySavingsTabProps {
    applicationType: ApplicationType;
}

const EnergySavingsTab = ({ applicationType }: EnergySavingsTabProps) => {
    const { translate } = useTranslate();
    const dark = useAppSelector(x => x.layout.darkMode);
    const { numberFormatOptions } = useAppSelector(x => x.regionSettings);
    const isPumpSystem = applicationType === 'pump';
    const chartData = useAppSelector(state => isPumpSystem
        ? state.pumpSystemChart.energySavingsChartData : state.fanSystemChart.energySavingsChartData);
    const chartTabData = useAppSelector(state => isPumpSystem
        ? state.pumpSystemChart.energySavingsChartTabData : state.fanSystemChart.energySavingsChartTabData);
    const systemName = translate(isPumpSystem ? TRANSLATIONS.generated['System.Pump'] : TRANSLATIONS.generated['System.Fan']);
    const energySavingsUnit = translate(TRANSLATIONS.generated['System.Units.Megawatthourperannual']);
    const co2SavingsUnit = translate(TRANSLATIONS.generated['System.Units.Tonnsperannual']);
    const isAlternativeMoreEfficient = (chartTabData?.alternativeEnergyConsumption ?? 0) < (chartTabData?.referenceEnergyConsumption ?? 0);

    const {
        chartOptionParameters,
        handleSystem,
        handleShowOperationProfile,
        handleEnergySaving
    } = useHook(x => x.energySavings({ applicationType }));

    const feedback: Feedback = useMemo(() => ({
        feedbackTitle: translate(TRANSLATIONS.generated['System.MostEfficientInfoTitle']),
        positiveFeedback: isAlternativeMoreEfficient,
        feedbackMessage: isAlternativeMoreEfficient ? translate(TRANSLATIONS.generated['System.AlternativeMoreEfficientInfoText']) : translate(TRANSLATIONS.generated['System.ReferenceMoreEfficientInfoText'])
    }), [translate, isAlternativeMoreEfficient]);

    const checkboxes = useMemo(() => [
        <Checkbox checked={chartOptionParameters.system} onChange={handleSystem}
            dark={dark} label={systemName} key='system-checkbox' />,
        <Checkbox checked={chartOptionParameters.showOperationProfile} onChange={handleShowOperationProfile}
            dark={dark} label={translate(TRANSLATIONS.generated['System.OperationProfile'])} key='operation-profile-checkbox' />,
        <Checkbox checked={chartOptionParameters.energySaving} onChange={handleEnergySaving}
            dark={dark} label={translate(TRANSLATIONS.generated['System.EnergySavingPotential'])} key='energy-saving-checkbox' />
    ], [chartOptionParameters.system, chartOptionParameters.showOperationProfile, chartOptionParameters.energySaving, handleSystem, dark, systemName, handleShowOperationProfile, translate, handleEnergySaving]);

    const formatSavingsValues = (value: number) => numericFormatter(`${(value / 1000) ?? ''}`, numberFormatOptions);

    const getAlternativeSystemAttributes = () => {
        if (chartTabData && chartTabData?.alternativeEnergyConsumption < chartTabData?.referenceEnergyConsumption) {
            return [
                {
                    label: translate(TRANSLATIONS.generated['System.ExpectedEnergyDemand']),
                    value: formatSavingsValues(chartTabData?.alternativeEnergyConsumption ?? 0),
                    unit: energySavingsUnit
                },
                {
                    label: translate(TRANSLATIONS.generated['System.EnergySavings']),
                    value: formatSavingsValues(chartTabData?.alternativeEnergySavings ?? 0),
                    unit: energySavingsUnit
                },
                {
                    label: translate(TRANSLATIONS.generated['Common.Co2EmissionSavings']),
                    value: formatSavingsValues(chartTabData?.alternativeCo2EmissionSavings ?? 0),
                    unit: co2SavingsUnit
                }
            ];
        } else {
            return [
                {
                    label: translate(TRANSLATIONS.generated['System.ExpectedEnergyDemand']),
                    value: formatSavingsValues(chartTabData?.alternativeEnergyConsumption ?? 0),
                    unit: energySavingsUnit
                }
            ];
        }
    };

    const getReferenceSystemAttributes = () => {
        if (chartTabData && chartTabData?.alternativeEnergyConsumption > chartTabData?.referenceEnergyConsumption) {
            return [
                {
                    label: translate(TRANSLATIONS.generated['System.ExpectedEnergyDemand']),
                    value: formatSavingsValues(chartTabData?.referenceEnergyConsumption ?? 0),
                    unit: energySavingsUnit
                },
                {
                    label: translate(TRANSLATIONS.generated['System.EnergySavings']),
                    value: formatSavingsValues(Math.abs(chartTabData?.alternativeEnergySavings) ?? 0),
                    unit: energySavingsUnit
                },
                {
                    label: translate(TRANSLATIONS.generated['Common.Co2EmissionSavings']),
                    value: formatSavingsValues(Math.abs(chartTabData?.alternativeCo2EmissionSavings) ?? 0),
                    unit: co2SavingsUnit
                }
            ];
        } else {
            return [
                {
                    label: translate(TRANSLATIONS.generated['System.ExpectedEnergyDemand']),
                    value: formatSavingsValues(chartTabData?.referenceEnergyConsumption ?? 0),
                    unit: energySavingsUnit
                }
            ];
        }
    };

    return (
        <ComparisonCardTab
            applicationType={applicationType}
            chart={
                <EnergySavingsChart
                    showApplicationPowerDemand={chartOptionParameters.system}
                    showEnergySavingPotential={chartOptionParameters.energySaving}
                    showOperationProfile={chartOptionParameters.showOperationProfile}
                    data={chartData}
                />
            }
            checkboxes={checkboxes}
            alternativeSystemAttributes={getAlternativeSystemAttributes()}
            referenceSystemAttributes={getReferenceSystemAttributes()}
            modalContent={<ComparisonCardEnergySavingsInfo />}
            feedback={(chartTabData?.alternativeEnergyConsumption ?? 0) !== (chartTabData?.referenceEnergyConsumption ?? 0) ? feedback : undefined}
        />
    );
};

export default EnergySavingsTab;
