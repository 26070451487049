import React from 'react';
import { TRANSLATIONS } from '../../../../constants';
import { Language } from '../../../../enums';
import { useAppSelector, useTranslate } from '../../../../hooks/common';
import Link from '../../../common/Link';
import Text from '../../../common/Text';

const ComparisonCardPowerLossInfo = () => {
    const dark = useAppSelector(state => state.layout.darkMode);
    const language = useAppSelector(state => state.translation.language);
    const { translate } = useTranslate();
    const link = 'https://www.industry.siemens.com/topics/global/en/energy-efficient-production/legislation-and-standards/en-50598/Pages/en-50598.aspx';

    const infoEnglish = (
        <>
            <ul>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        <strong>{translate(TRANSLATIONS.generated['Help.IES.Content'][0])}</strong>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][1])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][2])}
                        <strong>{translate(TRANSLATIONS.generated['Help.IES.Content'][3])}</strong>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][4])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][5])}
                        <strong>{translate(TRANSLATIONS.generated['Help.IES.Content'][6])}</strong>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][7])}
                        <strong>{translate(TRANSLATIONS.generated['Help.IES.Content'][8])}</strong>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][9])}
                        <strong>{translate(TRANSLATIONS.generated['Help.IES.Content'][10])}</strong>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][11])}
                        <strong>{translate(TRANSLATIONS.generated['Help.IES.Content'][12])}</strong>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][13])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {`${translate(TRANSLATIONS.generated['Help.IES.Content'][14])} `}
                        <Link text={translate(TRANSLATIONS.generated['Help.IES.Content'][15])} onClick={() => window.open(link, '_blank')} dark={dark} />
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][16])}
                        <strong>{translate(TRANSLATIONS.generated['Help.IES.Content'][17])}</strong>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][18])}
                        <strong>{translate(TRANSLATIONS.generated['Help.IES.Content'][19])}</strong>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][20])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][21])}
                        <strong>{translate(TRANSLATIONS.generated['Help.IES.Content'][22])}</strong>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][23])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][24])}
                        <strong>{translate(TRANSLATIONS.generated['Help.IES.Content'][25])}</strong>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][26])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][27])}
                        <strong>{translate(TRANSLATIONS.generated['Help.IES.Content'][28])}</strong>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][29])}
                    </Text>
                </li>
            </ul>
        </>
    );

    const infoGerman = (
        <>
            <ul>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][0])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][1])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][2])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][3])}
                        <Link text={translate(TRANSLATIONS.generated['Help.IES.Content'][4])} onClick={() => window.open(link, '_blank')} dark={dark} />
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][5])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][6])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][7])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.IES.Content'][8])}
                    </Text>
                </li>
            </ul>
        </>
    );

    return (
        <>
            <Text dark={dark} type='subtitle' style={{ paddingBottom: 8 }}>
                {translate(TRANSLATIONS.generated['Help.IES.Title'])}
            </Text>
            {language === Language.En ? infoEnglish : infoGerman}
        </>
    );
};

export default ComparisonCardPowerLossInfo;
