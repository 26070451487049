import React from 'react';
import { TRANSLATIONS } from '../../../../constants';
import { useAppSelector, useTranslate } from '../../../../hooks/common';
import Text from '../../../common/Text';

const ComparisonCardTotalCostsOfOwnershipInfo = () => {
    const dark = useAppSelector(state => state.layout.darkMode);
    const { translate } = useTranslate();

    return (
        <>
            <Text dark={dark} type='subtitle' style={{ paddingBottom: 8 }}>
                {translate(TRANSLATIONS.generated['Help.TCO.Title'])}
            </Text>
            <ul>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.TCO.Content'][0])}
                        <strong>{translate(TRANSLATIONS.generated['Help.TCO.Content'][1])}</strong>
                        {translate(TRANSLATIONS.generated['Help.TCO.Content'][2])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.TCO.Content'][3])}
                        <strong>{translate(TRANSLATIONS.generated['Help.TCO.Content'][4])}</strong>
                        {translate(TRANSLATIONS.generated['Help.TCO.Content'][5])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.TCO.Content'][6])}
                    </Text>
                </li>
            </ul>
        </>
    );
};

export default ComparisonCardTotalCostsOfOwnershipInfo;
