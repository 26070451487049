import React, { CSSProperties, FocusEvent, ReactNode, useMemo, useRef } from 'react';
import { useOpenAnimations } from '../../hooks/common';
import { MenuContext } from './Menu';

export interface HoverMenuBoundary {
    left: number;
    right: number;
}

export interface HoverMenuProps {
    children: ReactNode;
    open: boolean;
    position: 'left' | 'right' | 'scroll' | 'scroll-end';
    toggleElement: ReactNode;
    onOpenChange: (value: boolean) => void;
    dark?: boolean;
    getBoundary?: () => HoverMenuBoundary | undefined;
}

const HoverMenu = ({ children, dark, getBoundary, open, position, toggleElement, onOpenChange }: HoverMenuProps) => {
    const [animationClass, handleAnimationEnd] = useOpenAnimations(open);
    const buttonRef = useRef<HTMLDivElement>(null);
    const dropdownContainerRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const closed = animationClass === 'closed';

    const positionStyle = useMemo((): CSSProperties => {
        const boundary = getBoundary && getBoundary();

        // The normal arrangement is rendered in the default/closed state to provide a reference size for subsequent calculations
        if (closed || !boundary || !buttonRef.current || !dropdownContainerRef.current || !dropdownRef.current) {
            return {};
        }

        const buttonRectangle = buttonRef.current.getBoundingClientRect();
        const dropdownContainerRectangle = dropdownContainerRef.current.getBoundingClientRect();
        const dropdownWidth = dropdownRef.current.offsetWidth;
        const iconButtonPadding = 6;
        const requiredSpacing = 8;
        const { left: dropdownCointainerLeft } = dropdownContainerRectangle;
        const { left: boundaryLeft, right: boundaryRight } = boundary;

        if (position === 'right' || position === 'scroll') {
            const buttonLeftCalculated = dropdownCointainerLeft + iconButtonPadding; // In this case the dropdown container has a negative button padding at the left side

            // Check and position value for the normal dropdown arrangement
            if (boundaryRight - buttonLeftCalculated + iconButtonPadding >= dropdownWidth + requiredSpacing) {
                return {};
            }

            // Check whether the dropdown fit the available space without decreasing the width
            if (boundaryRight - boundaryLeft >= dropdownWidth + 2 * requiredSpacing) {
                return { left: boundaryRight - buttonLeftCalculated + iconButtonPadding - dropdownWidth - requiredSpacing };
            }

            // Calculation of the positon value when the dropdown cannot fit the available space
            return { left: boundaryLeft + requiredSpacing - buttonLeftCalculated + iconButtonPadding };
        } else if (position === 'left') {
            const buttonLeftCalculated = dropdownCointainerLeft;
            const buttonRightCalculated = dropdownCointainerLeft + buttonRectangle.width;

            // Check and position value for the normal dropdown arrangement
            if (buttonRightCalculated + iconButtonPadding - boundaryLeft >= dropdownWidth + requiredSpacing) {
                return {};
            }

            // Check whether the dropdown fit the available space without decreasing the width
            if (boundaryRight - boundaryLeft >= dropdownWidth + 2 * requiredSpacing) {
                return { right: buttonLeftCalculated - boundaryLeft - requiredSpacing };
            }

            // Calculation of the positon value when the dropdown cannot fit the available space
            return { right: buttonLeftCalculated - boundaryLeft - requiredSpacing };
        } else if (position === 'scroll-end') {
            const buttonRightCalculated = dropdownCointainerLeft + dropdownWidth - iconButtonPadding;

            // Check and position value for the normal dropdown arrangement
            if (buttonRightCalculated + iconButtonPadding - boundaryLeft >= dropdownWidth + requiredSpacing) {
                return {};
            }

            // Check whether the dropdown fit the available space without decreasing the width
            if (boundaryRight - boundaryLeft >= dropdownWidth + 2 * requiredSpacing) {
                return { right: buttonRightCalculated + iconButtonPadding - boundaryLeft - dropdownWidth - requiredSpacing };
            }

            // Calculation of the positon value when the dropdown cannot fit the available space
            return { right: buttonRightCalculated + iconButtonPadding - boundaryLeft - dropdownWidth - requiredSpacing };
        }

        return {};
    }, [closed, getBoundary, position]);

    const handleBlur = (event: FocusEvent<HTMLDivElement>) => {
        if (!event.relatedTarget || !buttonRef.current || !buttonRef.current.contains(event.relatedTarget as HTMLElement)) {
            onOpenChange(false);
        }
    };

    return (
        <div ref={buttonRef} className={`hover-menu position-${position} ${dark ? 'dark' : ''}`} tabIndex={-1} onBlur={handleBlur}>
            {toggleElement}
            <div ref={dropdownContainerRef} className='hover-menu-dropdown-container'>
                <div ref={dropdownRef} className={`hover-menu-dropdown ${animationClass}`} style={positionStyle} onClick={e => e.stopPropagation()} onAnimationEnd={handleAnimationEnd}>
                    <MenuContext.Provider value={{ getBoundary, onMenuClose: () => onOpenChange(false) }}>
                        {children}
                    </MenuContext.Provider>
                </div>
            </div>
        </div>
    );
};

export default HoverMenu;
