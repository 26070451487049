import React, { KeyboardEvent } from 'react';
import { useNavigate } from 'react-router';
import { setMegaMenuOpen } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks/common';

interface HeaderMegaMenuButtonProps {
    title: string;
    to: string;
}

const HeaderMegaMenuButton = ({ title, to }: HeaderMegaMenuButtonProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const dark = useAppSelector(state => state.layout.darkMode);

    const handleSelect = () => {
        navigate(to);
        dispatch(setMegaMenuOpen(false));
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleSelect();
        }
    };

    return (
        <div key={title} className={`header-mega-menu-button ${dark ? 'dark' : ''}`} tabIndex={0} onClick={handleSelect} onKeyDown={handleKeyDown}>
            {title}
        </div>
    );
};

export default HeaderMegaMenuButton;
