import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SystemSlice {
    mepsWarningModalOpen: boolean;
    existingPoleChangingMotorSelected: boolean;
}

const initialState: SystemSlice = {
    mepsWarningModalOpen: false,
    existingPoleChangingMotorSelected: false
};

export const systemSlice = createSlice({
    name: 'system',
    initialState,
    reducers: {
        setMepsWarningModalOpen: (state, action: PayloadAction<boolean>) => {
            state.mepsWarningModalOpen = action.payload;
        }
    }
});

export const { setMepsWarningModalOpen } = systemSlice.actions;
export const systemReducer = systemSlice.reducer;
