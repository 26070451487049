import { useCallback, useRef, useState } from 'react';

export const useValidate = <T>(value: T, validate: (value: T) => boolean, changeValue?: (value: T, name: string) => void, delay?: number):
    { value: T, setValue: (value: T, name: string) => void } => {
    const [unvalidatedValue, setUnvalidatedValue] = useState(value);
    const [valid, setValid] = useState(true);
    const timerRef = useRef<ReturnType<typeof setTimeout>>();

    const setValue = useCallback((newValue: T, name: string) => {
        setUnvalidatedValue(newValue);
        clearTimeout(timerRef.current);
        
        if (validate(newValue)) {
            timerRef.current = setTimeout(() => {
                setValid(true);
                changeValue && changeValue(newValue, name);
            }, delay ?? 500);
        } else {
            setValid(false);
        }
    }, [changeValue, delay, validate]);

    return { value: valid ? value : unvalidatedValue, setValue };
};
