import React, { Fragment, useCallback, useMemo } from 'react';
import { ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Area, Bar, Label, LabelList, Cell } from 'recharts';
import { CHART_ALTERNATIVE, CHART_ALTERNATIVE_DARK, CHART_LOSS, CHART_LOSS_DARK, CHART_REFERENCE, CHART_REFERENCE_DARK, CHART_SAVINGS, CHART_SAVINGS_DARK, LIFETIME_COSTS_STEP_MULTIPLIER, LIFETIME_COSTS_TICK_TOLERANCE, TRANSLATIONS } from '../../../../constants';
import { useAppSelector, useTranslate } from '../../../../hooks/common';
import { IntersectionPoint, LifetimeCostsChartData, LifetimeCostsChartDataPoint } from '../../../../models';
import { numericFormatter } from 'react-number-format';
import ChartWrapper from '../../../common/ChartWrapper';
import LifetimeCostsTooltip, { LifetimeCostsTooltipProps } from './LifetimeCostsTooltip';
import ChartLineDot from '../ChartElements/ChartLineDot';
import { useCurrency } from '../../../../hooks';

export interface LifetimeCostsChartProps {
    data: LifetimeCostsChartData,
    showFinancialSavingPotential: boolean
}

const LifetimeCostsChart = ({ data, showFinancialSavingPotential }: LifetimeCostsChartProps) => {
    const { translate } = useTranslate();
    const dark = useAppSelector(x => x.layout.darkMode);
    const alternativeColor = dark ? CHART_ALTERNATIVE_DARK : CHART_ALTERNATIVE;
    const referenceColor = dark ? CHART_REFERENCE_DARK : CHART_REFERENCE;
    const savingsColor = dark ? CHART_SAVINGS_DARK : CHART_SAVINGS;
    const lossColor = dark ? CHART_LOSS_DARK : CHART_LOSS;
    const barChartLabelColor = dark ? 'black' : 'white';
    const { currencyFormat } = useCurrency();

    const lineChartDataWithIntersection = useMemo(() => data.intersectionPoints.length
        ? [
            ...data.lineChartData,
            ...data.intersectionPoints
                .map<LifetimeCostsChartDataPoint>(point => (
                    {
                        month: point.x,
                        referenceCost: point.y,
                        alternativeCost: point.y,
                        range: [point.y, point.y],
                        intersection: true
                    }))
        ].sort((a, b) => a.month < b.month ? -1 : 1)
        : [...data.lineChartData], [data.intersectionPoints, data.lineChartData]);

    const yAxisTicks = useMemo(() => {
        const maxCost = Math.max(
            data.lineChartData[data.lineChartData.length - 1].alternativeCost,
            data.lineChartData[data.lineChartData.length - 1].referenceCost);
        let stepSize = LIFETIME_COSTS_STEP_MULTIPLIER;
        let numberOfAxisTicks = Math.floor(maxCost / stepSize);

        while (numberOfAxisTicks > 4) {
            stepSize = stepSize * 2;
            numberOfAxisTicks = Math.floor(maxCost / stepSize);
        }

        const largestTickValue = (numberOfAxisTicks + 1) * stepSize;
        const additionalTicks = largestTickValue - maxCost < largestTickValue * LIFETIME_COSTS_TICK_TOLERANCE ? 3 : 2;

        return new Array(numberOfAxisTicks + additionalTicks).fill(0).map((_, index) => index * stepSize);
    }, [data.lineChartData]);

    const xAxisDomain = useMemo(() => [Math.min(...data.months), Math.max(...data.months)], [data.months]);
    const yAxisDomain = useMemo(() => [0, Math.max(...yAxisTicks)], [yAxisTicks]);

    const getIntersectionColor = useCallback((intersection: IntersectionPoint, isLast: boolean | undefined) => {
        if (isLast) {
            return intersection.firstLineIsHigherNext ? lossColor : savingsColor;
        }

        return intersection.firstLineIsHigher ? lossColor : savingsColor;
    }, [lossColor, savingsColor]);

    const formatYAxisTicks = useCallback((value: number) => (value / 1000).toString(), []);

    const savingsBar = useMemo(() =>
        data.barChartData.map((entry, index) => <Cell display={!showFinancialSavingPotential || entry.savings === 0 ? 'none' : ''} key={index} fill={entry.systemType === 'reference' ? lossColor : savingsColor} />),
        [data.barChartData, lossColor, savingsColor, showFinancialSavingPotential]);

    const getLinearGradient = useCallback(() => data.intersectionPoints.length ?
        data.intersectionPoints.map((intersection, i) => {

            const nextIntersection = data.intersectionPoints[i + 1];

            let closeColor = '';
            let startColor = '';

            const isLast = i === data.intersectionPoints.length - 1;
            const offset = intersection.x / Math.max(...data.months);

            if (isLast) {
                closeColor = getIntersectionColor(intersection, false);
                startColor = getIntersectionColor(intersection, true);
            } else {
                closeColor = getIntersectionColor(intersection, true);
                startColor = getIntersectionColor(nextIntersection, false);
            }

            return (
                <Fragment key={i}>
                    <stop
                        offset={offset}
                        stopColor={startColor}
                        stopOpacity={1}
                    />
                    <stop
                        offset={offset}
                        stopColor={closeColor}
                        stopOpacity={1}
                    />
                </Fragment>
            );
        })
        : (
            <stop
                offset={0}
                stopColor={
                    data.lineChartData[0].alternativeCost > data.lineChartData[0].referenceCost ? referenceColor : alternativeColor
                }
            />
        ), [alternativeColor, data, getIntersectionColor, referenceColor]);

    const renderResponsiveContainer = (children: JSX.Element) => (
        <ResponsiveContainer maxHeight={500} aspect={5 / 3}>
            {children}
        </ResponsiveContainer>);

    const renderBarChartResponsiveContainer = (children: JSX.Element) => (
        <ResponsiveContainer maxHeight={120} aspect={50 / 12}>
            {children}
        </ResponsiveContainer>);

    return <div className='lifetime-costs-chart'>
        <ChartWrapper dark={dark} key='energySavingsChart' >
            {renderResponsiveContainer(
                <ComposedChart
                    data={lineChartDataWithIntersection}
                    margin={{
                        top: 5,
                        bottom: 5,
                        right: 5
                    }}
                >
                    <defs>
                        <linearGradient id='areaColor' x1='0%' y1='0%' x2='100%' y2='0%' >
                            {getLinearGradient()}
                        </linearGradient>
                    </defs>
                    <CartesianGrid />
                    <XAxis
                        domain={xAxisDomain}
                        dataKey='month'
                        type='number'
                        ticks={data.months}
                        height={45}
                    >
                        <Label value={`${translate(TRANSLATIONS.generated['Chart.OperationTime'])} [${translate(TRANSLATIONS.generated['Unit.Months'])}]`} offset={10} position='insideBottom' style={{ textAnchor: 'middle' }} />
                    </XAxis>
                    <YAxis
                        orientation='left'
                        domain={yAxisDomain}
                        ticks={yAxisTicks}
                        width={45}
                        tickFormatter={formatYAxisTicks}
                    >
                        <Label value={`${translate(TRANSLATIONS.generated['Chart.TotalCosts'])} [${numericFormatter('1000', currencyFormat)}]`} offset={10} position='insideLeft' angle={-90} style={{ textAnchor: 'middle' }} />
                    </YAxis>
                    {showFinancialSavingPotential && <Area type='monotone' dataKey='range' fill='url(#areaColor)' isAnimationActive={false} activeDot={false} />}
                    <Line
                        className='alternative-line'
                        fill={alternativeColor}
                        dot={<ChartLineDot systemType='alternative' />}
                        activeDot={props => <ChartLineDot {...props} active stroke={alternativeColor} fill={alternativeColor} systemType='alternative' />}
                        name='Alternative System'
                        type='monotone'
                        dataKey='alternativeCost'
                        isAnimationActive={false}
                    />
                    <Line
                        className='reference-line'
                        fill={referenceColor}
                        dot={<ChartLineDot systemType='reference' />}
                        activeDot={props => <ChartLineDot {...props} active stroke={referenceColor} fill={referenceColor} systemType='reference' />}
                        type='monotone'
                        name='Reference System'
                        dataKey='referenceCost'
                        isAnimationActive={false}
                    />
                    <Tooltip content={props => {
                        const newProps = { ...props, alternativeColor, referenceColor } as LifetimeCostsTooltipProps;

                        return <LifetimeCostsTooltip {...newProps} />;
                    }} />
                </ComposedChart>
            )}
        </ChartWrapper>
        <ChartWrapper dark={dark} >
            {renderBarChartResponsiveContainer(
                <ComposedChart
                    className='lifetime-costs-bar-chart'
                    data={data.barChartData}
                    layout='vertical'
                    margin={{
                        top: 5,
                        bottom: 5,
                        right: 5,
                        left: -55
                    }}
                >
                    <YAxis orientation='left' dataKey='systemType' type='category' tick={false} axisLine={false} />
                    <XAxis type='number' scale='linear' axisLine={false} tick={false} orientation='top'>
                        <Label value={`${translate(TRANSLATIONS.generated['Common.TCO'])} [%]`} offset={20} position='insideLeft' style={{ textAnchor: 'middle' }} />
                    </XAxis>
                    <Bar dataKey='investmentCost' stackId={1} barSize={35} fill='url(#barColor)' >
                        <LabelList dataKey='investmentCostPercent' position='middle' offset={5} fill={barChartLabelColor} />
                        {
                            data.barChartData.map((entry, index) => (
                                <Cell key={index} fill={entry.systemType == 'reference' ? referenceColor : alternativeColor} />
                            ))
                        }
                    </Bar>
                    <Bar dataKey='energyCost' stackId={1} barSize={35} fill='url(#barColor)' >
                        <LabelList dataKey='energyCostPercent' position='middle' offset={5} fill={barChartLabelColor} />
                        {
                            data.barChartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.systemType == 'reference' ? referenceColor : alternativeColor} />
                            ))
                        }
                    </Bar>
                    <Bar dataKey='savings' stackId={1} barSize={35} fill='url(#barColor)' >
                        <LabelList dataKey='savingsPercent' position='middle' offset={5} fill={barChartLabelColor} />
                        {savingsBar}
                    </Bar>
                </ComposedChart>)}
        </ChartWrapper >
    </div>;
};

export default LifetimeCostsChart;
