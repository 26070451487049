import React, { useCallback, useMemo } from 'react';
import { ComposedChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Area, Label, ReferenceArea, ReferenceDot, ReferenceLine } from 'recharts';
import { EnergyPerformanceChartData } from '../../../models';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { CHART_ALTERNATIVE, CHART_ALTERNATIVE_DARK, CHART_CONTRACT_RATE, CHART_CONTRACT_RATE_DARK, CHART_ENERGY_COST, CHART_ENERGY_COST_DARK, CHART_REFERENCE, CHART_REFERENCE_DARK, CHART_SAVINGS, CHART_SAVINGS_DARK, ENERGY_PERFORMANCE_STEP_MULTIPLIER, ENERGY_PERFORMANCE_TICK_TOLERANCE, TRANSLATIONS } from '../../../constants';
import ChartWrapper from '../../common/ChartWrapper';
import Triangle from './ChartElements/Triangle';
import { useCurrency } from '../../../hooks';

export interface EnergyPerformanceChartProps {
    data: EnergyPerformanceChartData,
    showDifference: boolean,
    contract: boolean
}

const EnergyPerformanceChart = ({ data, contract, showDifference }: EnergyPerformanceChartProps) => {
    const { translate } = useTranslate();
    const dark = useAppSelector(x => x.layout.darkMode);
    const { currencySymbol } = useCurrency();
    const alternativeColor = dark ? CHART_ALTERNATIVE_DARK : CHART_ALTERNATIVE;
    const contractRateColor = dark ? CHART_CONTRACT_RATE_DARK : CHART_CONTRACT_RATE;
    const energyCostColor = dark ? CHART_ENERGY_COST_DARK : CHART_ENERGY_COST;
    const savingsColor = dark ? CHART_SAVINGS_DARK : CHART_SAVINGS;
    const referenceColor = dark ? CHART_REFERENCE_DARK : CHART_REFERENCE;

    const firstMonth = useMemo(() => Math.min(...data.months), [data.months]);
    const lastMonth = useMemo(() => Math.max(...data.months), [data.months]);
    const contractRateMin = useMemo(() => Math.min(...data.chartData.map(x => x.contractRate ?? 0)) + data.chartData[0].alternativeEnergyCosts, [data.chartData]);
    const contractRateMax = useMemo(() => Math.max(...data.chartData.map(x => x.contractRate ?? 0)) + data.chartData[0].alternativeEnergyCosts, [data.chartData]);
    const contractRateReferenceLineSegment = useMemo(() => [
        {
            x: data.contractPeriod * 12 / 2,
            y: contractRateMax
        },
        {
            x: data.contractPeriod * 12 / 2,
            y: data.chartData[0].referenceEnergyCosts
        }], [contractRateMax, data.chartData, data.contractPeriod]);

    const contractRateReferenceDotCoordinates = useMemo(() => ({
        x: data.contractPeriod * 12 / 2,
            y: Math.max(...data.chartData.map(x => x.contractRate ?? 0)) + data.chartData[0].alternativeEnergyCosts
    }), [data.chartData, data.contractPeriod]);

    const differenceReferenceLineSegment = useMemo(() => [
        {
            x: contract ? data.months[data.months.length - 2] : data.contractPeriod * 12 / 2,
            y: data.chartData[0].alternativeEnergyCosts + 5
        },
        {
            x: contract ? data.months[data.months.length - 2] : data.contractPeriod * 12 / 2,
            y: data.chartData[0].referenceEnergyCosts
        }], [contract, data.chartData, data.contractPeriod, data.months]);

    const differenceReferenceDotCoordinates = useMemo(() => ({
        x: contract ? data.months[data.months.length - 2] : data.contractPeriod * 12 / 2,
            y: data.chartData[0].alternativeEnergyCosts
    }), [contract, data.chartData, data.contractPeriod, data.months]);

    const xAxisTicks = useMemo(() => new Array(data.contractPeriod + 1).fill(0).map((_, i) => i * 12), [data.contractPeriod]);

    const yAxisTicks = useMemo(() => {
        const maxCost = Math.max(
            Math.max(...data.chartData.map(x => x.runningCosts)),
            data.chartData[data.chartData.length - 1].alternativeEnergyCosts,
            data.chartData[data.chartData.length - 1].referenceEnergyCosts);
        let stepSize = ENERGY_PERFORMANCE_STEP_MULTIPLIER;
        let numberOfAxisTicks = Math.floor(maxCost / stepSize);

        while (numberOfAxisTicks > 4) {
            stepSize = stepSize * 2;
            numberOfAxisTicks = Math.floor(maxCost / stepSize);
        }

        const largestTickValue = (numberOfAxisTicks + 1) * stepSize;
        const additionalTicks = largestTickValue - maxCost < largestTickValue * ENERGY_PERFORMANCE_TICK_TOLERANCE ? 3 : 2;

        return new Array(numberOfAxisTicks + additionalTicks + 1).fill(0).map((_, index) => index * stepSize);
    }, [data.chartData]);

    const xAxisDomain = useMemo(() => [Math.min(...data.months), Math.max(...data.months)], [data.months]);
    const yAxisDomain = useMemo(() => [0, Math.max(...yAxisTicks)], [yAxisTicks]);

    const formatYAxisTicks = useCallback((value: number) => Math.max(...yAxisTicks) > 3000
        ? (value / 1000).toString()
        : value.toString(), [yAxisTicks]);

    const todayArrowSegment = useMemo(() => [
        {
            x: data.months[0],
            y: 0.5
        },
        {
            x: data.months[2] - 1,
            y: 0.5
        }], [data.months]);

    const todayArrowHeadPoints = useMemo(() => ({
        x: data.months[2] - 1,
        y: 0.5
    }), [data.months]);

    const todayVerticalLineSegment = useMemo(() => [
        {
            x: data.months[2],
            y: 0
        },
        {
            x: data.months[2],
            y: 1
        }], [data.months]);

    const contractLeftArrowHeadPoints = useMemo(() => ({
        x: data.months[2] + 1,
        y: 0.5
    }), [data.months]);

    const contractArrowLineSegment = useMemo(() => [
        {
            x: data.months[2] + 1,
            y: 0.5
        },
        {
            x: data.months[data.months.length - 3] - 1,
            y: 0.5
        }], [data.months]);

    const contractRightArrowHeadPoints = useMemo(() => ({
        x: data.months[data.months.length - 3] - 1,
        y: 0.5
    }), [data.months]);

    const contractVerticalLineSegment = useMemo(() => [
        {
            x: data.months[data.months.length - 3],
            y: 0
        },
        {
            x: data.months[data.months.length - 3],
            y: 1
        }], [data.months]);

    const futureArrowLineSegment = useMemo(() => [
        {
            x: data.months[data.months.length - 3] + 1,
            y: 0.5
        },
        {
            x: data.months[data.months.length - 1],
            y: 0.5
        }], [data.months]);

    const futureArrowHeadPoints = useMemo(() => ({
        x: data.months[data.months.length - 1],
        y: 0.5
    }), [data.months]);

    const renderResponsiveContainer = (children: JSX.Element) => (
        <ResponsiveContainer maxHeight={500} aspect={5 / 3}>
            {children}
        </ResponsiveContainer>);

    const renderArrowChartResponsiveContainer = (children: JSX.Element) => (
        <ResponsiveContainer maxHeight={20} aspect={10 / 1}>
            {children}
        </ResponsiveContainer>);
   
    return (
        <div className='lifetime-costs-chart'>
            <ChartWrapper dark={dark}>
                {renderResponsiveContainer(
                    <ComposedChart
                        data={data.chartData}
                        margin={{
                            top: 5,
                            bottom: 5,
                            right: 0
                        }}
                    >
                        <CartesianGrid />
                        <XAxis
                            domain={xAxisDomain}
                            dataKey='month'
                            type='number'
                            ticks={xAxisTicks}
                            height={25}
                        >
                            <Label
                                value={`${translate(TRANSLATIONS.generated['Common.Time'])} [${translate(TRANSLATIONS.generated['Common.Months'])}]`}
                                offset={10}
                                dy={12}
                                position='insideRight'
                                style={{ textAnchor: 'end' }}
                            />
                            <Label
                                value={translate(TRANSLATIONS.generated['Common.Today'])}
                                offset={10}
                                dy={12}
                                position='insideLeft'
                                style={{ textAnchor: 'start' }}
                            />
                        </XAxis>
                        <YAxis
                            orientation='left'
                            domain={yAxisDomain}
                            ticks={yAxisTicks}
                            width={45}
                            tickFormatter={formatYAxisTicks}
                        >
                            <Label
                                value={`${translate(TRANSLATIONS.generated['System.EnergyCosts'])} [${currencySymbol}]`}
                                offset={10}
                                position='insideLeft'
                                angle={-90}
                                style={{ textAnchor: 'middle' }}
                            />
                        </YAxis>
                   
                        <Area
                            dataKey={contract ? 'savingsRange' : 'savingsRangeWithoutContract'}
                            fill={savingsColor}
                            strokeOpacity={0}
                            type='stepBefore'
                            name='Savings'
                            isAnimationActive={false}
                        />
                        <Area
                            dataKey='energyCostsRange'
                            fill={energyCostColor}
                            strokeOpacity={0}
                            type='stepBefore'
                            name='Energy costs'
                            isAnimationActive={false}
                        />
                        <ReferenceArea
                            name='Energy costs label'
                            x1={firstMonth}
                            x2={lastMonth}
                            y1={0}
                            y2={data.chartData[0].alternativeEnergyCosts}
                            fill={energyCostColor}
                            label={<Label value={translate(TRANSLATIONS.generated['System.EnergyCosts'])} fill={dark ? 'white' : 'black'} position='middle' strokeWidth={1} />}
                        />
                        {contract && <Area
                            dataKey='contractRateRange'
                            fill={contractRateColor}
                            strokeOpacity={0}
                            type='stepBefore'
                            name='Contract rate'
                            isAnimationActive={false}
                        />}
                        {contract && <ReferenceArea
                            name='Contract rate label'
                            x1={0}
                            x2={data.months[data.months.length - 3]}
                            y1={contractRateMin}
                            y2={contractRateMax}
                            fillOpacity={0}
                            label={<Label value={translate(TRANSLATIONS.generated['System.ContractRate'])} fill={dark ? 'white' : 'black'} position='middle' strokeWidth={1} />}
                        />}
                        <Line
                            className='reference-line'
                            dot={false}
                            fill={referenceColor}
                            strokeDasharray='10 5'
                            type='monotone'
                            name='Reference System'
                            dataKey='referenceEnergyCosts'
                            isAnimationActive={false}
                        />
                        <Line
                            className='alternative-line'
                            dot={false}
                            fill={alternativeColor}
                            strokeDasharray='10 5'
                            name='Alternative System'
                            type='monotone'
                            dataKey='alternativeEnergyCosts'
                            isAnimationActive={false}
                        />
                        <Line
                            fill={referenceColor}
                            dot={false}
                            type='stepBefore'
                            name='Reference System'
                            dataKey={contract ? 'runningCosts' : 'runningCostsWithoutContract'}
                            isAnimationActive={false}
                        />
                        {showDifference && contract && <ReferenceLine
                            label={<Label value={`${data.differenceInContractPeriod.toFixed(2)} %`} fill={dark ? 'white' : 'black'} position='top' strokeWidth={1} />}
                            stroke={dark ? 'white' : 'black'}
                            strokeWidth={2}
                            segment={contractRateReferenceLineSegment}
                        />}
                        {showDifference && contract && <ReferenceDot
                            {...contractRateReferenceDotCoordinates}
                            shape={props => Triangle({ ...props, orientation: data.differenceInContractPeriod > 0 ? 'down' : 'up' })}
                        />}
                        {showDifference && <ReferenceLine
                            label={<Label value={`${data.differenceOutsideContractPeriod.toFixed(2)} %`} fill={dark ? 'white' : 'black'} position='top' strokeWidth={1} />}
                            stroke={dark ? 'white' : 'black'}
                            strokeWidth={2}
                            segment={differenceReferenceLineSegment}
                        />}
                        {showDifference && <ReferenceDot
                            {...differenceReferenceDotCoordinates}
                            shape={props => Triangle({ ...props, orientation: 'down' })}
                        />}
                    </ComposedChart>

                )}
            </ChartWrapper>
            <ChartWrapper dark={dark} >
                {renderArrowChartResponsiveContainer(
                    <ComposedChart
                        className='energy-performance-arrow-chart'
                        data={data.chartData}
                        margin={{
                            top: 5,
                            bottom: 5,
                            right: 0,
                            left: 45
                        }}
                    >
                        <XAxis
                            domain={xAxisDomain}
                            dataKey='month'
                            type='number'
                            interval={0}
                            ticks={xAxisTicks}
                            height={45}
                            hide={true}
                        />
                        <YAxis
                            orientation='left'
                            domain={[0, 1]}
                            ticks={[0, 1]}
                            width={45}
                            tickFormatter={formatYAxisTicks}
                            hide={true}

                        />
                        <ReferenceLine
                            stroke={dark ? 'white' : 'black'}
                            strokeWidth={1}
                            segment={todayArrowSegment}
                        />
                        <ReferenceDot
                            {...todayArrowHeadPoints}
                            shape={props => Triangle({ ...props, orientation: 'right' })}
                        />
                        <ReferenceLine
                            stroke={dark ? 'white' : 'black'}
                            strokeWidth={1}
                            segment={todayVerticalLineSegment}
                        />
                        <ReferenceDot
                            {...contractLeftArrowHeadPoints}
                            shape={props => Triangle({ ...props, orientation: 'left' })}
                        />
                        <ReferenceLine
                            stroke={dark ? 'white' : 'black'}
                            strokeWidth={1}
                            segment={contractArrowLineSegment}
                        />
                        <ReferenceDot
                            {...contractRightArrowHeadPoints}
                            shape={props => Triangle({ ...props, orientation: 'right' })}
                        />
                        <ReferenceLine
                            stroke={dark ? 'white' : 'black'}
                            strokeWidth={1}
                            segment={contractVerticalLineSegment}
                        />
                        <ReferenceLine
                            stroke={dark ? 'white' : 'black'}
                            strokeWidth={1}
                            segment={futureArrowLineSegment}
                        />
                        <ReferenceDot
                            {...futureArrowHeadPoints}
                            shape={props => Triangle({ ...props, orientation: 'right' })}
                        />
                    </ComposedChart>)}
            </ChartWrapper >
        </div>);
};

export default EnergyPerformanceChart;
