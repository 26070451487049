import { useEffect, useState } from 'react';

export const usePageSize = () => {
    const [pageSize, setPageSize] = useState({ height: 0, width: 0 });

    useEffect(() => {
        const handleResize = () => {
            setPageSize({ height: window.innerHeight, width: window.innerWidth });
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return pageSize;
};
