import React, { useCallback } from 'react';
import Button, { ButtonType } from './Button';
import Image from './Image';

export interface CardCommon {
    description: string;
    image: string;
}

interface InteractiveCard extends CardCommon {
    buttonLabel: string;
    onClick: () => void;
    title: string;
}

export type CardType = InteractiveCard | CardCommon;

const Card = ({ description, image, ...rest }: CardType) => {
    const isInteractive = 'title' in rest;

    const handleClick = useCallback(() => {
        if (isInteractive) {
            rest.onClick();
        }
    }, [isInteractive, rest]);

    return (
        <div className={`card ${isInteractive && 'interactive'}`}>
            <div className='card-image-container'>
                <Image source={image} />
            </div>
            {isInteractive && <div className='card-title'>{rest.title}</div>}
            <div className='card-description'>{description}</div>
            {isInteractive && (
                <div className='card-button-container'>
                    <Button type={ButtonType.Primary} dark onClick={() => handleClick()}>
                        {rest.buttonLabel}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default Card;
