import React, { CSSProperties } from 'react';

export interface ColorfulBarProps {
    color: 'reference' | 'alternative';
    dark?: boolean;
    style?: CSSProperties;
}

const ColorfulBar = ({ color, dark, style }: ColorfulBarProps) => (
        <div className={`colorful-bar colorful-bar-${color ?? ''} ${dark ? 'dark' : ''}`} style={style}></div>
);

export default ColorfulBar;
