import { TechnicalDataParams } from '../models';

export const MOTOR_TECHNICAL_DATA_INITIAL_PARAMS: TechnicalDataParams = {
    KMATInitType: 'MLFB',
    Caller: 'SINASAVE',
    ConfigId: '47',
    CATMode: 'online',
    ReturnTarget: '_top',
    Version: '2.0',
    CIScenario: 'preallocation',
    HookUrl: 'https://mall.industry.siemens.com/sizer-offline.ok',
    ReturnUrl: 'https://mall.industry.siemens.com/sizer-offline.cancel',
    ResultType: 'PAYLOAD'
};
