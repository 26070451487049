import React, { useMemo, useRef } from 'react';
import { InputType } from '../../../enums';
import { ApplicationType } from '../../../types';
import { ImageDropdownItem, SelectInputItem } from '../../../models';
import { TRANSLATIONS } from '../../../constants';
import { getMepsWarning } from '../../../services';
import { useHook, useTechnicalData } from '../../../hooks';
import { useTranslate, useAppSelector } from '../../../hooks/common';
import Box from '../../common/Box';
import Flex from '../../common/Flex';
import Grid from '../../common/Grid';
import { IconType } from '../../common/Icon';
import Link from '../../common/Link';
import Loader from '../../common/Loader';
import Text from '../../common/Text';
import SystemElement from '../SystemElement';
import BasicViewMotorInfo from './ModalInfo/BasicViewMotorInfo';
import BasicViewPumpControlModeInfo from './ModalInfo/BasicViewPumpControlModeInfo';
import BasicViewSwitchGearInfo from './ModalInfo/BasicViewSwitchGearInfo';
import MotorTechnicalDataForm from '../MotorTechnicalDataForm';

interface BasicViewCardProps {
    applicationType: ApplicationType;
    systemType: 'reference' | 'alternative';
}

const BasicViewCard = ({ applicationType, systemType }: BasicViewCardProps) => {
    const { dynamicTranslate, translate } = useTranslate();
    const {
        cardRef,
        controlComponent,
        controlComponentDescription,
        controlInputs,
        controlModeDescription,
        controlModeInput,
        dark,
        getWidth,
        handleSystemInputChange,
        motorComponentDescription,
        motorInputs,
        systemLoading
    } = useHook(x => x.basicView({ applicationType, systemType }));

    const technicalDataFormRef = useRef<HTMLFormElement>(null);
    const { mlfb, callMotorTechnicalData, callControlUnitTechnicalData } = useTechnicalData({ formRef: technicalDataFormRef, applicationType, systemType });
    const isPumpSystem = applicationType === 'pump';
    const hasMepsWarning = getMepsWarning(systemType, useAppSelector(state => isPumpSystem ? state.pumpSystem : state.fanSystem));
    const imageDropdownItemOfMotor = useMemo(() => motorInputs.find(x => x.inputType === InputType.ImageDropdown) as ImageDropdownItem, [motorInputs]);
    const imageDropdownItemOfControl = useMemo(() => controlInputs.find(x => x.inputType === InputType.ImageDropdown) as ImageDropdownItem, [controlInputs]);
    const selectInputItemOfBasicType = useMemo(() => controlInputs.find(x => x.name === 'System.Switchgear.BasicType') as SelectInputItem, [controlInputs]);
    const motorChangedToExistingPoleChangingMotor = imageDropdownItemOfMotor.selectedValue.title === 'System.Motor.ExistingPoleChangingMotor';
    const thirdPartySelected = imageDropdownItemOfControl.selectedValue.title === 'System.Switchgear.ThirdParty'
        || imageDropdownItemOfControl.selectedValue.title === 'System.Converter.ThirdParty';
    const basicTypeIsNotSoftStarter = imageDropdownItemOfControl.selectedValue.title === 'System.Switchgear.Siemens'
        && selectInputItemOfBasicType.current.value !== 'System.Switchgear.BasicTypes.SoftStarter';
    const hideSwitchgearLink = motorChangedToExistingPoleChangingMotor || thirdPartySelected || basicTypeIsNotSoftStarter;
    const hideMotorLink = imageDropdownItemOfMotor.selectedValue.title === 'System.Motor.ExistingPoleChangingMotor'
        || imageDropdownItemOfMotor.selectedValue.title === 'Motor.YourMotor';

    const renderLink = (type: 'motor' | 'switchgear') => {
        return (type === 'motor' ? hideMotorLink : hideSwitchgearLink)
            ? undefined
            : <Flex>
                <Link
                    onClick={type === 'motor' ? callMotorTechnicalData : callControlUnitTechnicalData}
                    icon={IconType.LinkExtern}
                    iconPosition='left'
                    text={translate(TRANSLATIONS.generated['Motor.GetTechnicalData'])}
                    dark={dark}
                    style={{ paddingTop: 8 }}
                    noWrap
                />
            </Flex>;
    };

    return (
        <Loader dark={dark} loading={systemLoading} disableInitialLoading>
            <div className={`${systemType}-card`} ref={cardRef}>
                <MotorTechnicalDataForm formRef={technicalDataFormRef} mlfb={mlfb} />
                <Box edgeColor={systemType} dark={dark} style={{ padding: 16, paddingBottom: 0 }}>
                    <Text
                        color={systemType}
                        dark={dark}
                        type='subtitle-small'
                        style={{ paddingBottom: 24 }}
                    >
                        {systemType == 'reference' ? translate(TRANSLATIONS.generated['System.ReferenceSystem']) : translate(TRANSLATIONS.generated['System.AlternativeSystem'])}
                    </Text>
                    <Grid columns={1}>
                        <SystemElement
                            description={dynamicTranslate(TRANSLATIONS.generated, `${controlModeDescription}Controller`)}
                            getAvailableSpace={getWidth}
                            handleSystemInputChange={handleSystemInputChange}
                            info={<BasicViewPumpControlModeInfo />}
                            inputs={controlModeInput}
                            key='control-mode'
                            title={translate(TRANSLATIONS.generated['System.ControlMode'])}
                        />
                        <SystemElement
                            description={motorComponentDescription}
                            getAvailableSpace={getWidth}
                            handleSystemInputChange={handleSystemInputChange}
                            info={<BasicViewMotorInfo inputs={motorInputs} />}
                            inputs={motorInputs}
                            key='motor'
                            warning={hasMepsWarning}
                            title={translate(TRANSLATIONS.generated['System.Motor'])}
                            link={renderLink('motor')}
                            needDoubleLine={motorChangedToExistingPoleChangingMotor}
                        />
                        <SystemElement
                            description={controlComponentDescription}
                            getAvailableSpace={getWidth}
                            handleSystemInputChange={handleSystemInputChange}
                            info={<BasicViewSwitchGearInfo inputs={controlInputs} />}
                            inputs={controlInputs}
                            key='control-component'
                            style={{ height: 140 }}
                            title={dynamicTranslate(TRANSLATIONS.generated, controlComponent.name)}
                            link={renderLink('switchgear')}
                        />
                    </Grid>
                </Box>
            </div>
        </Loader>
    );
};

export default BasicViewCard;
