import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector, useOpenAnimations } from '../../hooks/common';
import HeaderMegaMenuButton from './HeaderMegaMenuButton';
import IconButton from '../common/IconButton';
import { IconType } from '../common/Icon';
import { setMegaMenuOpen } from '../../store';

const HeaderMegaMenu = () => {
    const dispatch = useAppDispatch();
    const dark = useAppSelector(state => state.layout.darkMode);
    const open = useAppSelector(state => state.layout.megaMenuOpen);
    const [animationClass, handleAnimationEnd] = useOpenAnimations(open);

    const handleClose = useCallback(() => dispatch(setMegaMenuOpen(false)), [dispatch]);

    return (
        <>
            <div className={`header-mega-menu ${animationClass} ${dark ? 'dark' : ''}`}>
                <div className='header-mega-menu-background' />
                <div className='header-mega-menu-content' onAnimationEnd={handleAnimationEnd}>
                    <div className='header-mega-menu-header'>
                        <div className='header-mega-menu-header-title'>SinaSave</div>
                        <IconButton dark={dark} iconType={IconType.CloseBig} layer={2} onClick={handleClose} />
                    </div>
                    <HeaderMegaMenuButton title='Home' to='/' />
                    <HeaderMegaMenuButton title='Pump systems' to='/pump-systems' />
                    <HeaderMegaMenuButton title='Fan systems' to='/fan-systems' />
                </div>
            </div>
        </>
    );
};

export default HeaderMegaMenu;
