import React, { ReactNode } from 'react';
import { HeaderTab } from '../../enums';
import { useAppSelector } from '../../hooks/common';
import Footer from './Footer';
import Header from './Header';
import HeaderKeyVisual from './HeaderKeyVisual';
import HeaderMegaMenu from './HeaderMegaMenu';
import Snackbars from './Snackbars';
import MepsWarningModal from '../application/MepsWarningModal';
import keyVisual from '../../../assets/images/KEYVISUAL.png';
import keyVisualDark from '../../../assets/images/KEYVISUAL_DARK.png';

interface LayoutProps {
    children?: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
    const activeTabType = useAppSelector(state => state.layout.activeTab);
    const dark = useAppSelector(state => state.layout.darkMode);

    return (
        <div className={`layout ${dark ? 'dark' : ''}`}>
            <Header title='SinaSave' />
            <HeaderKeyVisual height={860} url={dark ? keyVisualDark : keyVisual} hidden={activeTabType !== HeaderTab.Home} />
            <HeaderMegaMenu />
            <Snackbars />
            <MepsWarningModal />
            <main id='main' tabIndex={-1}>
                {children}
            </main>
            <Footer />
        </div>
    );
};

export default Layout;
