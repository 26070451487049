import React from 'react';
import { TRANSLATIONS } from '../../../../constants';
import { useAppSelector, useTranslate } from '../../../../hooks/common';
import Text from '../../../common/Text';

const CommercialViewGovernmentFundingInfo = () => {
    const dark = useAppSelector(state => state.layout.darkMode);
    const { translate } = useTranslate();

    return (
        <>
            <Text dark={dark} type='subtitle' style={{ paddingBottom: 8 }}>
                {translate(TRANSLATIONS.generated['Help.GovernmentFunding.Title'])}
            </Text>
            <ul>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.GovernmentFunding.Content'][0])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.GovernmentFunding.Content'][1])}
                    </Text>
                </li>
            </ul>
        </>
    );
};

export default CommercialViewGovernmentFundingInfo;
