import React, { useState } from 'react';
import HoverMenu from '../common/HoverMenu';
import IconButton from '../common/IconButton';
import MenuItem from '../common/MenuItem';
import { IconType } from '../common/Icon';
import { useAppSelector, useTranslate } from '../../hooks/common';
import { useSystemActions } from '../../hooks/application/SystemActions';
import { ApplicationType } from '../../types';
import { TRANSLATIONS } from '../../constants';

interface SystemActionsProps {
    applicationType: ApplicationType
}

const SystemActionsMenu = ({ applicationType }: SystemActionsProps) => {
    const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
    const dark = useAppSelector(state => state.layout.darkMode);
    const { handleResetSystem } = useSystemActions({ applicationType });
    const { translate } = useTranslate();

    return (
        <HoverMenu
            open={optionsMenuOpen}
            toggleElement={
                <IconButton dark={dark} iconType={IconType.Options} layer={2} onClick={() => setOptionsMenuOpen(x => !x)} />
            }
            onOpenChange={setOptionsMenuOpen}
            dark={dark}
            position='left'
        >
        <MenuItem
            label={translate(TRANSLATIONS.main.resetSystem)}
            onClick={handleResetSystem}
            dark={dark}
        />
        </HoverMenu>
    );
};

export default SystemActionsMenu;
