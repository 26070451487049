export const CHART_REFERENCE = '#553BA3';
export const CHART_REFERENCE_DARK = '#805CFF';
export const CHART_ALTERNATIVE = '#EC6602';
export const CHART_ALTERNATIVE_DARK = '#FF9000';
export const CHART_OPERATION_PROFILE = '#1491EB';
export const CHART_SAVINGS = '#00AF8E';
export const CHART_SAVINGS_DARK = '#00D7A0';
export const CHART_LOSS = '#D72339';
export const CHART_LOSS_DARK = '#FF2640';
export const CHART_CONTRACT_RATE = '#00BEDC';
export const CHART_CONTRACT_RATE_DARK = '#00E6DC';
export const CHART_ENERGY_COST = '#007EB1';
export const CHART_ENERGY_COST_DARK = '#00BEDC';
export const CHART_APPLICATION_POWER_DEMAND = '#000028';
