import React, { useState } from 'react';
import { useAppSelector } from '../../hooks/common';
import IconButton from '../common/IconButton';
import { IconType } from '../common/Icon';
import Modal from '../common/Modal';

interface SystemCircuitProps {
    offset?: 8 | 16;
    modalContent?: JSX.Element;
}

const SystemCircuit = ({ offset, modalContent }: SystemCircuitProps) => {
    const offsetClass = offset ? `system-circuit-offset-${offset}` : '';
    const dark = useAppSelector(state => state.layout.darkMode);
    const [systemCircuitModalOpen, setSystemCircuitModalOpen] = useState(false);

    return (
        <div className={`system-circuit ${offsetClass} ${dark ? 'dark' : ''}`} >
            <div className='system-circuit-bottom-1' />
            <div className='system-circuit-bottom-2'>
                <div className='dot' />
            </div>
            <div className='system-circuit-bottom-3' />
            <div className='system-circuit-bottom-4' />
            <div className='system-circuit-line-1-1' />
            <div className='system-circuit-line-1-2' />
            <div className='system-circuit-line-1-3' />
            <div className='system-circuit-line-2-1' />
            <div className='system-circuit-line-2-2' />
            <div className='system-circuit-line-2-3' />
            <div className='system-circuit-top-1' />
            <div className='system-circuit-top-2' />
            <div className='system-circuit-top-3' />
            <div className='system-circuit-vertical-line' />
            <div className='system-circuit-information'>
                {modalContent && <IconButton onClick={() => setSystemCircuitModalOpen(true)} dark={dark} iconType={IconType.Information} size={16} />}
            </div>
            {modalContent && <Modal open={systemCircuitModalOpen} allowClickAway dark={dark} movable onClose={() => setSystemCircuitModalOpen(false)} >{modalContent}</Modal>}
        </div>
    );

};

export default SystemCircuit;
