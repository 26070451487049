import { useAppSelector } from '.';
import { Translations } from '../../models';

export const useTranslate = () => {
    const language = useAppSelector(state => state.translation.language);

    const translate = (translations: string[]) => translations[language] === '' ? translations[0] : translations[language];

    const dynamicTranslate = (translation: Translations, label?: string) => {
        const labelAsKey = label as keyof typeof translation;

        const translationKeyExists = label && Object.keys(translation).find(x => x == label);

        if (!translationKeyExists) {
            labelAsKey !== null && console.error(`Translation is missing for label ${label}`);

            return '';
        }

        const translations = translation[labelAsKey];

        return Array.isArray(translations)
            ? translate(translation[labelAsKey] as string[])
            : Object.keys(translations).map(x => translate(translations[x] as string[])).join('');
    };

    return {
        translate,
        dynamicTranslate
    };
};
