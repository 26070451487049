import React, { useCallback, useMemo } from 'react';
import { Dot, ComposedChart, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Label, Bar, LabelList, Scatter, DotProps } from 'recharts';
import { numericFormatter } from 'react-number-format';
import { PowerlossCalculationChartData } from '../../../../models';
import { POWER_LOSS_SPEEDS, POWER_LOSS_TORQUES, TRANSLATIONS } from '../../../../constants';
import { useAppSelector, useTranslate } from '../../../../hooks/common';
import ChartWrapper from '../../../common/ChartWrapper';

export interface PowerlossCalculationChartProps {
    data?: PowerlossCalculationChartData,
}

const PowerlossCalculationChart = ({ data }: PowerlossCalculationChartProps) => {
    const { translate } = useTranslate();
    const dark = useAppSelector(x => x.layout.darkMode);
    const { numberFormatOptions } = useAppSelector(x => x.regionSettings);
    const scatterXAxisDomain = useMemo(() => [Math.min(...POWER_LOSS_SPEEDS), Math.max(...POWER_LOSS_SPEEDS) + 25 - 0.5], []);
    const mainYAxisDomain = useMemo(() => [Math.min(...POWER_LOSS_TORQUES), Math.max(...POWER_LOSS_TORQUES) + 25], []);

    const renderAxes = useCallback(() => {
        const axisList = [
            <XAxis key='mainXAxis' dataKey='name' xAxisId='main' scale='band' padding={{ left: -2 }} height={45} dy={10}>
                <Label value={`${translate(TRANSLATIONS.generated['System.Speed'])} [%]`} offset={5} position='insideBottom' style={{ textAnchor: 'middle' }} />
            </XAxis>,
            //- 0.5 below is a correction for scaling with respect to 'category' type values
            <XAxis key='scatterXAxis' dataKey='name' xAxisId='scatter' hide domain={scatterXAxisDomain} type='number' />,
            <YAxis key='mainYAxis' orientation='left' domain={mainYAxisDomain} ticks={POWER_LOSS_TORQUES} width={45}>,
                <Label value={`${translate(TRANSLATIONS.generated['System.Torque'])} [%]`} offset={10} position='insideLeft' angle={-90} style={{ textAnchor: 'middle' }} />
            </YAxis>,
            ...POWER_LOSS_TORQUES.map(x => <XAxis key={`xAxisForTorque${x}`} dataKey='name' xAxisId={x} scale='band' padding={{ left: -2 }} hide />)
        ];

        return axisList;
    }, [mainYAxisDomain, scatterXAxisDomain, translate]);

    const hasBarChartData = (type: 'reference' | 'alternative' | 'iesReference', torque: number) => Boolean(data?.chartData && data.chartData[0][`${type}PowerLossRange${torque}Torque`]);

    const renderLabelContent = useCallback((value: number) => numericFormatter(value.toString(), numberFormatOptions), [numberFormatOptions]);

    const renderBars = useCallback((type: 'reference' | 'alternative' | 'iesReference') => POWER_LOSS_TORQUES.map(torque =>
        <Bar key={`${type}${torque}`} className={type} name={`${type}Torque${torque}`} dataKey={`${type}PowerLossRange${torque}Torque`} xAxisId={torque} barSize={30} style={{ strokeWidth: 0 }}>
            <LabelList dataKey={`${type}PowerLossRange${torque}TorqueDisplay`} formatter={renderLabelContent} position='bottom' offset={5} />
        </Bar>
    ), [renderLabelContent]);

    const renderDot = ({ cx, cy }: DotProps) => <Dot cx={cx} cy={cy} r={4} />;

    const renderResponsiveContainer = (children: JSX.Element) =>
        <ResponsiveContainer maxHeight={500} aspect={5 / 3}>
            {children}
        </ResponsiveContainer>;

    const renderScatterCharts = useCallback(() =>
        POWER_LOSS_TORQUES.map(torque =>
            <Scatter key={torque} className='display-point' name='points' dataKey={`scatterPoint${torque}`} xAxisId='scatter' shape={renderDot} />
        ), []);


    return (
        <div className='powerloss-calculation-chart'>
            <ChartWrapper dark={dark} >
                {renderResponsiveContainer(
                    <ComposedChart
                        data={data?.chartData}
                        margin={{
                            top: 5,
                            bottom: 5,
                            right: 0
                        }}
                    >
                        <CartesianGrid />
                        {renderAxes()}
                        {hasBarChartData('iesReference', POWER_LOSS_TORQUES[0]) && renderBars('iesReference')}
                        {hasBarChartData('reference', POWER_LOSS_TORQUES[0]) && renderBars('reference')}
                        {hasBarChartData('alternative', POWER_LOSS_TORQUES[0]) && renderBars('alternative')}
                        {renderScatterCharts()}
                    </ComposedChart>
                )}
            </ChartWrapper>
        </div>);
};

export default PowerlossCalculationChart;
