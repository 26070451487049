import React, { CSSProperties, ReactNode } from 'react';

interface GridProps {
    children: ReactNode;
    columns: number;
    columns1500?: number;
    columns1200?: number;
    columns992?: number;
    columns768?: number;
    columns600?: number;
    columns480?: number;
    columnGap?: number;
    rowGap?: number;
    style?: CSSProperties;
}

const Grid = ({ children, columns, columns1500, columns1200, columns992, columns768, columns600, columns480, columnGap, rowGap, style }: GridProps) => {
    const getMedia = (maxWidth: number, columns: number | undefined) => columns ? `grid-columns-${maxWidth}-${columns}` : '';

    return (
        <div
            className={
                `grid-container grid-columns-${columns} ${getMedia(1500, columns1500)} ${getMedia(1200, columns1200)} ${getMedia(992, columns992)}` +
                ` ${getMedia(768, columns768)} ${getMedia(600, columns600)} ${getMedia(480, columns480)}`
            }
            style={{ ...style, columnGap: columnGap ?? 0, rowGap: rowGap ?? 0 }}
        >
            {children}
        </div>
    );
};

export default Grid;
