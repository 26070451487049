import React from 'react';
import { TRANSLATIONS } from '../../../../constants';
import { Language } from '../../../../enums';
import { useAppSelector, useTranslate } from '../../../../hooks/common';
import Text from '../../../common/Text';

const CommercialViewMotorInfo = () => {
    const dark = useAppSelector(state => state.layout.darkMode);
    const language = useAppSelector(state => state.translation.language);
    const { translate } = useTranslate();

    const infoEnglish = (
        <>
            <ul>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.MotorCommercial.Content'][0])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.MotorCommercial.Content'][1])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.MotorCommercial.Content'][2])}
                    </Text>
                </li>
            </ul>
        </>
    );

    const infoGerman = (
        <>
            <ul>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.MotorCommercial.Content'][0])}
                        <strong>{translate(TRANSLATIONS.generated['Help.MotorCommercial.Content'][1])}</strong>
                        {translate(TRANSLATIONS.generated['Help.MotorCommercial.Content'][2])}
                        <strong>{translate(TRANSLATIONS.generated['Help.MotorCommercial.Content'][3])}</strong>
                        {translate(TRANSLATIONS.generated['Help.MotorCommercial.Content'][4])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.MotorCommercial.Content'][5])}
                        <strong>{translate(TRANSLATIONS.generated['Help.MotorCommercial.Content'][6])}</strong>
                        {translate(TRANSLATIONS.generated['Help.MotorCommercial.Content'][7])}
                        <strong>{translate(TRANSLATIONS.generated['Help.MotorCommercial.Content'][8])}</strong>
                        {translate(TRANSLATIONS.generated['Help.MotorCommercial.Content'][9])}
                        <strong>{translate(TRANSLATIONS.generated['Help.MotorCommercial.Content'][10])}</strong>
                        {translate(TRANSLATIONS.generated['Help.MotorCommercial.Content'][11])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.MotorCommercial.Content'][12])}
                        <strong>{translate(TRANSLATIONS.generated['Help.MotorCommercial.Content'][13])}</strong>
                        {translate(TRANSLATIONS.generated['Help.MotorCommercial.Content'][14])}
                    </Text>
                </li>
            </ul>
        </>
    );

    return (
        <>
            <Text dark={dark} type='subtitle' style={{ paddingBottom: 8 }}>
                {translate(TRANSLATIONS.generated['Help.MotorCommercial.Title'])}
            </Text>
            {language === Language.En ? infoEnglish : infoGerman}
        </>
    );
};

export default CommercialViewMotorInfo;
