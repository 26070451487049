import React from 'react';
import Icon, { IconType } from './Icon';

export type StatusIconType = 'error' | 'info' | 'success' | 'warning';

interface StatusIconProps {
    type: StatusIconType;
    dark?: boolean;
    width?: number;
}

const StatusIcon = ({ dark, type, width }: StatusIconProps) => {
    const iconType = ((): IconType => {
        switch (type) {
            case 'error':
                return IconType.ErrorFilled;
            case 'info':
                return IconType.InformationFilled;
            case 'success':
                return IconType.SuccessFilled;
            case 'warning':
                return IconType.WarningFilled;
        }
    })();

    return (
        <div className={`status-icon status-icon-${type} ${dark ? 'dark' : ''}`} style={{ ['--icon-width' as string]: width ? `${width}px` : undefined }}>
            <Icon type={iconType} />
        </div>
    );
};

export default StatusIcon;
