import { RefObject, useRef, useState } from 'react';

export const useOpenAnimations = <T extends HTMLElement>(open: boolean): ['open' | 'opening' | 'closing' | 'closed', () => void, RefObject<T>, number | 'auto'] => {
    const [animating, setAnimating] = useState(false);
    const [previousOpen, setPreviousOpen] = useState<boolean | undefined>(undefined);
    const contentRef = useRef<T>(null);

    let calculatedOpen = open;

    if (previousOpen !== open) {
        if (!animating && previousOpen !== undefined) {
            calculatedOpen = previousOpen;
            setAnimating(true);
        }

        setPreviousOpen(open);
    }

    const getAnimationClass = () => calculatedOpen
        ? animating ? 'opening' : 'open'
        : animating ? 'closing' : 'closed';

    const getHeight = () => animating
        ? calculatedOpen ? contentRef.current?.scrollHeight ?? 0 : contentRef.current?.clientHeight ?? 0
        : calculatedOpen ? 'auto' : 0;

    const handleAnimationEnd = () => {
        setAnimating(false);
    };

    return [getAnimationClass(), handleAnimationEnd, contentRef, getHeight()];
};
