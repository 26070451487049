import React, { ChangeEvent, CSSProperties, DetailedHTMLProps, InputHTMLAttributes, KeyboardEvent, KeyboardEventHandler, useCallback, useMemo } from 'react';
import { InputAttributes, NumericFormat, NumericFormatProps, useNumericFormat } from 'react-number-format';

interface InputProps {
    label: string;
    name: string;
    value: string;
    dark?: boolean;
    disabled?: boolean;
    error?: boolean;
    highlighted?: boolean;
    numberFormatOptions?: NumericFormatProps<InputAttributes>;
    placeholder?: string;
    readOnly?: boolean;
    required?: boolean;
    style?: CSSProperties;
    getMessage?: (value: string) => string;
    onBlur?: () => void;
    onChange?: (value: string, name: string) => void;
    onSubmit?: () => void;
    withFullBorder?: boolean;
}

const TextInput = ({
    dark,
    disabled,
    highlighted,
    error,
    label,
    name,
    numberFormatOptions,
    placeholder,
    readOnly,
    required,
    style,
    value,
    getMessage,
    onBlur,
    onChange,
    onSubmit,
    withFullBorder
}: InputProps) => {
    const message = getMessage ? getMessage(value) : '';
    const { removeFormatting } = useNumericFormat({ ...numberFormatOptions });

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            let value = event.target.value;
            
            if (numberFormatOptions && removeFormatting) {
                value = removeFormatting(event.target.value);
            }

            onChange && onChange(value, event.target.name);
        },
        [numberFormatOptions, onChange, removeFormatting]
    );

    const handleKeyPress = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                onSubmit && onSubmit();
            }
        },
        [onSubmit]
    );

    const handleOnBlur = useCallback(() => {
        onBlur && onBlur();
    }, [onBlur]);

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(e => e.stopPropagation(), []);

    const inputProps: Partial<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & NumericFormatProps<InputAttributes>> = useMemo(
        () => ({
            className: `${highlighted ? 'highlighted' : ''} ${error ? 'error' : ''} ${readOnly && withFullBorder ? 'with-full-border' : ''}`,
            type: 'text',
            name,
            placeholder,
            value,
            disabled,
            readOnly,
            required,
            onChange: handleChange,
            onKeyDown: handleKeyDown,
            onKeyPress: handleKeyPress
        }),
        [disabled, error, handleChange, handleKeyDown, handleKeyPress, highlighted, name, placeholder, readOnly, required, value, withFullBorder]
    );

    return (
        <div className={`text-input ${dark ? 'dark' : ''}`} style={style}>
            <div className='text-input-field' onBlur={handleOnBlur}>
                {numberFormatOptions ? <NumericFormat {...inputProps} {...numberFormatOptions} /> : <input {...inputProps} />}
                <div className={`text-input-label ${placeholder || Boolean(value) ? 'filled' : ''}`}>{label}</div>
            </div>
            {getMessage && <div className={`text-input-message ${error ? 'error' : ''}`}>{message ? message : <br />}</div>}
        </div>
    );
};

export default TextInput;
