import React, { KeyboardEvent } from 'react';
import Icon, { IconType } from './Icon';

interface BoxTabSwitcherDropdownItemProps {
    icon: IconType;
    label: string;
    onClick: () => void;
    dark?: boolean;
}

const BoxTabSwitcherDropdownItem = ({ icon, label, onClick, dark }: BoxTabSwitcherDropdownItemProps) => {
    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();

        onClick();
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();

        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            onClick();
        }
    };

    return (
        <div className={`box-tab-switcher-dropdown-item ${dark ? 'dark' : ''}`} onClick={handleClick} onKeyDown={handleKeyDown}>
            <div className='box-tab-switcher-dropdown-item-icon'>
                <Icon type={icon} />
            </div>
            <div className='box-tab-switcher-dropdown-item-label'>{label}</div>
        </div>
    );
};

export default BoxTabSwitcherDropdownItem;
