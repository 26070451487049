import React, { Context, createContext, ReactNode, useContext } from 'react';

export interface HookConfiguration {
    [key: string]: { hook: (...parameters: never) => unknown, global?: undefined } | { hook: () => unknown, global: true };
}

type HookContext<T extends HookConfiguration> = {
    [Property in keyof T]: T[Property] extends { global: true } ? ReturnType<T[Property]['hook']> : T[Property]['hook'];
};

type Hooks<T extends HookConfiguration> = {
    configuration: T;
    context: Context<HookContext<T> | undefined>;
    useHook: <TSelected>(map: (context: HookContext<T>) => TSelected) => TSelected;
}

export interface HookProviderProps<T extends HookConfiguration> {
    children: ReactNode;
    hooks: Hooks<T>
}

export const HookProvider = <T extends HookConfiguration>({ children, hooks }: HookProviderProps<T>) => {
    const { configuration, context: Context } = hooks;

    return (
        <Context.Provider value={getValueFromConfiguration(configuration)}>
            {children}
        </Context.Provider>
    );
};

const getValueFromConfiguration = <T extends HookConfiguration>(configuration: T): HookContext<T> => {
    return Object.fromEntries(
        Object.entries(configuration)
            .map(([key, value]) => [key, value.global ? value.hook() : value.hook])
    ) as HookContext<T>;
};

export const configureHooks = <T extends HookConfiguration>(configuration: T): Hooks<T> => {
    const context = createContext<HookContext<T> | undefined>(undefined);

    const useHook = <TSelected, >(map: (context: HookContext<T>) => TSelected) => {
        if (!context) {
            throw new Error('No HookProvider found when calling useHook.');
        }

        return map(useContext(context as Context<HookContext<T>>));
    };

    return {
        configuration,
        context,
        useHook
    };
};
