import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TrackingService } from '../../services';
import { HeaderTab } from '../../enums';
import { setActiveTab } from '../../store';
import { TRANSLATIONS } from '../../constants';
import { useAppDispatch, useAppSelector, useTranslate } from '../../hooks/common';
import Card from '../common/Card';
import ContentContainer from '../common/ContentContainer';
import Flex from '../common/Flex';
import Grid from '../common/Grid';
import Headline from '../common/Headline';
import MarketingSection from './MarketingSection';
import compareImage from '../../../assets/images/compare.png';
import comparisonImage from '../../../assets/images/comparison.png';
import consolidationImage from '../../../assets/images/consolidation.png';
import compareImageDark from '../../../assets/images/compare_dark.png';
import comparisonImageDark from '../../../assets/images/comparison_dark.png';
import consolidationImageDark from '../../../assets/images/consolidation_dark.png';
import fanImage from '../../../assets/images/FAN.png';
import pumpImage from '../../../assets/images/PUMP.png';
import fanImageDark from '../../../assets/images/FAN_DARK.png';
import pumpImageDark from '../../../assets/images/PUMP_DARK.png';

const Home = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { translate } = useTranslate();
    const dark = useAppSelector(state => state.layout.darkMode);
    const language = useAppSelector(state => state.translation.language);

    useEffect(() => {
        TrackingService.TrackPage(window.location.pathname, language);
    }, []);
   
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setActiveTab(HeaderTab.Home));
    }, [dispatch]);

    return (
        <>
            <Flex direction={'column'} justification={'center'} style={{ paddingTop: 170 }}>
                <ContentContainer>
                    <Headline title='SinaSave' description={translate(TRANSLATIONS.main.sinaSaveDescription)} />
                </ContentContainer>
            </Flex>
            <ContentContainer>
                <Grid columns={2} columnGap={80} rowGap={80} columns1500={1} style={{ paddingTop: 300 }}>
                    <Card
                        description={translate(TRANSLATIONS.main.pumpSystemCardDescription)}
                        image={dark ? pumpImageDark : pumpImage}
                        buttonLabel={translate(TRANSLATIONS.main.startComparison)}
                        title={translate(TRANSLATIONS.generated['Home.PumpSystems'])}
                        onClick={() => navigate('/pump-systems/basic')}
                    />
                    <Card
                        description={translate(TRANSLATIONS.main.fanSystemCardDescription)}
                        image={dark ? fanImageDark : fanImage}
                        buttonLabel={translate(TRANSLATIONS.main.startComparison)}
                        title={translate(TRANSLATIONS.generated['Home.FanSystems'])}
                        onClick={() => navigate('/fan-systems/basic')}
                    />
                </Grid>
                <div style={{ paddingTop: 240, fontSize: 48, fontWeight: 900 }}>{translate(TRANSLATIONS.main.highlightsAndBenefits)}</div>
                <Grid columns={3} columnGap={40} columns1500={2} columns992={1} rowGap={40} style={{ paddingTop: 32 }}>
                    <Card description={translate(TRANSLATIONS.main.comparisonCard)} image={dark ? comparisonImageDark : comparisonImage} />
                    <Card description={translate(TRANSLATIONS.main.compareCard)} image={dark ? compareImageDark : compareImage} />
                    <Card description={translate(TRANSLATIONS.main.consolidationCard)} image={dark ? consolidationImageDark : consolidationImage} />
                </Grid>

            </ContentContainer>
            <MarketingSection />
        </>
    );
};

export default Home;
