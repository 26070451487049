import React, { KeyboardEvent } from 'react';

interface CheckboxProps {
    checked: boolean;
    onChange: (value: boolean) => void;
    dark?: boolean;
    disabled?: boolean;
    label?: string;
    selectHighlight?: boolean;
}

const Checkbox = ({ checked, dark, disabled, label, selectHighlight, onChange }: CheckboxProps) => {
    const handleChange = () => {
        if (!disabled) {
            onChange(!checked);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();

        if (event.key === ' ') {
            event.preventDefault();
            handleChange();
        }
    };

    return (
        <div className={`checkbox-container ${disabled ? 'disabled' : ''} ${dark ? 'dark' : ''}`} tabIndex={disabled ? undefined : 0} onClick={handleChange} onKeyDown={handleKeyDown}>
            <div className={`checkbox ${checked ? 'checked' : ''} ${selectHighlight ? 'select-highlight' : ''}`}>
                <div className='checkmark' />
            </div>
            {label &&
                <div className='checkbox-label'>
                    {label}
                </div>
            }
        </div>
    );
};

export default Checkbox;
