import { TRANSLATIONS } from '../constants';

export const isAtLeast = (value: string, min: number, allowEmpty?: boolean): boolean => {
    const number = Number(value);

    return (allowEmpty || value !== '') && number >= min;
};

export const isInRange = (value: string, min: number, max: number, allowEmpty?: boolean): boolean => {
    const number = Number(value);

    return (allowEmpty || value !== '') && number >= min && number <= max;
};

export const isNotNegative = (value: string): boolean => isAtLeast(value, 0);

export const isNotNegativeOrEmpty = (value: string): boolean => isAtLeast(value, 0, true);

export const isPercentage = (value: string): boolean => isInRange(value, 0, 100);

export const isPercentageMinOne = (value: string): boolean => isInRange(value, 1, 100);

export const isYear = (value: string): boolean => isInRange(value, 1, 99);

export const getMinValidationMessage = (translate: (translations: string[]) => string, min: string): string =>
    translate(TRANSLATIONS.generated['Errors.Min']).replace('{{MIN}}', min);

export const getRangeValidationMessage = (translate: (translations: string[]) => string, min: string, max: string): string =>
    translate(TRANSLATIONS.generated['Errors.Between']).replace('{{MIN}}', min).replace('{{MAX}}', max);

export const getNotNegativeValidationMessage = (translate: (translations: string[]) => string): string => getMinValidationMessage(translate, '0');

export const getPercentageValidationMessage = (translate: (translations: string[]) => string): string => getRangeValidationMessage(translate, '0', '100');

export const getPercentageMinOneValidationMessage = (translate: (translations: string[]) => string): string => getRangeValidationMessage(translate, '1', '100');

export const getYearValidationMessage = (translate: (translations: string[]) => string): string => getRangeValidationMessage(translate, '1', '99');
