import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../common';
import objectHash from 'object-hash';
import { setFanSystemStateChanged, setPumpSystemStateChanged } from '../../store';

export const useUnloadAlert = () => {
    const dispatch = useAppDispatch();
    const pumpSystemStateChanged = useAppSelector(state => state.pumpSystem.systemStateChanged);
    const pumpAlternativeSystem = useAppSelector(state => state.pumpSystem.alternativeSystem);
    const pumpAlternativeSystemInitialHash = useAppSelector(state => state.pumpSystem.alternativeSystemInitialHash);
    const pumpReferenceSystem = useAppSelector(state => state.pumpSystem.referenceSystem);
    const pumpReferenceSystemInitialHash = useAppSelector(state => state.pumpSystem.referenceSystemInitialHash);
    const pumpApplicationMode = useAppSelector(state => state.pumpSystem.applicationMode);

    const fanSystemStateChanged = useAppSelector(state => state.fanSystem.systemStateChanged);
    const fanAlternativeSystem = useAppSelector(state => state.fanSystem.alternativeSystem);
    const fanAlternativeSystemInitialHash = useAppSelector(state => state.fanSystem.alternativeSystemInitialHash);
    const fanReferenceSystem = useAppSelector(state => state.fanSystem.referenceSystem);
    const fanReferenceSystemInitialHash = useAppSelector(state => state.fanSystem.referenceSystemInitialHash);
    const fanApplicationMode = useAppSelector(state => state.fanSystem.applicationMode);

    useEffect(() => {
        const pumpAlternativeSystemHash = pumpAlternativeSystem !== null ? objectHash(pumpAlternativeSystem) : undefined;
        const pumpReferenceSystemHash = pumpReferenceSystem !== null ? objectHash(pumpReferenceSystem) : undefined;
        const hasPumpSystemStateChanged = pumpAlternativeSystemHash !== pumpAlternativeSystemInitialHash ||
            pumpReferenceSystemHash !== pumpReferenceSystemInitialHash ||
            pumpApplicationMode === 'Custom';
        dispatch(setPumpSystemStateChanged(hasPumpSystemStateChanged));
        
        const fanAlternativeSystemHash = fanAlternativeSystem !== null ? objectHash(fanAlternativeSystem) : undefined;
        const fanReferenceSystemHash = fanReferenceSystem !== null ? objectHash(fanReferenceSystem) : undefined;
        const hasFanSystemStateChanged = fanAlternativeSystemHash !== fanAlternativeSystemInitialHash ||
            fanReferenceSystemHash !== fanReferenceSystemInitialHash ||
            fanApplicationMode === 'Custom';
        dispatch(setFanSystemStateChanged(hasFanSystemStateChanged));
    }, [pumpAlternativeSystem,
        pumpAlternativeSystemInitialHash,
        pumpReferenceSystem,
        pumpReferenceSystemInitialHash,
        pumpApplicationMode,
        fanAlternativeSystem,
        fanAlternativeSystemInitialHash,
        fanReferenceSystem,
        fanReferenceSystemInitialHash,
        fanApplicationMode,
        dispatch]);

    useEffect(() => {
        if (pumpSystemStateChanged || fanSystemStateChanged) {
            window.onbeforeunload = () => '';
        }

        return () => {
            window.onbeforeunload = null;
        };
    }, [pumpSystemStateChanged, fanSystemStateChanged]);
};
