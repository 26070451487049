import React, { ReactNode, useEffect, useRef, useState } from 'react';

type Anchor = 'bottom' | 'left' | 'right' | 'top';

interface StickyProps {
    anchor: Anchor;
    children: ReactNode
    dark?: boolean;
    distance?: number;
    onStuckChange?: (stuck: boolean) => void;
}

const Sticky = ({ anchor, children, dark, distance, onStuckChange }: StickyProps) => {
    const [stuck, setStuck] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current) {
            const element = ref.current;

            const getMargin = (position: Anchor) => anchor === position ? -1 - (distance ?? 0) : 0;
            const rootMargin = `${getMargin('top')}px ${getMargin('right')}px ${getMargin('bottom')}px ${getMargin('left')}px`;

            const observer = new IntersectionObserver(([x]) => {
                setStuck(x.intersectionRatio < 1);
                onStuckChange && onStuckChange(x.intersectionRatio < 1);
            }, { threshold: [1], rootMargin });

            observer.observe(element);

            return () => observer.unobserve(element);
        }
    }, [anchor, distance, onStuckChange]);

    return (
        <div ref={ref} className={`sticky ${stuck ? 'stuck' : ''} ${dark ? 'dark' : ''}`} style={{ [anchor]: distance ?? 0 }}>
            {children}
        </div>
    );
};

export default Sticky;
