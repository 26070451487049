import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HeaderTab } from '../enums';

export interface LayoutState {
    activeTab?: HeaderTab;
    darkMode: boolean;
    megaMenuOpen: boolean;
}

const getInitialDarkMode = () => {
    const storedDarkMode = window.localStorage.getItem('darkMode');

    return Boolean(storedDarkMode && storedDarkMode === 'true');
};

const initialState: LayoutState = {
    darkMode: getInitialDarkMode(),
    megaMenuOpen: false
};

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setActiveTab: (state, action: PayloadAction<HeaderTab | undefined>) => {
            state.activeTab = action.payload;
        },
        setDarkMode: (state, action: PayloadAction<boolean>) => {
            state.darkMode = action.payload;


            window.localStorage.setItem('darkMode', action.payload.toString());
        },
        setMegaMenuOpen: (state, action: PayloadAction<boolean>) => {
            state.megaMenuOpen = action.payload;
        }
    }
});

export const { setActiveTab, setDarkMode, setMegaMenuOpen } = layoutSlice.actions;
export const layoutReducer = layoutSlice.reducer;
