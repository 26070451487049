import React from 'react';

export interface MenuDividerItemProps {
    dark?: boolean;
}

const MenuDividerItem = ({ dark }: MenuDividerItemProps) => (
    <div className={`menu-divider-item ${dark ? 'dark' : ''}`} />
);

export default MenuDividerItem;
