import React from 'react';
import { TRANSLATIONS } from '../../../../constants';
import { useAppSelector, useTranslate } from '../../../../hooks/common';
import Text from '../../../common/Text';
import { InputItemList } from '../../../../models';
import { GetImageDropdownItem } from '../../../../services/ImageDropdownItem';

export interface BasicViewMotorInfoProps {
    inputs: InputItemList;
}

export const BasicViewMotorInfo = ({ inputs }: BasicViewMotorInfoProps) => {
    const dark = useAppSelector(state => state.layout.darkMode);
    const { translate } = useTranslate();
    const imageDropdownItem = GetImageDropdownItem(inputs);
    const selectedValueTitle = imageDropdownItem.selectedValue.title;

    const simoticsInfo = (
        <>
            <ul>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.MotorTechnical.Content'][0])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.MotorTechnical.Content'][1])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.MotorTechnical.Content'][2])}
                    </Text>
                </li>
            </ul>
        </>
    );

    const simoticsVSDInfo = (
        <>
            <ul>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.MotorVSDSRMTechnical.Content'][0])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.MotorVSDSRMTechnical.Content'][1])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.MotorVSDSRMTechnical.Content'][2])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.MotorVSDSRMTechnical.Content'][3])}
                    </Text>
                </li>
            </ul>
        </>
    );

    const existingMotorInfo = (
        <>
            <ul>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.NemaThirdPartyMotorTechnical.Content'][0])}
                    </Text>
                </li>
            </ul>
        </>
    );

    const existingPoleChangingMotorInfo = (
        <>
            <ul>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.NemaExistingPoleChangingMotorTechnical.Content'][0])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.NemaExistingPoleChangingMotorTechnical.Content'][1])}
                    </Text>
                </li>
            </ul>
        </>
    );

    const renderInfo = (selectedValueTitle: string) => {
        switch (selectedValueTitle) {
            case 'Motor.Siemens':
            case 'System.Motor.SiemensStandard':
                return simoticsInfo;
            case 'System.Motor.SiemensInverterDuty':
                return simoticsVSDInfo;
            case 'Motor.YourMotor':
                return existingMotorInfo;
            case 'System.Motor.ExistingPoleChangingMotor':
                return existingPoleChangingMotorInfo;
            default:
                break;
        }
    };

    return (
        <>
            <Text dark={dark} type='subtitle' style={{ paddingBottom: 8 }}>
                {translate(TRANSLATIONS.generated['Help.MotorTechnical.Title'])}
            </Text>
            {renderInfo(selectedValueTitle)}
        </>
    );
};

export default BasicViewMotorInfo;
