import { useCallback } from 'react';
import { useAppSelector } from '../common';
import { useHook } from '..';
import { ApplicationType } from '../../types';
import { useCommercialViewUtilities } from './CommercialViewUtilities';

interface SystemActionsParameters {
    applicationType: ApplicationType
}

export const useSystemActions = ({ applicationType }: SystemActionsParameters) => {
    const { regionSettings } = useAppSelector(state => state.regionSettings);
    const { handleGetDefaults } = useHook(x => x.systemsLoad({ applicationType: applicationType }));
    const { clearGovernmentFunding: clearRefAdditionalInvestment, clearAdditionalInvestment: closeRefGovernmentFunding } = useCommercialViewUtilities({ applicationType, systemType: 'reference' });
    const { clearGovernmentFunding: clearAltAdditionalInvestment, clearAdditionalInvestment: closeAltGovernmentFunding } = useCommercialViewUtilities({ applicationType, systemType: 'alternative' });

    const handleResetSystem = useCallback(() => {
        clearAltAdditionalInvestment();
        closeAltGovernmentFunding();
        clearRefAdditionalInvestment();
        closeRefGovernmentFunding();
        handleGetDefaults(regionSettings?.countryCode ?? '--');
    }, [handleGetDefaults, regionSettings?.countryCode, clearAltAdditionalInvestment, closeAltGovernmentFunding, clearRefAdditionalInvestment, closeRefGovernmentFunding]);

    return { handleResetSystem };
};
