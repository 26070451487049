import React, { CSSProperties, KeyboardEvent, MouseEvent } from 'react';
import Icon, { IconType } from './Icon';
import StatusIcon, { StatusIconType } from './StatusIcon';

interface CommonIconButtonProps {
    onClick: () => void;
    className?: string;
    dark?: boolean;
    disabled?: boolean;
    layer?: 1 | 2 | 3;
    open?: boolean;
    size?: 16 | 20 | 24 | 40;
    style?: CSSProperties;
    secondary?: boolean;
    transparent?: boolean;
}
interface IconButtonPropsWithIcon extends CommonIconButtonProps {
    iconType: IconType;
}

interface IconButtonPropsWithStatusIcon extends CommonIconButtonProps {
    statusIconType: StatusIconType;
}

type IconButtonProps = IconButtonPropsWithIcon | IconButtonPropsWithStatusIcon;

const IconButton = ({ className, dark, disabled, layer, open, secondary, size, style, transparent, onClick, ...rest }: IconButtonProps) => {
    const handleClick = () => {
        if (!disabled) {
            onClick();
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();

        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleClick();
        }
    };

    const handleOnClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        handleClick();
    };

    return (
        <div
            className={`icon-button icon-button-${size ?? 24} icon-button-layer-${layer ?? 1} ${open ? 'open' : ''} ${secondary ? 'secondary' : ''} ${disabled ? 'disabled' : ''}`
                + ` ${transparent ? 'transparent' : ''} ${dark ? 'dark' : ''} ${className}`}
            tabIndex={disabled ? undefined : 0}
            onClick={handleOnClick}
            onKeyDown={handleKeyDown}
            style={style}
        >
            {'iconType' in rest
                ? <Icon type={rest.iconType} />
                : <StatusIcon type={rest.statusIconType} dark={dark} />
            }
        </div>
    );
};

export default IconButton;
