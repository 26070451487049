import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { COMMERCIAL_VIEW_INITIAL_STATE } from '../constants';
import { AdditionalInvestmentData, CommercialData, GovernmentFundingData } from '../models';

export const pumpSystemsCommercialViewSlice = createSlice({
    name: 'pumpSystemCommercialData',
    initialState: COMMERCIAL_VIEW_INITIAL_STATE,
    reducers: {
        setPumpAlternativeSystemCommercialData: (state, action: PayloadAction<Partial<CommercialData>>) => {
            state.alternativeSystemCommercialData = {
                ...state.alternativeSystemCommercialData,
                ...action.payload
            };
        },
        setPumpReferenceSystemCommercialData: (state, action: PayloadAction<Partial<CommercialData>>) => {
            state.referenceSystemCommercialData = {
                ...state.referenceSystemCommercialData,
                ...action.payload
            };
        },
        setPumpAlternativeSystemGovernmentFundingData: (state, action: PayloadAction<Partial<GovernmentFundingData>>) => {
            state.alternativeSystemCommercialData.governmentFunding = {
                ...state.alternativeSystemCommercialData.governmentFunding,
                ...action.payload
            };
        },
        setPumpReferenceSystemGovernmentFundingData: (state, action: PayloadAction<Partial<GovernmentFundingData>>) => {
            state.referenceSystemCommercialData.governmentFunding = {
                ...state.referenceSystemCommercialData.governmentFunding,
                ...action.payload
            };
        },
        setPumpAlternativeSystemAdditionalInvestmentData: (state, action: PayloadAction<Partial<AdditionalInvestmentData>>) => {
            state.alternativeSystemCommercialData.additionalInvestments = {
                ...state.alternativeSystemCommercialData.additionalInvestments,
                ...action.payload
            };
        },
        setPumpReferenceSystemAdditionalInvestmentData: (state, action: PayloadAction<Partial<AdditionalInvestmentData>>) => {
            state.referenceSystemCommercialData.additionalInvestments = {
                ...state.referenceSystemCommercialData.additionalInvestments,
                ...action.payload
            };
        }
    }
});

export const {
    setPumpAlternativeSystemAdditionalInvestmentData,
    setPumpAlternativeSystemCommercialData,
    setPumpAlternativeSystemGovernmentFundingData,
    setPumpReferenceSystemAdditionalInvestmentData,
    setPumpReferenceSystemCommercialData,
    setPumpReferenceSystemGovernmentFundingData
} = pumpSystemsCommercialViewSlice.actions;
export const pumpSystemsCommercialDataReducer = pumpSystemsCommercialViewSlice.reducer;
