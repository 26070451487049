import { useCallback, useMemo } from 'react';
import { TRANSLATIONS } from '../../constants';
import { useAppSelector, useTranslate } from '../common';

export const useCurrency = () => {
    const { dynamicTranslate } = useTranslate();
    const { regionSettings, numberFormatOptions, isInitialized: regionSettingsInitialized } = useAppSelector(x => x.regionSettings);
    const currencySymbol = useMemo(() => 
        regionSettingsInitialized ? regionSettings?.currency?.code === 'PLN' ? 'zł' : dynamicTranslate(TRANSLATIONS.generated, regionSettings?.currency?.label) : undefined
    , [dynamicTranslate, regionSettings?.currency, regionSettingsInitialized]);
  
    const isPrefix = useCallback(() => {
        switch (regionSettings?.currency?.code) {
            case 'HUF':
                return false;
            case 'PLN':
                return false;
            default:
                return true;
        }
    }, [regionSettings?.currency?.code]);

    const currencyFormat = useMemo(() => {
        return { ...numberFormatOptions, fixedDecimalScale: false, prefix: isPrefix() ? `${currencySymbol} ` : '', suffix: isPrefix() ? '' : ` ${currencySymbol}` };
    }, [isPrefix, currencySymbol, numberFormatOptions]);

    return {
        currencySymbol,
        currencyFormat
    };
};
