import React, { CSSProperties } from 'react';

interface ChartItemMarkerProps {
    type: 'circle' | 'diamond' | 'square' | 'triangle';
    size: number;
    color: string;
}

const ChartItemMarker = ({ type, size, color }: ChartItemMarkerProps) => {
    const circleStyle: CSSProperties = {
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: color,
        borderRadius: '50%'
    };

    const diamondStyle: CSSProperties = {
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: color,
        transform: 'rotate(45deg)'
    };

    const squareStyle: CSSProperties = {
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: color,
        paddingBottom: 4
    };

    const triangleStyle: CSSProperties = {
        borderLeft: `${size / 2}px solid transparent`,
        borderRight: `${size / 2}px solid transparent`,
        borderBottom: `${size}px solid ${color}`
    };

    const getMarkerStyle = () => {
        switch (type) {
            case 'circle':
                return circleStyle;
            case 'diamond':
                return diamondStyle;
            case 'square':
                return squareStyle;
            case 'triangle':
                return triangleStyle;
            default:
                break;
        }
    };

    return <div style={getMarkerStyle()} />;
    };

    export default ChartItemMarker;
