import { ApiBase, ChartMockApi } from '.';
import { GEOLOCATION_API_URL } from '../constants';
import {
    ServiceDataResult, SystemsResponse, SystemsRequest, PumpResponse, SystemRequest, RegionSettings, ChartData, ChartRequest, FanRequest, FanResponse, SystemResponse,
    DocumentExportResult, DocumentDownloadResult, DocumentExportRequest, PumpRequest, GeolocationResult
} from '../models';

const baseUrl = process.env.REACT_APP_API_BASE_URL ?? '';

export class Api extends ApiBase {
    public static getChartData = (data: ChartRequest) => ChartMockApi.getChartData(data) as Promise<ServiceDataResult<ChartData>>;
    public static getDefaultPump = (countryCode: string) => Api.get(`${baseUrl}/api/pump/default/?countryCode=${countryCode}`) as Promise<ServiceDataResult<PumpResponse>>;
    public static getPump = (pumpRequest: PumpRequest) => Api.post(`${baseUrl}/api/pump/`, pumpRequest) as Promise<ServiceDataResult<PumpResponse>>;
    public static getFan = (fanRequest: FanRequest) => Api.post(`${baseUrl}/api/fan/`, fanRequest) as Promise<ServiceDataResult<FanResponse>>;
    public static getDefaultFan = (countryCode: string) => Api.get(`${baseUrl}/api/fan/default/?countryCode=${countryCode}`) as Promise<ServiceDataResult<FanResponse>>;
    public static getRegionSettings = (countryCode: string) => Api.get(`${baseUrl}/api/regionsettings/?countryCode=${countryCode}`) as Promise<ServiceDataResult<RegionSettings>>;
    public static getSystems = (request: SystemsRequest) => Api.post(`${baseUrl}/api/systemcalculation/systems`, request) as Promise<ServiceDataResult<SystemsResponse>>;
    public static getSystem = (request: SystemRequest) => Api.post(`${baseUrl}/api/systemcalculation/system`, request) as Promise<ServiceDataResult<SystemResponse>>;
    public static getGeolocation = () => Api.get(GEOLOCATION_API_URL) as Promise<GeolocationResult>;
    public static exportDocument = (documentExportRequest: DocumentExportRequest) => Api.post(`${baseUrl}/api/export/exportdocument`, documentExportRequest) as Promise<ServiceDataResult<DocumentExportResult>>;
    public static downloadDocument = (downloadDocumentRequest: DocumentExportResult) => Api.post(`${baseUrl}/api/export/downloaddocument`, downloadDocumentRequest) as Promise<ServiceDataResult<DocumentDownloadResult>>;
}
