import React, { CSSProperties } from 'react';
import { ReactComponent as Bypass } from '../../../assets/images/sinasave-images/Bypass.svg';
import { ReactComponent as BypassDescription } from '../../../assets/images/sinasave-images/Bypass_Full.svg';
import { ReactComponent as Converter } from '../../../assets/images/sinasave-images/Converter.svg';
import { ReactComponent as ConverterDescription } from '../../../assets/images/sinasave-images/Converter_Full.svg';
import { ReactComponent as ExistingMotor } from '../../../assets/images/sinasave-images/Existing_Motor_Dropdown.svg';
import { ReactComponent as ExistingPoleChangingMotor } from '../../../assets/images/sinasave-images/Existing_Pole_Changing_Motor_Dropdown.svg';
import { ReactComponent as ExistingPoleChangingSwitchgear } from '../../../assets/images/sinasave-images/Existing_Pole_Changing_Switchgear.svg';
import { ReactComponent as Simotics } from '../../../assets/images/sinasave-images/Motor_Dropdown.svg';
import { ReactComponent as Sinamics } from '../../../assets/images/sinasave-images/Sinamics_Dropdown.svg';
import { ReactComponent as Sirius } from '../../../assets/images/sinasave-images/Sirius_Dropdown.svg';
import { ReactComponent as ThirdPartyConverter } from '../../../assets/images/sinasave-images/Third_Party_Converter_Dropdown.svg';
import { ReactComponent as ThirdPartySwitchgear } from '../../../assets/images/sinasave-images/Third_Party_Switchgear.svg';
import { ReactComponent as Throttle } from '../../../assets/images/sinasave-images/Throttle.svg';
import { ReactComponent as ThrottleDescription } from '../../../assets/images/sinasave-images/Throttle_Full.svg';
import { ReactComponent as Pump } from '../../../assets/images/PUMP.svg';
import { ReactComponent as Fan } from '../../../assets/images/FAN.svg';

export type imageNames =
    'Bypass'
    | 'BypassDescription'
    | 'Converter'
    | 'ConverterDescription'
    | 'ExistingMotor'
    | 'ExistingPoleChangingMotor'
    | 'ExistingPoleChangingSwitchgear'
    | 'Pump'
    | 'Fan'
    | 'Simotics'
    | 'Sinamics'
    | 'Sirius'
    | 'ThirdPartyConverter'
    | 'ThirdPartySwitchgear'
    | 'ThrottleDescription'
    | 'Throttle';

interface SinaSaveImageProps {
    imageName?: imageNames;
    dark?: boolean;
    style?: CSSProperties;
}

const SinaSaveImage = ({ imageName, dark, style }: SinaSaveImageProps) => {
    const sinaSaveImageClass = `sina-save-image ${dark ? 'dark' : ''}`;

    switch (imageName) {
        case 'Bypass':
            return <Bypass className={sinaSaveImageClass} style={style} />;
        case 'BypassDescription':
            return <BypassDescription className={sinaSaveImageClass} style={style} />;
        case 'ConverterDescription':
            return <ConverterDescription className={sinaSaveImageClass} style={style} />;
        case 'Converter':
            return <Converter className={sinaSaveImageClass} style={style} />;
        case 'ExistingMotor':
            return <ExistingMotor className={sinaSaveImageClass} style={style} />;
        case 'ExistingPoleChangingMotor':
            return <ExistingPoleChangingMotor className={sinaSaveImageClass} style={style} />;
        case 'Pump':
            return <Pump className={sinaSaveImageClass} style={style} />;
        case 'Fan':
            return <Fan className={sinaSaveImageClass} style={style} />;
        case 'Simotics':
            return <Simotics className={sinaSaveImageClass} style={style} />;
        case 'Sinamics':
            return <Sinamics className={sinaSaveImageClass} style={style} />;
        case 'Sirius':
            return <Sirius className={sinaSaveImageClass} style={style} />;
        case 'ExistingPoleChangingSwitchgear':
            return <div style={{ paddingLeft: 3, paddingRight: 3 }}>
                <ExistingPoleChangingSwitchgear className={sinaSaveImageClass} style={style} />
            </div>;
        case 'ThirdPartyConverter':
            return <ThirdPartyConverter className={sinaSaveImageClass} style={style} />;
        case 'ThirdPartySwitchgear':
            return <div style={{ paddingLeft: 2, paddingRight: 2 }}>
                <ThirdPartySwitchgear className={sinaSaveImageClass} style={style} />
            </div>;
        case 'ThrottleDescription':
            return <ThrottleDescription className={sinaSaveImageClass} style={style} />;
        case 'Throttle':
            return <Throttle className={sinaSaveImageClass} style={style} />;
        default:
            return <></>;
    }
};

export default SinaSaveImage;
