import React from 'react';

interface HeadlineProps {
    title: string | number | JSX.Element;
    description?: string | number | JSX.Element;
    label?: string;
}

const Headline = ({ description, label, title }: HeadlineProps) => (
    <div className='headline'>
        <span className='headline-text'>
            {label &&
                <div className='headline-label'>
                    {label}
                </div>
            }
            <div className='headline-title'>
                {title}
            </div>
            {description &&
                <div className='headline-description'>
                    {description}
                </div>
            }
        </span>
    </div>
);

export default Headline;
