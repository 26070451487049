import React, { KeyboardEvent, useContext } from 'react';
import { MenuContext } from './Menu';

export interface MenuItemProps {
    label: string;
    onClick: () => void;
    dark?: boolean;
    disabled?: boolean;
    keepOpen?: boolean;
}

const MenuItem = ({ dark, disabled, keepOpen, label, onClick }: MenuItemProps) => {
    const { onMenuClose } = useContext(MenuContext);

    const handleSelect = () => {
        if (!disabled) {
            !keepOpen && onMenuClose && onMenuClose();
            onClick();
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();

        handleSelect();
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();

        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleSelect();
        }
    };

    return (
        <div className={`menu-item ${disabled ? 'disabled' : ''} ${dark ? 'dark' : ''}`} tabIndex={disabled ? undefined : 0} onClick={handleClick} onKeyDown={handleKeyDown}>
            {label}
        </div>
    );
};

export default MenuItem;
