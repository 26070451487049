import React, { CSSProperties, ReactNode } from 'react';

interface AppearanceAnimationWrapperProps {
    children: ReactNode;
    style?: CSSProperties;
}

const AppearanceAnimationWrapper = ({ children, style }: AppearanceAnimationWrapperProps) => (
    <div className='appearance-animation-wrapper' style={style}>
        {children}
    </div>
);

export default AppearanceAnimationWrapper;
