import React, { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { hooks } from './ts/hooks';
import { HookProvider } from './ts/providers';
import { store } from './ts/store/store';
import routes from './routes';
import 'rc-slider/assets/index.css';
import './css/styles.css';

const root = createRoot(document.getElementById('root')!);

root.render(
    <StrictMode>
        <Provider store={store}>
            <HookProvider hooks={hooks}>
                <BrowserRouter>
                    {routes}
                </BrowserRouter>
            </HookProvider>
        </Provider>
    </StrictMode>
);
