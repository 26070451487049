import React from 'react';
import { ReactComponent as AcknowledgeEvents } from '../../../assets/images/icons/Acknowledge-events.svg';
import { ReactComponent as AddWorkorder } from '../../../assets/images/icons/Add-workorder.svg';
import { ReactComponent as Apps } from '../../../assets/images/icons/Apps.svg';
import { ReactComponent as ArrowDoubleLeft } from '../../../assets/images/icons/Arrow-double-left.svg';
import { ReactComponent as ArrowDoubleRight } from '../../../assets/images/icons/Arrow-double-right.svg';
import { ReactComponent as ArrowDown } from '../../../assets/images/icons/Arrow-down.svg';
import { ReactComponent as ArrowheadDown } from '../../../assets/images/icons/Arrowhead-down.svg';
import { ReactComponent as ArrowheadLeft } from '../../../assets/images/icons/Arrowhead-left.svg';
import { ReactComponent as ArrowheadRight } from '../../../assets/images/icons/Arrowhead-right.svg';
import { ReactComponent as ArrowheadUp } from '../../../assets/images/icons/Arrowhead-up.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/images/icons/Arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../assets/images/icons/Arrow-right.svg';
import { ReactComponent as ArrowUp } from '../../../assets/images/icons/Arrow-up.svg';
import { ReactComponent as Attach } from '../../../assets/images/icons/Attach.svg';
import { ReactComponent as Back } from '../../../assets/images/icons/Back.svg';
import { ReactComponent as BarChart } from '../../../assets/images/icons/Bar-chart.svg';
import { ReactComponent as Bookmark } from '../../../assets/images/icons/Bookmark.svg';
import { ReactComponent as BrowseAll } from '../../../assets/images/icons/Browse-all.svg';
import { ReactComponent as Buildings } from '../../../assets/images/icons/Buildings.svg';
import { ReactComponent as Calendar } from '../../../assets/images/icons/Calendar.svg';
import { ReactComponent as Chat } from '../../../assets/images/icons/Chat.svg';
import { ReactComponent as Check } from '../../../assets/images/icons/Check.svg';
import { ReactComponent as CheckSmall } from '../../../assets/images/icons/Check-small.svg';
import { ReactComponent as Close } from '../../../assets/images/icons/Close.svg';
import { ReactComponent as CloseBig } from '../../../assets/images/icons/Close-big.svg';
import { ReactComponent as CloseSmall } from '../../../assets/images/icons/Close-small.svg';
import { ReactComponent as Configuration } from '../../../assets/images/icons/Configuration.svg';
import { ReactComponent as Configure } from '../../../assets/images/icons/Configure.svg';
import { ReactComponent as Copy } from '../../../assets/images/icons/Copy.svg';
import { ReactComponent as Cut } from '../../../assets/images/icons/Cut.svg';
import { ReactComponent as Delivery } from '../../../assets/images/icons/Delivery.svg';
import { ReactComponent as Document } from '../../../assets/images/icons/Document.svg';
import { ReactComponent as DocumentDOC } from '../../../assets/images/icons/Document-DOC.svg';
import { ReactComponent as DocumentMP } from '../../../assets/images/icons/Document-MP.svg';
import { ReactComponent as DocumentPDF } from '../../../assets/images/icons/Document-PDF.svg';
import { ReactComponent as DocumentPPT } from '../../../assets/images/icons/Document-PPT.svg';
import { ReactComponent as DocumentSuccess } from '../../../assets/images/icons/Document-success.svg';
import { ReactComponent as DocumentTXT } from '../../../assets/images/icons/Document-TXT.svg';
import { ReactComponent as DocumentXLS } from '../../../assets/images/icons/Document-XLS.svg';
import { ReactComponent as Download } from '../../../assets/images/icons/Download.svg';
import { ReactComponent as DragDrop } from '../../../assets/images/icons/Drag-drop.svg';
import { ReactComponent as DropDown } from '../../../assets/images/icons/Drop-down.svg';
import { ReactComponent as Edit } from '../../../assets/images/icons/Edit.svg';
import { ReactComponent as Email } from '../../../assets/images/icons/Email.svg';
import { ReactComponent as Error } from '../../../assets/images/icons/Error.svg';
import { ReactComponent as ErrorFilled } from '../../../assets/images/icons/Error-filled.svg';
import { ReactComponent as Explore } from '../../../assets/images/icons/Explore.svg';
import { ReactComponent as Facebook } from '../../../assets/images/icons/Facebook.svg';
import { ReactComponent as Favorite } from '../../../assets/images/icons/Favorite.svg';
import { ReactComponent as FavoriteMarked } from '../../../assets/images/icons/Favorite_marked.svg';
import { ReactComponent as Filter } from '../../../assets/images/icons/Filter.svg';
import { ReactComponent as Folder } from '../../../assets/images/icons/Folder.svg';
import { ReactComponent as FolderNew } from '../../../assets/images/icons/Folder-new.svg';
import { ReactComponent as FolderOpen } from '../../../assets/images/icons/Folder-open.svg';
import { ReactComponent as FullScreen } from '../../../assets/images/icons/Full-screen.svg';
import { ReactComponent as FullScreenExit } from '../../../assets/images/icons/Full-screen-exit.svg';
import { ReactComponent as GroupPeople } from '../../../assets/images/icons/Group-people.svg';
import { ReactComponent as Hide } from '../../../assets/images/icons/Hide.svg';
import { ReactComponent as History } from '../../../assets/images/icons/History.svg';
import { ReactComponent as Home } from '../../../assets/images/icons/Home.svg';
import { ReactComponent as HomeSmall } from '../../../assets/images/icons/Home-small.svg';
import { ReactComponent as Hourglass } from '../../../assets/images/icons/Hourglass.svg';
import { ReactComponent as Image } from '../../../assets/images/icons/Image.svg';
import { ReactComponent as Information } from '../../../assets/images/icons/Information.svg';
import { ReactComponent as InformationFilled } from '../../../assets/images/icons/Information-filled.svg';
import { ReactComponent as Instagram } from '../../../assets/images/icons/Instagram.svg';
import { ReactComponent as InteractionClick } from '../../../assets/images/icons/Interaction-click.svg';
import { ReactComponent as InteractionTouch } from '../../../assets/images/icons/Interaction-touch.svg';
import { ReactComponent as Language } from '../../../assets/images/icons/Language.svg';
import { ReactComponent as LineChart } from '../../../assets/images/icons/Line-chart.svg';
import { ReactComponent as Link } from '../../../assets/images/icons/Link.svg';
import { ReactComponent as Linkedin } from '../../../assets/images/icons/Linkedin.svg';
import { ReactComponent as LinkExtern } from '../../../assets/images/icons/Link-extern.svg';
import { ReactComponent as Location } from '../../../assets/images/icons/Location.svg';
import { ReactComponent as Locked } from '../../../assets/images/icons/Locked.svg';
import { ReactComponent as Login } from '../../../assets/images/icons/Login.svg';
import { ReactComponent as Logout } from '../../../assets/images/icons/Logout.svg';
import { ReactComponent as Maximize } from '../../../assets/images/icons/Maximize.svg';
import { ReactComponent as Menu } from '../../../assets/images/icons/Menu.svg';
import { ReactComponent as Microphone } from '../../../assets/images/icons/Microphone.svg';
import { ReactComponent as Minimize } from '../../../assets/images/icons/Minimize.svg';
import { ReactComponent as Minus } from '../../../assets/images/icons/Minus.svg';
import { ReactComponent as Monitor } from '../../../assets/images/icons/Monitor.svg';
import { ReactComponent as Moon } from '../../../assets/images/icons/Moon.svg';
import { ReactComponent as NavigationLeft } from '../../../assets/images/icons/Navigation-left.svg';
import { ReactComponent as NavigationRight } from '../../../assets/images/icons/Navigation-right.svg';
import { ReactComponent as Notification } from '../../../assets/images/icons/Notification.svg';
import { ReactComponent as Optimize } from '../../../assets/images/icons/Optimize.svg';
import { ReactComponent as Options } from '../../../assets/images/icons/Options.svg';
import { ReactComponent as OptionsHorizontal } from '../../../assets/images/icons/Options-horizontal.svg';
import { ReactComponent as Paste } from '../../../assets/images/icons/Paste.svg';
import { ReactComponent as Pause } from '../../../assets/images/icons/Pause.svg';
import { ReactComponent as Phone } from '../../../assets/images/icons/Phone.svg';
import { ReactComponent as Pin } from '../../../assets/images/icons/Pin.svg';
import { ReactComponent as Pinned } from '../../../assets/images/icons/Pinned.svg';
import { ReactComponent as Plant } from '../../../assets/images/icons/Plant.svg';
import { ReactComponent as Play } from '../../../assets/images/icons/Play.svg';
import { ReactComponent as Plus } from '../../../assets/images/icons/Plus.svg';
import { ReactComponent as PlusSmall } from '../../../assets/images/icons/Plus-small.svg';
import { ReactComponent as Print } from '../../../assets/images/icons/Print.svg';
import { ReactComponent as QRCode } from '../../../assets/images/icons/QR-code.svg';
import { ReactComponent as Question } from '../../../assets/images/icons/Question.svg';
import { ReactComponent as ReadingTime } from '../../../assets/images/icons/Reading-time.svg';
import { ReactComponent as Redo } from '../../../assets/images/icons/Redo.svg';
import { ReactComponent as Refresh } from '../../../assets/images/icons/Refresh.svg';
import { ReactComponent as Reset } from '../../../assets/images/icons/Reset.svg';
import { ReactComponent as Resize } from '../../../assets/images/icons/Resize.svg';
import { ReactComponent as Save } from '../../../assets/images/icons/Save.svg';
import { ReactComponent as Search } from '../../../assets/images/icons/Search.svg';
import { ReactComponent as SearchSmall } from '../../../assets/images/icons/Search-small.svg';
import { ReactComponent as Settings } from '../../../assets/images/icons/Settings.svg';
import { ReactComponent as Share } from '../../../assets/images/icons/Share.svg';
import { ReactComponent as ShoppingCart } from '../../../assets/images/icons/Shopping-cart.svg';
import { ReactComponent as Show } from '../../../assets/images/icons/Show.svg';
import { ReactComponent as Skip } from '../../../assets/images/icons/Skip.svg';
import { ReactComponent as SkipBack } from '../../../assets/images/icons/Skip-back.svg';
import { ReactComponent as SocialFeed } from '../../../assets/images/icons/Social-feed.svg';
import { ReactComponent as Sort } from '../../../assets/images/icons/Sort.svg';
import { ReactComponent as SortAscending } from '../../../assets/images/icons/Sort-ascending.svg';
import { ReactComponent as SortDescending } from '../../../assets/images/icons/Sort-descending.svg';
import { ReactComponent as SortDown } from '../../../assets/images/icons/Sort-down.svg';
import { ReactComponent as SortUp } from '../../../assets/images/icons/Sort-up.svg';
import { ReactComponent as SortUpDown } from '../../../assets/images/icons/Sort-up-down.svg';
import { ReactComponent as SourceCode } from '../../../assets/images/icons/Source-code.svg';
import { ReactComponent as Stop } from '../../../assets/images/icons/Stop.svg';
import { ReactComponent as Success } from '../../../assets/images/icons/Success.svg';
import { ReactComponent as SuccessFilled } from '../../../assets/images/icons/Success-filled.svg';
import { ReactComponent as Support } from '../../../assets/images/icons/Support.svg';
import { ReactComponent as Trash } from '../../../assets/images/icons/Trash.svg';
import { ReactComponent as Twitter } from '../../../assets/images/icons/Twitter.svg';
import { ReactComponent as Undo } from '../../../assets/images/icons/Undo.svg';
import { ReactComponent as Unlocked } from '../../../assets/images/icons/Unlocked.svg';
import { ReactComponent as Upload } from '../../../assets/images/icons/Upload.svg';
import { ReactComponent as User } from '../../../assets/images/icons/User.svg';
import { ReactComponent as VolumeLoud } from '../../../assets/images/icons/Volume-loud.svg';
import { ReactComponent as VolumeMute } from '../../../assets/images/icons/Volume-mute.svg';
import { ReactComponent as VolumeQuiet } from '../../../assets/images/icons/Volume-quiet.svg';
import { ReactComponent as Warning } from '../../../assets/images/icons/Warning.svg';
import { ReactComponent as WarningFilled } from '../../../assets/images/icons/Warning-filled.svg';
import { ReactComponent as Weight } from '../../../assets/images/icons/Weight.svg';
import { ReactComponent as Yammer } from '../../../assets/images/icons/Yammer.svg';
import { ReactComponent as YouTube } from '../../../assets/images/icons/YouTube.svg';
import { ReactComponent as ZoomIn } from '../../../assets/images/icons/Zoom-in.svg';
import { ReactComponent as ZoomOut } from '../../../assets/images/icons/Zoom-out.svg';

export enum IconType {
    AcknowledgeEvents,
    AddWorkorder,
    Apps,
    ArrowDoubleLeft,
    ArrowDoubleRight,
    ArrowDown,
    ArrowheadDown,
    ArrowheadLeft,
    ArrowheadRight,
    ArrowheadUp,
    ArrowLeft,
    ArrowRight,
    ArrowUp,
    Attach,
    Back,
    BarChart,
    Bookmark,
    BrowseAll,
    Buildings,
    Calendar,
    Chat,
    Check,
    CheckSmall,
    Close,
    CloseBig,
    CloseSmall,
    Configuration,
    Configure,
    Copy,
    Cut,
    Delivery,
    Document,
    DocumentDOC,
    DocumentMP,
    DocumentPDF,
    DocumentPPT,
    DocumentSuccess,
    DocumentTXT,
    DocumentXLS,
    Download,
    DragDrop,
    DropDown,
    Edit,
    Email,
    Error,
    ErrorFilled,
    Explore,
    Facebook,
    Favorite,
    FavoriteMarked,
    Filter,
    Folder,
    FolderNew,
    FolderOpen,
    FullScreen,
    FullScreenExit,
    GroupPeople,
    Hide,
    History,
    Home,
    HomeSmall,
    Hourglass,
    Image,
    Information,
    InformationFilled,
    Instagram,
    InteractionClick,
    InteractionTouch,
    Language,
    LineChart,
    Link,
    Linkedin,
    LinkExtern,
    Location,
    Locked,
    Login,
    Logout,
    Maximize,
    Menu,
    Microphone,
    Minimize,
    Minus,
    Monitor,
    Moon,
    NavigationLeft,
    NavigationRight,
    Notification,
    Optimize,
    Options,
    OptionsHorizontal,
    Paste,
    Pause,
    Phone,
    Pin,
    Pinned,
    Plant,
    Play,
    Plus,
    PlusSmall,
    Print,
    QRCode,
    Question,
    ReadingTime,
    Redo,
    Refresh,
    Reset,
    Resize,
    Save,
    Search,
    SearchSmall,
    Settings,
    Share,
    ShoppingCart,
    Show,
    Skip,
    SkipBack,
    SocialFeed,
    Sort,
    SortAscending,
    SortDescending,
    SortDown,
    SortUp,
    SortUpDown,
    SourceCode,
    Stop,
    Success,
    SuccessFilled,
    Support,
    Trash,
    Twitter,
    Undo,
    Unlocked,
    Upload,
    User,
    VolumeLoud,
    VolumeMute,
    VolumeQuiet,
    Warning,
    WarningFilled,
    Weight,
    Yammer,
    YouTube,
    ZoomIn,
    ZoomOut
}

interface IconProps {
    type: IconType;
}

const Icon = ({ type }: IconProps) => {
    switch (type) {
        case IconType.AcknowledgeEvents:
            return <AcknowledgeEvents />;
        case IconType.AddWorkorder:
            return <AddWorkorder />;
        case IconType.Apps:
            return <Apps />;
        case IconType.ArrowDoubleLeft:
            return <ArrowDoubleLeft />;
        case IconType.ArrowDoubleRight:
            return <ArrowDoubleRight />;
        case IconType.ArrowDown:
            return <ArrowDown />;
        case IconType.ArrowheadDown:
            return <ArrowheadDown />;
        case IconType.ArrowheadLeft:
            return <ArrowheadLeft />;
        case IconType.ArrowheadRight:
            return <ArrowheadRight />;
        case IconType.ArrowheadUp:
            return <ArrowheadUp />;
        case IconType.ArrowLeft:
            return <ArrowLeft />;
        case IconType.ArrowRight:
            return <ArrowRight />;
        case IconType.ArrowUp:
            return <ArrowUp />;
        case IconType.Attach:
            return <Attach />;
        case IconType.Back:
            return <Back />;
        case IconType.BarChart:
            return <BarChart />;
        case IconType.Bookmark:
            return <Bookmark />;
        case IconType.BrowseAll:
            return <BrowseAll />;
        case IconType.Buildings:
            return <Buildings />;
        case IconType.Calendar:
            return <Calendar />;
        case IconType.Chat:
            return <Chat />;
        case IconType.Check:
            return <Check />;
        case IconType.CheckSmall:
            return <CheckSmall />;
        case IconType.Close:
            return <Close />;
        case IconType.CloseBig:
            return <CloseBig />;
        case IconType.CloseSmall:
            return <CloseSmall />;
        case IconType.Configuration:
            return <Configuration />;
        case IconType.Configure:
            return <Configure />;
        case IconType.Copy:
            return <Copy />;
        case IconType.Cut:
            return <Cut />;
        case IconType.Delivery:
            return <Delivery />;
        case IconType.Document:
            return <Document />;
        case IconType.DocumentDOC:
            return <DocumentDOC />;
        case IconType.DocumentMP:
            return <DocumentMP />;
        case IconType.DocumentPDF:
            return <DocumentPDF />;
        case IconType.DocumentPPT:
            return <DocumentPPT />;
        case IconType.DocumentSuccess:
            return <DocumentSuccess />;
        case IconType.DocumentTXT:
            return <DocumentTXT />;
        case IconType.DocumentXLS:
            return <DocumentXLS />;
        case IconType.Download:
            return <Download />;
        case IconType.DragDrop:
            return <DragDrop />;
        case IconType.DropDown:
            return <DropDown />;
        case IconType.Edit:
            return <Edit />;
        case IconType.Email:
            return <Email />;
        case IconType.Error:
            return <Error />;
        case IconType.ErrorFilled:
            return <ErrorFilled />;
        case IconType.Explore:
            return <Explore />;
        case IconType.Facebook:
            return <Facebook />;
        case IconType.Favorite:
            return <Favorite />;
        case IconType.FavoriteMarked:
            return <FavoriteMarked />;
        case IconType.Filter:
            return <Filter />;
        case IconType.Folder:
            return <Folder />;
        case IconType.FolderNew:
            return <FolderNew />;
        case IconType.FolderOpen:
            return <FolderOpen />;
        case IconType.FullScreen:
            return <FullScreen />;
        case IconType.FullScreenExit:
            return <FullScreenExit />;
        case IconType.GroupPeople:
            return <GroupPeople />;
        case IconType.Hide:
            return <Hide />;
        case IconType.History:
            return <History />;
        case IconType.Home:
            return <Home />;
        case IconType.HomeSmall:
            return <HomeSmall />;
        case IconType.Hourglass:
            return <Hourglass />;
        case IconType.Image:
            return <Image />;
        case IconType.Information:
            return <Information />;
        case IconType.InformationFilled:
            return <InformationFilled />;
        case IconType.Instagram:
            return <Instagram />;
        case IconType.InteractionClick:
            return <InteractionClick />;
        case IconType.InteractionTouch:
            return <InteractionTouch />;
        case IconType.Language:
            return <Language />;
        case IconType.LineChart:
            return <LineChart />;
        case IconType.Link:
            return <Link />;
        case IconType.Linkedin:
            return <Linkedin />;
        case IconType.LinkExtern:
            return <LinkExtern />;
        case IconType.Location:
            return <Location />;
        case IconType.Locked:
            return <Locked />;
        case IconType.Login:
            return <Login />;
        case IconType.Logout:
            return <Logout />;
        case IconType.Maximize:
            return <Maximize />;
        case IconType.Menu:
            return <Menu />;
        case IconType.Microphone:
            return <Microphone />;
        case IconType.Minimize:
            return <Minimize />;
        case IconType.Minus:
            return <Minus />;
        case IconType.Monitor:
            return <Monitor />;
        case IconType.Moon:
            return <Moon />;
        case IconType.NavigationLeft:
            return <NavigationLeft />;
        case IconType.NavigationRight:
            return <NavigationRight />;
        case IconType.Notification:
            return <Notification />;
        case IconType.Optimize:
            return <Optimize />;
        case IconType.Options:
            return <Options />;
        case IconType.OptionsHorizontal:
            return <OptionsHorizontal />;
        case IconType.Paste:
            return <Paste />;
        case IconType.Pause:
            return <Pause />;
        case IconType.Phone:
            return <Phone />;
        case IconType.Pin:
            return <Pin />;
        case IconType.Pinned:
            return <Pinned />;
        case IconType.Plant:
            return <Plant />;
        case IconType.Play:
            return <Play />;
        case IconType.Plus:
            return <Plus />;
        case IconType.PlusSmall:
            return <PlusSmall />;
        case IconType.Print:
            return <Print />;
        case IconType.QRCode:
            return <QRCode />;
        case IconType.Question:
            return <Question />;
        case IconType.ReadingTime:
            return <ReadingTime />;
        case IconType.Redo:
            return <Redo />;
        case IconType.Refresh:
            return <Refresh />;
        case IconType.Reset:
            return <Reset />;
        case IconType.Resize:
            return <Resize />;
        case IconType.Save:
            return <Save />;
        case IconType.Search:
            return <Search />;
        case IconType.SearchSmall:
            return <SearchSmall />;
        case IconType.Settings:
            return <Settings />;
        case IconType.Share:
            return <Share />;
        case IconType.ShoppingCart:
            return <ShoppingCart />;
        case IconType.Show:
            return <Show />;
        case IconType.Skip:
            return <Skip />;
        case IconType.SkipBack:
            return <SkipBack />;
        case IconType.SocialFeed:
            return <SocialFeed />;
        case IconType.Sort:
            return <Sort />;
        case IconType.SortAscending:
            return <SortAscending />;
        case IconType.SortDescending:
            return <SortDescending />;
        case IconType.SortDown:
            return <SortDown />;
        case IconType.SortUp:
            return <SortUp />;
        case IconType.SortUpDown:
            return <SortUpDown />;
        case IconType.SourceCode:
            return <SourceCode />;
        case IconType.Stop:
            return <Stop />;
        case IconType.Success:
            return <Success />;
        case IconType.SuccessFilled:
            return <SuccessFilled />;
        case IconType.Support:
            return <Support />;
        case IconType.Trash:
            return <Trash />;
        case IconType.Twitter:
            return <Twitter />;
        case IconType.Undo:
            return <Undo />;
        case IconType.Unlocked:
            return <Unlocked />;
        case IconType.Upload:
            return <Upload />;
        case IconType.User:
            return <User />;
        case IconType.VolumeLoud:
            return <VolumeLoud />;
        case IconType.VolumeMute:
            return <VolumeMute />;
        case IconType.VolumeQuiet:
            return <VolumeQuiet />;
        case IconType.Warning:
            return <Warning />;
        case IconType.WarningFilled:
            return <WarningFilled />;
        case IconType.Weight:
            return <Weight />;
        case IconType.Yammer:
            return <Yammer />;
        case IconType.YouTube:
            return <YouTube />;
        case IconType.ZoomIn:
            return <ZoomIn />;
        case IconType.ZoomOut:
            return <ZoomOut />;
    }
};

export default Icon;
