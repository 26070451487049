import { useAppDispatch, useAppSelector } from '../common';
import { ApplicationType } from '../../types';
import { getApplicationActions } from '../../services/ApplicationActions';

export interface PowerlossCalculationProps {
    applicationType: ApplicationType;
}

export const usePowerlossCalculation = ({ applicationType }: PowerlossCalculationProps) => {
    const dispatch = useAppDispatch();
    const isPumpSystem = applicationType === 'pump';
    const chartOptionParameters = useAppSelector(state => isPumpSystem ? state.pumpSystemChart.powerlossCalculationChartTabParams : state.fanSystemChart.powerlossCalculationChartTabParams);
    const { powerLossCalculationChartTabParamsHandler } = getApplicationActions(applicationType);

    const handlePowerLossAtRequiredShaftPowerChange = () => {
        dispatch(powerLossCalculationChartTabParamsHandler({ ...chartOptionParameters, powerLossAtRequiredShaftPower: !chartOptionParameters.powerLossAtRequiredShaftPower }));
    };

    const handleShowReferenceIesPowerLoss = () => {
        dispatch(powerLossCalculationChartTabParamsHandler({ ...chartOptionParameters, showReferenceIesPowerLoss: !chartOptionParameters.showReferenceIesPowerLoss }));
    };

    return { chartOptionParameters, handlePowerLossAtRequiredShaftPowerChange, handleShowReferenceIesPowerLoss };
};
