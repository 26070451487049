import { useCallback, useEffect, useMemo } from 'react';
import { DEFAULT_CURRENCY_CODE, TRANSLATIONS } from '../../constants';
import { Language } from '../../enums';
import { Country, Currency, GeolocationResult } from '../../models';
import { Api, apiCall, priceExchanger } from '../../services';
import { setLanguage, addRegionSetting, setDefaultCurrency, setRegionSettings, setCountryCodeSet, setRegionSettingsInitialized, addSnackbar } from '../../store';
import { useAppDispatch, useAppSelector, useTranslate } from '../common';
import { useSystemsLoad } from './SystemsLoad';

export const useRegionSettings = () => {
    const dispatch = useAppDispatch();
    const { regionSettings, defaultCurrency, countryCodeSet } = useAppSelector(state => state.regionSettings);
    const handlePumpDefaultsGet = useSystemsLoad({ applicationType: 'pump' }).handleGetDefaults;
    const handleFanDefaultsGet = useSystemsLoad({ applicationType: 'fan' }).handleGetDefaults;
    const { translate } = useTranslate();
    type LanguageType = 'en' | 'de';
    const languages = useMemo(() => Object.keys(Language).slice(0, Math.ceil(Object.keys(Language).length / 2)).map(x => Number(x)), []);

    const handleGetRegionSettings = useCallback(async (countryCode: string) => {
        return apiCall(
            Api.getRegionSettings(countryCode),
            async x => {
                dispatch(setRegionSettings(x.data));
                dispatch(setDefaultCurrency(x.data.currencies?.find(x => x.code === DEFAULT_CURRENCY_CODE)));
                dispatch(setRegionSettingsInitialized(true));
            },
            async () => {
                dispatch(addSnackbar({
                    title: translate(TRANSLATIONS.error.error),
                    description: translate(TRANSLATIONS.error.api.getRegionSettings), type: 'error'
                }));
            }
        );
    }, [dispatch, translate]);

    const initializeRegionSettings = useCallback(async () => {
        if (!regionSettings && !countryCodeSet) {
            const countryCode = await handleCountryCodeFromIpGet();

            dispatch(addRegionSetting({ countryCode }));
            dispatch(setCountryCodeSet(true));

            await handleGetRegionSettings(countryCode);
        }
    }, [countryCodeSet, dispatch, handleGetRegionSettings, regionSettings]);

    useEffect(() => {
        (async () => await initializeRegionSettings())();
    }, [initializeRegionSettings]);


    const handleCurrencySelect = useCallback((value: Currency) => {
        dispatch(addRegionSetting({ currency: value }));
    }, [dispatch]);

    const handleCountrySelect = useCallback((value: Country) => {
        handleGetRegionSettings(value.code);
        handlePumpDefaultsGet(value.code);
        handleFanDefaultsGet(value.code);
    }, [handleFanDefaultsGet, handleGetRegionSettings, handlePumpDefaultsGet]);

    const handleEnergyPriceChange = useCallback((value: string) => {
        if (parseFloat(value)) {
            dispatch(addRegionSetting({
                energyPrice: priceExchanger(parseFloat(value), regionSettings?.currency, defaultCurrency)
            }));
        }
    }, [defaultCurrency, dispatch, regionSettings?.currency]);

    const handleCo2FactorChange = useCallback((value: string) => {
        if (parseFloat(value)) {
            dispatch(addRegionSetting({ co2Factor: parseFloat(value) }));
        }
    }, [dispatch]);

    const getLanguageFromCode = useCallback((x: Language) => translate(TRANSLATIONS.header.languages[Language[x].toLowerCase() as LanguageType]), [translate]);

    const handleLanguageSelect = useCallback((x: Language) => dispatch(setLanguage(x)), [dispatch]);

    const exchangedEnergyPrice = priceExchanger(regionSettings?.energyPrice ?? 0, defaultCurrency, regionSettings?.currency);

    const handleCountryCodeFromIpGet = async () => {
        let response: GeolocationResult | null = null;

        try {
            response = await Api.getGeolocation();
        } catch {
            console.warn('IPinfo API is not working');
        }

        return response ? response.country.toUpperCase() : 'DE';
    };

    return {
        initializeRegionSettings,
        handleGetRegionSettings,
        handleCo2FactorChange,
        handleCountrySelect,
        handleCurrencySelect,
        handleEnergyPriceChange,
        handleLanguageSelect,
        getLanguageFromCode,
        languages,
        exchangedEnergyPrice
    };
};
