import React from 'react';
import { TRANSLATIONS } from '../../../../constants';
import { useAppSelector, useTranslate } from '../../../../hooks/common';
import Text from '../../../common/Text';
import { ApplicationType } from '../../../../types';

export interface BasicViewApplicationCardInfoProps {
    applicationType: ApplicationType;
}

const BasicViewApplicationCardInfo = ({ applicationType }: BasicViewApplicationCardInfoProps) => {
    const dark = useAppSelector(state => state.layout.darkMode);
    const { translate } = useTranslate();
    const applicationTypeTitleCase = applicationType === 'pump' ? 'Pump' : 'Fan';

    return (
        <>
            <Text dark={dark} type='subtitle' style={{ paddingBottom: 8 }}>
                {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}LoadPoint.Title`])}
            </Text>
            <ul>
                <li>
                    <Text dark={dark} type='subtitle-very-small' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}LoadPoint.Content`][0])}
                    </Text>
                    <ul>
                        <li>
                            <Text dark={dark} type='normal'>
                                {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}LoadPoint.Content`][1])}
                            </Text>
                        </li>
                    </ul>
                </li>
                <li>
                    <Text dark={dark} type='subtitle-very-small' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}LoadPoint.Content`][2])}
                    </Text>
                    <ul>
                        <li>
                            <Text dark={dark} type='normal'>
                                {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}LoadPoint.Content`][3])}
                            </Text>
                        </li>
                    </ul>
                </li>
                <li>
                    <Text dark={dark} type='subtitle-very-small' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}LoadPoint.Content`][4])}
                    </Text>
                    <ul>
                        <li>
                            <Text dark={dark} type='normal'>
                                {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}LoadPoint.Content`][5])}
                            </Text>
                        </li>
                    </ul>
                </li>
                <li>
                    <Text dark={dark} type='subtitle-very-small' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}LoadPoint.Content`][6])}
                    </Text>
                    <ul>
                        <li>
                            <Text dark={dark} type='normal'>
                                {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}LoadPoint.Content`][7])}
                            </Text>
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    );
};

export default BasicViewApplicationCardInfo;
