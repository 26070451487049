import React, { useCallback, useMemo, useState } from 'react';
import { TRANSLATIONS } from '../../../constants';
import { useHook } from '../../../hooks';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { ApplicationRequest, SupplyType } from '../../../models';
import { ApplicationType } from '../../../types';
import Box from '../../common/Box';
import Grid from '../../common/Grid';
import SystemCircuit from '../SystemCircuit';
import Select from '../../common/Select';
import IconButton from '../../common/IconButton';
import { IconType } from '../../common/Icon';
import BasicViewLineSupplyInfo from './ModalInfo/BasicViewLineSupplyInfo';
import Modal from '../../common/Modal';
import Flex from '../../common/Flex';

interface BasicViewBottomCardProps {
    applicationType: ApplicationType
}

const BasicViewBottomCard = ({ applicationType }: BasicViewBottomCardProps) => {
    const dark = useAppSelector(state => state.layout.darkMode);
    const { translate } = useTranslate();
    const isPumpSystem = applicationType === 'pump';
    const { supplyType, supplyTypes, application } = useAppSelector(state => isPumpSystem ? state.pumpSystem : state.fanSystem);
    const { regionSettings } = useAppSelector(state => state.regionSettings);
    const { handleGetConfiguration } = useHook(x => x.systemsLoad({ applicationType }));
    const supplyTypeValue = useMemo(() => supplyType ?? {} as SupplyType, [supplyType]);
    const supplyTypeValues = useMemo(() => supplyTypes ?? [], [supplyTypes]);
    const [lineSupplyModalOpen, setLineSupplyModalOpen] = useState(false);
    const handleLineSupplyModalOpen = useCallback(() => setLineSupplyModalOpen(true), []);
    const handleLineSupplyModalClose = useCallback(() => setLineSupplyModalOpen(false), []);

    const handleLineSupplyChange = useCallback(async (value: SupplyType) => {

        if (application) {
            const applicationRequest: ApplicationRequest = {
                countryCode: regionSettings?.countryCode ?? '--',
                application: application,
                supplyType: value
            };
            await handleGetConfiguration(applicationRequest);
        }

    }, [application, regionSettings?.countryCode, handleGetConfiguration]);

    return (
        <Box dark={dark} style={{ padding: 0 }} >
            <Grid columns={2} >
                <SystemCircuit />
                <SystemCircuit offset={8} />
                <Flex className='bottom-box-select' gap={24} style={{ padding: 16, paddingBottom: 24 }}>
                    <div style={{ flexGrow: 1 }}>
                        <Select
                            dark={dark}
                            label={translate(TRANSLATIONS.generated['System.LineSupply'])}
                            mapToString={x => x?.name ?? ''}
                            onSelect={handleLineSupplyChange}
                            value={supplyTypeValue}
                            values={supplyTypeValues}
                        />
                    </div>
                    <IconButton className='info-button-mobile' iconType={IconType.Information} size={16} layer={2} dark={dark} onClick={handleLineSupplyModalOpen} />
                </Flex>
                <Flex className='info-button-desktop' justification='flex-end' style={{ padding: 16, paddingBottom: 24 }}>
                    <IconButton iconType={IconType.Information} size={16} layer={2} dark={dark} onClick={handleLineSupplyModalOpen} />
                </Flex>
            </Grid>
            <Modal open={lineSupplyModalOpen} onClose={handleLineSupplyModalClose} dark={dark} allowClickAway movable>
                <BasicViewLineSupplyInfo />
            </Modal>
        </Box>
    );
};

export default BasicViewBottomCard;
