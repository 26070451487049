import { RefObject, useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '../common';
import { ApplicationType } from '../../types';
import { CONTROL_UNIT_TECHNICAL_DATA_BASE_URL } from '../../constants/ControlUnitTechnicalDataParams';

export interface TechnicalDataFormProps {
    applicationType: ApplicationType
    systemType: 'reference' | 'alternative';
    formRef: RefObject<HTMLFormElement>
}

export const useTechnicalData = ({ formRef, applicationType, systemType }: TechnicalDataFormProps) => {
    const [mlfb, setMlfb] = useState<string | undefined>();
    const system = useAppSelector(state => applicationType === 'pump' ? state.pumpSystem : state.fanSystem);

    const selectedSystem = systemType === 'reference' ? system.referenceSystem : system.alternativeSystem;

    useEffect(() => {
        if (mlfb) {
            formRef.current?.submit();
        }
    }, [mlfb]);

    const callSpc = useCallback((targetMlfb: string | undefined) => {

        if (targetMlfb === mlfb) {
            formRef.current?.submit();
        } else {
            setMlfb(targetMlfb);
        }
    }, [mlfb, formRef]);

    const callTst = useCallback((targetMlfb: string | undefined) => {
        window.open(`${CONTROL_UNIT_TECHNICAL_DATA_BASE_URL}?node=${targetMlfb}`, '_blank');
    }, []);

    const callMotorTechnicalData = useCallback(() => {
        callSpc(selectedSystem?.motorComponent.selectedMotor.mlfb);
    }, [selectedSystem, callSpc]);

    const callControlUnitTechnicalData = useCallback(() => {
        if (systemType === 'reference') {
            callTst(selectedSystem?.controlUnitComponent.selectedControlUnit.mlfb);
        } else {
            callSpc(selectedSystem?.controlUnitComponent.selectedControlUnit.mlfb);
        }
    }, [selectedSystem, callTst, callSpc, systemType]);

    return { mlfb, callMotorTechnicalData, callControlUnitTechnicalData };
};
