import React from 'react';
import { TooltipProps } from 'recharts';
import { numericFormatter } from 'react-number-format';
import { TRANSLATIONS } from '../../../../constants';
import { useTranslate } from '../../../../hooks/common';
import Flex from '../../../common/Flex';
import ChartItemMarker from '../ChartElements/ChartItemMarker';
import { useCurrency } from '../../../../hooks';


export interface LifetimeCostsTooltipProps extends TooltipProps<string, string> {
    referenceColor: string;
    alternativeColor: string;
}

const LifetimeCostsTooltip = ({ active, payload, label, alternativeColor, referenceColor }: LifetimeCostsTooltipProps) => {
    const { translate } = useTranslate();
    const { currencyFormat } = useCurrency();

    if (active && payload && payload.length && payload[0].payload.intersection) {
        return (
            <div className='custom-tooltip intersection-point'>
                <div className='label'>{`${translate(TRANSLATIONS.generated['Chart.OperationTime'])}: ${payload[0].payload.month.toFixed(1)} ${translate(TRANSLATIONS.generated['Common.Months'])}`}</div>
                <Flex alignment='stretch' direction='column'>
                    <Flex direction='row' gap={36} alignment='stretch' justification='space-between' style={{ color: referenceColor }}>
                        <div className='value-list-item-name'>{`${translate(TRANSLATIONS.generated['Chart.BreakevenPoint'])}:`}</div>
                        <div className='value-list-item-value'>{numericFormatter(payload[0].payload.referenceCost.toString(), currencyFormat)}</div>
                    </Flex>
                </Flex>
            </div>
        );
    }

    if (active && payload && payload.length) {
        return (
            <div className='custom-tooltip'>
                <div className='label'>{`${translate(TRANSLATIONS.generated['Chart.OperationTime'])}: ${label} ${translate(TRANSLATIONS.generated['Common.Months'])}`}</div>
                <Flex alignment='stretch' direction='column'>
                    <Flex direction='row' gap={36} alignment='stretch' justification='space-between' style={{ color: referenceColor }}>
                        <Flex gap={8}>
                            <ChartItemMarker type='diamond' size={8} color={referenceColor} />
                            <div className='value-list-item-name'>{`${translate(TRANSLATIONS.generated['System.ReferenceSystem'])}:`}</div>
                        </Flex>
                        <div className='value-list-item-value'>{numericFormatter(payload[0].payload.referenceCost.toString(), currencyFormat)}</div>
                    </Flex>
                    <Flex direction='row' gap={36} alignment='stretch' justification='space-between' style={{ color: alternativeColor }}>
                        <Flex gap={8}>
                            <ChartItemMarker type='triangle' size={8} color={alternativeColor} />
                            <div className='value-list-item-name'>{`${translate(TRANSLATIONS.generated['System.AlternativeSystem'])}:`}</div>
                        </Flex>
                        <div className='value-list-item-value'>{numericFormatter(payload[0].payload.alternativeCost.toString(), currencyFormat)}</div>
                    </Flex>
                </Flex>
            </div>
        );
    }

    return null;
};

export default LifetimeCostsTooltip;
