import React, { createContext, CSSProperties, FocusEvent, KeyboardEvent, ReactNode, useMemo, useRef, useState } from 'react';
import { useOpenAnimations } from '../../hooks/common';
import Icon, { IconType } from './Icon';

export interface MenuContext {
    getBoundary?: () => MenuBoundary | undefined;
    onMenuClose?: () => void;
}

export interface MenuBoundary {
    left: number;
    right: number;
}

export interface MenuProps {
    children: ReactNode;
    label: string;
    dark?: boolean;
    getBoundary?: () => MenuBoundary | undefined;
}

export const MenuContext = createContext<MenuContext>({});

const Menu = ({ children, dark, getBoundary, label }: MenuProps) => {
    const [open, setOpen] = useState(false);
    const [animationClass, handleAnimationEnd] = useOpenAnimations(open);
    const buttonRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const closed = animationClass === 'closed';

    const positionStyle = useMemo((): CSSProperties => {
        const boundary = getBoundary && getBoundary();

        // The normal arrangement is rendered in the default/closed state to provide a reference size for subsequent calculations
        if (closed || !boundary || !buttonRef.current || !dropdownRef.current) {
            return { left: 0 };
        }

        const buttonRectangle = buttonRef.current.getBoundingClientRect();
        const dropdownWidth = dropdownRef.current.offsetWidth;
        const requiredSpacing = 8;
        const { left: buttonLeft, right: buttonRight } = buttonRectangle;
        const { left: boundaryLeft, right: boundaryRight } = boundary;

        // Check and position value for right side dropdown arrangement
        if (boundaryRight - buttonLeft >= dropdownWidth + requiredSpacing) {
            return { left: 0 };
        }

        // Check and position value for left side dropdown arrangement
        if (buttonRight - boundaryLeft >= dropdownWidth + requiredSpacing) {
            return { right: 0 };
        }

        // Check whether the dropdown fit the available space without wrapping the text
        if (boundaryRight - boundaryLeft >= dropdownWidth + 2 * requiredSpacing) {
            // Calculation for the negative offsets required for the different arrangements
            const positionLeftNegativeOffset = buttonRight - boundaryLeft - dropdownWidth - requiredSpacing;
            const positionRightNegativeOffset = boundaryRight - buttonLeft - dropdownWidth - requiredSpacing;

            // The value closer to zero is used for the positioning
            if (positionRightNegativeOffset > positionLeftNegativeOffset) {
                return { left: positionRightNegativeOffset };
            } else {
                return { right: positionLeftNegativeOffset };
            }
        }

        // Calculation of the positon values for the full width arrangement when the text is wrapped
        const fullWidthLeft = boundaryLeft + requiredSpacing - buttonLeft;
        const fullWidthRight = buttonRight + requiredSpacing - boundaryRight;

        return { left: fullWidthLeft, minWidth: 0, right: fullWidthRight, whiteSpace: 'normal' };
    }, [closed, getBoundary]);
    
    const handleBlur = (event: FocusEvent<HTMLDivElement>) => {
        if (!event.relatedTarget || !buttonRef.current || !buttonRef.current.contains(event.relatedTarget as HTMLElement)) {
            setOpen(false);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();

        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            setOpen(x => !x);
        }
    };

    return (
        <div ref={buttonRef} className={`menu ${animationClass} ${dark ? 'dark' : ''}`} tabIndex={0} onBlur={handleBlur} onClick={() => setOpen(x => !x)} onKeyDown={handleKeyDown}>
            {label}
            <Icon type={IconType.ArrowheadDown} />
            <div ref={dropdownRef} className='menu-dropdown' style={positionStyle} onClick={e => e.stopPropagation()} onAnimationEnd={handleAnimationEnd}>
                <MenuContext.Provider value={{ getBoundary, onMenuClose: () => setOpen(false) }}>
                    {children}
                </MenuContext.Provider>
            </div>
        </div>
    );
};

export default Menu;
