import { EnergyPerformanceChartTabParams } from '../models/chart/EnergyPerformanceParams';
import { DEFAULT_CONTRACT_PERIOD } from './DefaultContractPeriod';
import { DEFAULT_FINANCING_INTEREST } from './DefaultFinancingInterest';

export const DEFAULT_ENERGY_PERFORMANCE_CHART_TAB_PARAMS: EnergyPerformanceChartTabParams = {
    contractPeriod: DEFAULT_CONTRACT_PERIOD,
    contractPeriodString: DEFAULT_CONTRACT_PERIOD.toString(),
    showDifference: true,
    hasContract: false,
    financingInterest: DEFAULT_FINANCING_INTEREST,
    financingInterestString: DEFAULT_FINANCING_INTEREST.toString()
};
