import React, { useEffect, useState, createContext, useCallback, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { FAN_SYSTEMS_SUB_PAGES, TRANSLATIONS } from '../../../constants';
import { HeaderTab } from '../../../enums';
import { useAppDispatch, useAppSelector, useTranslate } from '../../../hooks/common';
import { setActiveTab } from '../../../store';
import AppearanceAnimationWrapper from '../../common/AppearanceAnimationWrapper';
import { IconType } from '../../common/Icon';
import ContentContainer from '../../common/ContentContainer';
import Flex from '../../common/Flex';
import IconButton from '../../common/IconButton';
import Loader from '../../common/Loader';
import Modal from '../../common/Modal';
import Sticky from '../../common/Sticky';
import TabSwitcher, { Tab } from '../../common/TabSwitcher';
import Text from '../../common/Text';
import { useHook } from '../../../hooks';
import ComparisonCard from '../ComparisonTabs/ComparisonCard';
import DocumentExportModal from '../DocumentExportModal';
import SystemActionsMenu from '../SystemActionsMenu';

export interface FanSystemsContext {
    onSubPageChange?: (selectedTab: number) => void;
}

export const FanSystemsContext = createContext<FanSystemsContext>({});

const FanSystems = () => {
    const dark = useAppSelector(state => state.layout.darkMode);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { translate } = useTranslate();
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [isStickyBarActive, setIsStickyBarActive] = useState(false);
    const [basicViewModalOpen, setBasicViewModalOpen] = useState(false);
    const [commercialViewModalOpen, setCommercialViewModalOpen] = useState(false);
    const [documentExportModalOpen, setDocumentExportModalOpen] = useState(false);
    const fanSystemState = useAppSelector(state => state.fanSystem);
    const { regionSettings, countryCodeSet, defaultCurrency } = useAppSelector(state => state.regionSettings);
    const { handleGetDefaults, defaultsLoading } = useHook(x => x.systemsLoad({ applicationType: 'fan' }));
    const { handleExportDownload } = useHook(x => x.documentExport({ applicationType: 'fan' }));

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setActiveTab(HeaderTab.FanSystems));
    }, [dispatch]);

    useEffect(() => {
        if (defaultCurrency && !defaultsLoading && !fanSystemState.application && !fanSystemState.supplyType && countryCodeSet && !fanSystemState.systemsLoading) {
            handleGetDefaults(regionSettings?.countryCode ?? '--');
        }
    }, [countryCodeSet, defaultCurrency, defaultsLoading, handleGetDefaults, fanSystemState.application, fanSystemState.supplyType, regionSettings?.countryCode, fanSystemState.systemsLoading]);

    const handleBasicViewModalOpen = useCallback(() => setBasicViewModalOpen(true), []);
    const handleBasicViewModalClose = useCallback(() => setBasicViewModalOpen(false), []);
    const handleCommercialViewModalOpen = useCallback(() => setCommercialViewModalOpen(true), []);
    const handleCommercialViewModalClose = useCallback(() => setCommercialViewModalOpen(false), []);

    const tabs = useMemo((): Tab[] => [
        {
            label: translate(TRANSLATIONS.generated['System.BasicView']),
            content: <IconButton iconType={IconType.Information} onClick={handleBasicViewModalOpen} dark={dark} key='basic-view-info' size={16} />
        },
        {
            label: translate(TRANSLATIONS.generated['Common.CommercialView']),
            content: <IconButton iconType={IconType.Information} onClick={handleCommercialViewModalOpen} dark={dark} key='commercial-view-info' size={16} />
        }
    ], [dark, handleBasicViewModalOpen, handleCommercialViewModalOpen, translate]);

    return (
        <Loader dark={dark} loading={fanSystemState.systemsLoading} marginTop={-88} marginLeft={-10} marginRight={-10} marginBottom={20} disableInitialLoading>
            {defaultCurrency && fanSystemState.alternativeSystem && fanSystemState.referenceSystem && fanSystemState.application && <AppearanceAnimationWrapper >
                <Sticky anchor='top' dark={dark} onStuckChange={(stuck: boolean) => setIsStickyBarActive(stuck)}>
                    <ContentContainer style={{ paddingBottom: 32, paddingTop: 32 }}>
                        <Flex justification='space-between'>
                            <Text type='subtitle-very-small' dark={dark}>
                                {isStickyBarActive
                                    ? translate(TRANSLATIONS.generated['Home.FanSystems'])
                                    : <br />
                                }
                            </Text>
                            <Flex gap={8}>
                                <SystemActionsMenu applicationType='fan' />
                                <IconButton dark={dark} iconType={IconType.Download} onClick={() => setDocumentExportModalOpen(true)} />
                            </Flex>
                        </Flex>
                    </ContentContainer>
                </Sticky>
                <ContentContainer style={{ paddingTop: 16 }}>
                    <TabSwitcher
                        selectedTab={selectedTab}
                        tabs={tabs}
                        onSelect={(index: number) => navigate(`/fan-systems/${FAN_SYSTEMS_SUB_PAGES[index]}`)}
                        dark={dark}
                        style={{ marginBottom: 12 }}
                    />
                    <div className={`systems-view ${fanSystemState.fullScreenChart ? 'fullscreen' : ''}`}>
                        <FanSystemsContext.Provider value={{ onSubPageChange: setSelectedTab }}>
                            <Outlet />
                        </FanSystemsContext.Provider>
                        <div className='savings-box'><ComparisonCard applicationType='fan' systemsLoading={fanSystemState.systemsLoading} /></div>
                    </div>
                    <Modal open={basicViewModalOpen} onClose={handleBasicViewModalClose} dark={dark} allowClickAway movable>
                        <Text dark={dark} type='subtitle' style={{ paddingBottom: 8 }}>
                            {translate(TRANSLATIONS.generated['System.BasicView'])}
                        </Text>
                        <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                            {translate(TRANSLATIONS.generated['System.FanTechnicalExplanation'][0])}
                            <strong>{` ${translate(TRANSLATIONS.generated['System.BasicView'])}`}</strong>
                            {translate(TRANSLATIONS.generated['System.FanTechnicalExplanation'][2])}
                            <strong>{translate(TRANSLATIONS.generated['System.FanTechnicalExplanation'][3])}</strong>
                            {translate(TRANSLATIONS.generated['System.FanTechnicalExplanation'][4])}
                        </Text>
                    </Modal>
                    <Modal open={commercialViewModalOpen} onClose={handleCommercialViewModalClose} dark={dark} allowClickAway movable>
                        <Text dark={dark} type='subtitle' style={{ paddingBottom: 8 }}>
                            {translate(TRANSLATIONS.generated['Common.CommercialView'])}
                        </Text>
                        <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                            {translate(TRANSLATIONS.generated['System.FanCommercialExplanation'][0])}
                            <strong>{` ${translate(TRANSLATIONS.generated['System.FanCommercialExplanation'][1])}`}</strong>
                            {translate(TRANSLATIONS.generated['System.FanCommercialExplanation'][2])}
                            <strong>{translate(TRANSLATIONS.generated['System.FanCommercialExplanation'][3])}</strong>
                            {translate(TRANSLATIONS.generated['System.FanCommercialExplanation'][4])}
                        </Text>
                    </Modal>
                    <DocumentExportModal handleDocumentExport={handleExportDownload} handleDocumentExportModalClose={() => setDocumentExportModalOpen(false)} showDocumentExportModal={documentExportModalOpen} />
                </ContentContainer>
            </AppearanceAnimationWrapper>}
        </Loader >
    );
};

export default FanSystems;
