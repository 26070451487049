import React, { KeyboardEvent, ReactNode, RefObject, useCallback } from 'react';
import { Tresholds, useMovability, useOpenAnimations } from '../../hooks/common';
import IconButton from './IconButton';
import { IconType } from './Icon';

interface ModalProps {
    children: ReactNode;
    open: boolean;
    allowClickAway?: boolean;
    dark?: boolean;
    movable?: boolean;
    width?: number | 'full';
    onAfterClose?: () => void;
    onClose?: () => void;
}

const Modal = ({ allowClickAway, children, dark, movable, open, width, onAfterClose, onClose }: ModalProps) => {
    const [animationClass, handleAnimationEnd] = useOpenAnimations(open);

    const getTresholds = useCallback((elementRef: RefObject<HTMLDivElement>): Tresholds => {
        const element = elementRef.current;
        const parent = element?.parentElement;

        const height = element?.offsetHeight ?? 0;
        const width = element?.offsetWidth ?? 0;

        const parentHeight = parent ? parent.offsetHeight : 0;
        const parentWidth = parent?.offsetWidth ?? 0;

        const tresholdX = (parentWidth - width) / 2;
        const tresholdY = (parentHeight - height) / 2;

        return { xMin: -tresholdX, xMax: tresholdX, yMin: -tresholdY, yMax: tresholdY };
    }, []);

    const { elementRef, handleKeyDown, handleMouseDown } = useMovability(getTresholds);

    const handleClose = () => {
        if (onClose && allowClickAway) {
            onClose();
        }
    };

    const handleBackgroundAnimationEnd = () => {
        handleAnimationEnd();

        animationClass === 'opening' && elementRef.current?.focus();

        onAfterClose && animationClass === 'closing' && onAfterClose();
    };

    const handleModalKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (onClose && event.key === 'Escape') {
            onClose();
        }

        if (movable) {
            handleKeyDown(event);
        }
    };

    return (
        <div
            className={`modal-background ${animationClass} ${allowClickAway ? 'clickable' : ''} ${dark ? 'dark' : ''}`}
            onClick={handleClose}
            onAnimationEnd={handleBackgroundAnimationEnd}
        >
            <div
                ref={elementRef}
                className='modal'
                tabIndex={movable || onClose ? 0 : undefined}
                onClick={e => e.stopPropagation()}
                onKeyDown={handleModalKeyDown}
                style={{ width: width === 'full' ? 1350 : width ?? 600 }}
            >
                <IconButton iconType={IconType.Close} size={16} layer={dark ? 2 : 1} dark={dark} onClick={handleClose} />
                {movable && <div className='move-handle' onMouseDown={handleMouseDown} />}
                {children}
            </div>
        </div>
    );
};

export default Modal;
