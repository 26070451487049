import React from 'react';
import { TRANSLATIONS } from '../../../../constants';
import { useAppSelector, useTranslate } from '../../../../hooks/common';
import Text from '../../../common/Text';

const BasicViewPumpControlModeInfo = () => {
    const dark = useAppSelector(state => state.layout.darkMode);
    const { translate } = useTranslate();

    return (
        <>
            <Text dark={dark} type='subtitle' style={{ paddingBottom: 8 }}>
                {translate(TRANSLATIONS.generated['Help.PumpTechnicalControlMode.Title'])}
            </Text>
            <ul>
                <li>
                    <Text dark={dark} type='subtitle-very-small' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.PumpTechnicalControlMode.Content'][0])}
                    </Text>
                    <ul>
                        <li>
                            <Text dark={dark} type='normal'>
                                {translate(TRANSLATIONS.generated['Help.PumpTechnicalControlMode.Content'][1])}
                            </Text>
                        </li>
                    </ul>
                </li>
                <li>
                    <Text dark={dark} type='subtitle-very-small' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.PumpTechnicalControlMode.Content'][2])}
                    </Text>
                    <ul>
                        <li>
                            <Text dark={dark} type='normal'>
                                {translate(TRANSLATIONS.generated['Help.PumpTechnicalControlMode.Content'][3])}
                            </Text>
                        </li>
                    </ul>
                </li>
                <li>
                    <Text dark={dark} type='subtitle-very-small' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated['Help.PumpTechnicalControlMode.Content'][4])}
                    </Text>
                    <ul>
                        <li>
                            <Text dark={dark} type='normal'>
                                {translate(TRANSLATIONS.generated['Help.PumpTechnicalControlMode.Content'][5])}
                            </Text>
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    );
};

export default BasicViewPumpControlModeInfo;
