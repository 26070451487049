import React from 'react';
import Flex from '../../../common/Flex';
import { CHART_OPERATION_PROFILE, TRANSLATIONS } from '../../../../constants';
import { TooltipProps } from 'recharts';
import { numericFormatter } from 'react-number-format';
import { useAppSelector, useTranslate } from '../../../../hooks/common';
import ChartItemMarker from '../ChartElements/ChartItemMarker';

export interface EnergySavingsTooltipProps extends TooltipProps<string, string> {
    powerUnit: string;
    timeUnit: string;
    showOperationProfile: boolean;
    showApplicationPowerDemand: boolean;
    referenceColor: string;
    alternativeColor: string;
    applicationPowerDemandColor: string;
}

const EnergySavingsTooltip = ({ active, payload, label, powerUnit, timeUnit, showOperationProfile, showApplicationPowerDemand, alternativeColor, referenceColor, applicationPowerDemandColor }: EnergySavingsTooltipProps) => {
    const { translate } = useTranslate();
    const { numberFormatOptions } = useAppSelector(x => x.regionSettings);

    if (active && payload && payload.length) {
        return (
            <div className='custom-tooltip'>
                <div className='label'>{`${translate(TRANSLATIONS.generated['System.Flowrate'])} ${label} %`}</div>
                <Flex alignment={'stretch'} direction={'column'}>
                    {showOperationProfile &&
                        <Flex direction={'row'} gap={36} alignment={'stretch'} justification={'space-between'} style={{ color: CHART_OPERATION_PROFILE }}>
                            <Flex gap={8}>
                                <ChartItemMarker type='square' size={8} color={CHART_OPERATION_PROFILE} />
                                <div className='value-list-item-name'>{`${translate(TRANSLATIONS.generated['System.OperationProfile'])}:`}</div>
                            </Flex>
                            <div className='value-list-item-value'>{payload[0].payload.operationProfile} {timeUnit}</div>
                        </Flex>}
                    {showApplicationPowerDemand &&
                        <Flex direction={'row'} gap={36} alignment={'stretch'} justification={'space-between'} style={{ color: applicationPowerDemandColor }}>
                            <Flex gap={8}>
                                <ChartItemMarker type='circle' size={8} color={applicationPowerDemandColor} />
                                <div className='value-list-item-name'>{`${translate(TRANSLATIONS.generated['Main.Application'])}:`}</div>
                            </Flex>
                            <div className='value-list-item-value'>{numericFormatter(payload[0].payload.applicationPowerDemand.toString(), numberFormatOptions)} {powerUnit}</div>
                        </Flex>}
                    <Flex direction={'row'} gap={36} alignment={'stretch'} justification={'space-between'} style={{ color: referenceColor }}>
                        <Flex gap={8}>
                            <ChartItemMarker type='diamond' size={8} color={referenceColor} />
                            <div className='value-list-item-name'>{`${translate(TRANSLATIONS.generated['System.ReferenceSystem'])}:`}</div>
                        </Flex>
                        <div className='value-list-item-value'>{numericFormatter(payload[0].payload.referencePowerDemand.toString(), numberFormatOptions)} {powerUnit}</div>
                    </Flex>
                    <Flex direction={'row'} gap={36} alignment={'stretch'} justification={'space-between'} style={{ color: alternativeColor }}>
                        <Flex gap={6}>
                            <ChartItemMarker type='triangle' size={10} color={alternativeColor} />
                            <div className='value-list-item-name'>{`${translate(TRANSLATIONS.generated['System.AlternativeSystem'])}:`}</div>
                        </Flex>
                        <div className='value-list-item-value'>{numericFormatter(payload[0].payload.alternativePowerDemand.toString(), numberFormatOptions)} {powerUnit}</div>
                    </Flex>
                </Flex>
            </div>
        );
    }

    return null;
};

export default EnergySavingsTooltip;
