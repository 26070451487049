import React, { CSSProperties, ReactNode } from 'react';

interface ContentContainerProps {
    children?: ReactNode;
    style?: CSSProperties;
}

const ContentContainer = ({ children, style }: ContentContainerProps) => (
    <div className='content-container' style={style}>
        {children}
    </div>
);

export default ContentContainer;
