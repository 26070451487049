import React, { ReactNode } from 'react';

interface ChartWrapperProps {
    children: ReactNode;
    dark?: boolean;
}

const ChartWrapper = ({ children, dark }: ChartWrapperProps) => (
    <div className={`chart-wrapper ${dark ? 'dark' : ''}`}>
        {children}
    </div>
);

export default ChartWrapper;
