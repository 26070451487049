import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../common';
import { ApplicationType } from '../../types';
import { getApplicationActions } from '../../services/ApplicationActions';

export interface EnergyPerformanceParameters {
    applicationType: ApplicationType
}

export const useEnergyPerformance = ({ applicationType }: EnergyPerformanceParameters) => {
    const dispatch = useAppDispatch();
    const isPumpType = applicationType === 'pump';
    const energyPerformanceChartData = useAppSelector(state => state.pumpSystemChart.energyPerformanceChartData);
    const chartOptionParameters = useAppSelector(store => isPumpType ? store.pumpSystemChart.energyPerformanceChartTabParams : store.fanSystemChart.energyPerformanceChartTabParams);

    const energyPerformanceParams = useAppSelector(x => isPumpType ? x.pumpSystemChart.energyPerformanceChartTabParams : x.fanSystemChart.energyPerformanceChartTabParams);

    const { energyPerformanceParamsHandler } = getApplicationActions(applicationType);

    const handleChangeFinancialInterest = (financialInterest: string) => {
        if (isNaN(Number.parseInt(financialInterest))) {
            dispatch(energyPerformanceParamsHandler({ ...chartOptionParameters, financingInterestString: financialInterest }));
        }
        else {
            dispatch(energyPerformanceParamsHandler({
                ...chartOptionParameters,
                financingInterest: Number.parseFloat(financialInterest),
                financingInterestString: financialInterest
            }));
        }
    };

    const handleChangeContractPeriod = (contractPeriod: string) => {
        dispatch(energyPerformanceParamsHandler({ ...chartOptionParameters, contractPeriodString: contractPeriod }));
        if (isNaN(Number.parseInt(contractPeriod))) {
            dispatch(energyPerformanceParamsHandler({ ...chartOptionParameters, contractPeriodString: contractPeriod }));
        } else {
            dispatch(energyPerformanceParamsHandler({ ...chartOptionParameters, contractPeriod: Number.parseInt(contractPeriod), contractPeriodString: contractPeriod }));
        }
    };

    const handleShowDifference = () => {
        dispatch(energyPerformanceParamsHandler({ ...chartOptionParameters, showDifference: !chartOptionParameters.showDifference }));
    };

    const handleHasContract = () => {
        dispatch(energyPerformanceParamsHandler({ ...chartOptionParameters, hasContract: !chartOptionParameters.hasContract }));
    };

    const alternativeRunningCostsAfterInnovation = useMemo(
        () => energyPerformanceChartData
            ? (energyPerformanceChartData.chartData[energyPerformanceChartData?.chartData.length - 1].runningCosts
                + (energyPerformanceParams.hasContract ? energyPerformanceChartData?.chartData[energyPerformanceChartData.chartData.length - 4].contractRate ?? 0 : 0)
            ).toFixed(0) ?? ''
            : ''
        , [energyPerformanceChartData, energyPerformanceParams.hasContract]);

    const runningCostsAfterContractTime = useMemo(() => energyPerformanceChartData ?
        energyPerformanceChartData.chartData[energyPerformanceChartData.chartData.length - 1].runningCosts.toFixed(0) ?? ''
        : '', [energyPerformanceChartData]);

    return {
        handleChangeContractPeriod,
        handleChangeFinancialInterest,
        handleHasContract,
        handleShowDifference,
        chartOptionParameters,
        alternativeRunningCostsAfterInnovation,
        runningCostsAfterContractTime
    };
};
