import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import Home from './ts/components/application/Home';
import PumpSystems from './ts/components/application/PumpSystems/PumpSystems';
import PumpSystemsBasicView from './ts/components/application/PumpSystems/subPages/PumpSystemsBasicView';
import PumpSystemsCommercialView from './ts/components/application/PumpSystems/subPages/PumpSystemsCommercialView';
import PumpSystemsTechnicalView from './ts/components/application/PumpSystems/subPages/PumpSystemsTechnicalView';
import Layout from './ts/components/layout/Layout';
import Settings from './ts/components/application/Settings';
import FanSystems from './ts/components/application/FanSystems/FanSystems';
import FanSystemBasicView from './ts/components/application/FanSystems/subPages/FanSystemBasicView';
import FanSystemCommercialView from './ts/components/application/FanSystems/subPages/FanSystemsCommercialView';
import CookieNotice from './ts/components/application/Cookie/CookieNotice';

const routes = (
    <Routes>
        <Route path='/*' element={<Layout><Outlet /></Layout>}>
            <Route path='*' element={<Home />} />
            <Route path='cookienotice' element={<CookieNotice />} />
            <Route path='settings' element={<Settings />} />
            <Route path='pump-systems' element={<PumpSystems />}>
                <Route path='*' element={<PumpSystemsBasicView />} />
                <Route path='basic' element={<PumpSystemsBasicView />} />
                <Route path='technical' element={<PumpSystemsTechnicalView />} />
                <Route path='commercial' element={<PumpSystemsCommercialView />} />
            </Route>
            <Route path='fan-systems' element={<FanSystems />}>
                <Route path='*' element={<FanSystemBasicView />} />
                <Route path='basic' element={<FanSystemBasicView />} />
                <Route path='commercial' element={<FanSystemCommercialView />} />
            </Route>
        </Route>
    </Routes>
);

export default routes;
