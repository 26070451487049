import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CHART_INITIAL_STATE } from '../constants/ChartInitialState';
import { EnergyPerformanceChartTabData, EnergySavingsChartTabData, LifetimeCostsChartData, LifetimeCostsChartTabData } from '../models';
import { PowerlossCalculationChartData } from '../models';
import { EnergySavingsChartData } from '../models';
import { EnergyPerformanceChartData } from '../models';
import { EnergySavingsChartTabParams } from '../models/chart/EnergySavingsParams';
import { EnergyPerformanceChartTabParams } from '../models/chart/EnergyPerformanceParams';
import { LifetimeCostsChartTabParams } from '../models/chart/LifetimeCostsChartTabParams';
import { PowerlossCalculationChartTabParams } from '../models/chart/PowerlossCalculationChartTabParams';

export const fanSystemChartSlice = createSlice({
    name: 'fanSystemChart',
    initialState: CHART_INITIAL_STATE,
    reducers: {
        setFanEnergySavingsChartData: (state, action: PayloadAction<EnergySavingsChartData>) => {
            state.energySavingsChartData = action.payload;
        },
        setFanEnergySavingsChartTabData: (state, action: PayloadAction<EnergySavingsChartTabData>) => {
            state.energySavingsChartTabData = action.payload;
        },
        setFanEnergySavingsChartTabParams: (state, action: PayloadAction<EnergySavingsChartTabParams>) => {
            state.energySavingsChartTabParams = action.payload;
        },
        setFanEnergyPerformanceChartData: (state, action: PayloadAction<EnergyPerformanceChartData>) => {
            state.energyPerformanceChartData = action.payload;
        },
        setFanEnergyPerformanceChartTabData: (state, action: PayloadAction<EnergyPerformanceChartTabData>) => {
            state.energyPerformanceChartTabData = action.payload;
        },
        setFanEnergyPerformanceChartTabParams: (state, action: PayloadAction<EnergyPerformanceChartTabParams>) => {
            state.energyPerformanceChartTabParams = action.payload;
        },
        setFanLifetimeCostsChartData: (state, action: PayloadAction<LifetimeCostsChartData>) => {
            state.lifetimeCostsChartData = action.payload;
        },
        setFanLifetimeCostsChartTabData: (state, action: PayloadAction<LifetimeCostsChartTabData>) => {
            state.lifetimeCostsChartTabData = action.payload;
        },
        setFanLifetimeCostsChartTabParams: (state, action: PayloadAction<LifetimeCostsChartTabParams>) => {
            state.lifetimeCostsChartTabParams = action.payload;
        },
        setFanPowerlossCalculationChartData: (state, action: PayloadAction<PowerlossCalculationChartData>) => {
            state.powerlossCalculationChartData = action.payload;
        },
        setFanChartDataLoading: (state, action: PayloadAction<boolean>) => {
            state.chartDataLoading = action.payload;
        },
        setFanPowerLossCalculationChartTabParams: (state, action: PayloadAction<PowerlossCalculationChartTabParams>) => {
            state.powerlossCalculationChartTabParams = action.payload;
        },
        setFanSelectedComparisonCardTabId: (state, action: PayloadAction<string>) => {
            state.selectedComparisonCardTabId = action.payload;
        }
    }
});

export const {
    setFanChartDataLoading,
    setFanEnergyPerformanceChartData,
    setFanEnergyPerformanceChartTabData,
    setFanEnergyPerformanceChartTabParams,
    setFanEnergySavingsChartData,
    setFanEnergySavingsChartTabData,
    setFanEnergySavingsChartTabParams,
    setFanLifetimeCostsChartData,
    setFanLifetimeCostsChartTabData,
    setFanLifetimeCostsChartTabParams,
    setFanPowerLossCalculationChartTabParams,
    setFanPowerlossCalculationChartData,
    setFanSelectedComparisonCardTabId
} = fanSystemChartSlice.actions;
export const fanSystemChartReducer = fanSystemChartSlice.reducer;
