import { ApplicationType, SystemType } from '../types';
import {
    setFanAlternativeSystemAdditionalInvestmentData,
    setFanAlternativeSystemCommercialData,
    setFanAlternativeSystemGovernmentFundingData,
    setFanAlternativeSystemInvestmentCosts,
    setFanAlternativeSystemLoading,
    setFanReferenceSystemAdditionalInvestmentData,
    setFanReferenceSystemCommercialData,
    setFanReferenceSystemGovernmentFundingData,
    setFanReferenceSystemInvestmentCosts,
    setFanReferenceSystemLoading,
    setPumpAlternativeSystemAdditionalInvestmentData,
    setPumpAlternativeSystemCommercialData,
    setPumpAlternativeSystemGovernmentFundingData,
    setPumpAlternativeSystemInvestmentCosts,
    setPumpAlternativeSystemLoading,
    setPumpReferenceSystemAdditionalInvestmentData,
    setPumpReferenceSystemCommercialData,
    setPumpReferenceSystemGovernmentFundingData,
    setPumpReferenceSystemInvestmentCosts,
    setPumpReferenceSystemLoading
} from '../store';
import { getActionCreatorForCondition } from './Utilities';

export const getSystemActions = (applicationType: ApplicationType, systemType: SystemType) => {
    const isPumpApplication = applicationType === 'pump';
    const isReferenceSystem = systemType === 'reference';

    const referenceSystemInvestmentCostHandler = getActionCreatorForCondition(
        isPumpApplication,
        setPumpReferenceSystemInvestmentCosts,
        setFanReferenceSystemInvestmentCosts
    );

    const alternativeSystemInvestmentCostHandler = getActionCreatorForCondition(
        isPumpApplication,
        setPumpAlternativeSystemInvestmentCosts,
        setFanAlternativeSystemInvestmentCosts
    );

    const systemInvestmentCostHandler = getActionCreatorForCondition(
        isReferenceSystem,
        referenceSystemInvestmentCostHandler,
        alternativeSystemInvestmentCostHandler
    );

    const referenceCommercialDataHandler = getActionCreatorForCondition(
        isPumpApplication,
        setPumpReferenceSystemCommercialData,
        setFanReferenceSystemCommercialData
    );

    const alternativeCommercialDataHandler = getActionCreatorForCondition(
        isPumpApplication,
        setPumpAlternativeSystemCommercialData,
        setFanAlternativeSystemCommercialData
    );

    const commercialDataHandler = getActionCreatorForCondition(
        isReferenceSystem,
        referenceCommercialDataHandler,
        alternativeCommercialDataHandler
    );

    const referenceAdditionalInvestmentHandler = getActionCreatorForCondition(
        isPumpApplication,
        setPumpReferenceSystemAdditionalInvestmentData,
        setFanReferenceSystemAdditionalInvestmentData
    );

    const alternativeAdditionalInvestmentHandler = getActionCreatorForCondition(
        isPumpApplication,
        setPumpAlternativeSystemAdditionalInvestmentData,
        setFanAlternativeSystemAdditionalInvestmentData
    );

    const additionalInvestmentHandler = getActionCreatorForCondition(
        isReferenceSystem,
        referenceAdditionalInvestmentHandler,
        alternativeAdditionalInvestmentHandler
    );

    const referenceGovernmentFundingDataHandler = getActionCreatorForCondition(
        isPumpApplication,
        setPumpReferenceSystemGovernmentFundingData,
        setFanReferenceSystemGovernmentFundingData
    );

    const alternativeGovernmentFundingDataHandler = getActionCreatorForCondition(
        isPumpApplication,
        setPumpAlternativeSystemGovernmentFundingData,
        setFanAlternativeSystemGovernmentFundingData
    );

    const governmentFundingDataHandler = getActionCreatorForCondition(
        isReferenceSystem,
        referenceGovernmentFundingDataHandler,
        alternativeGovernmentFundingDataHandler
    );

    const referenceSystemLoadingHandler = getActionCreatorForCondition(
        isPumpApplication,
        setPumpReferenceSystemLoading,
        setFanReferenceSystemLoading
    );

    const alternativeSystemLoadingHandler = getActionCreatorForCondition(
        isPumpApplication,
        setPumpAlternativeSystemLoading,
        setFanAlternativeSystemLoading
    );

    const systemLoadingHandler = getActionCreatorForCondition(
        isReferenceSystem,
        referenceSystemLoadingHandler,
        alternativeSystemLoadingHandler
    );

    return {
        additionalInvestmentHandler,
        commercialDataHandler,
        governmentFundingDataHandler,
        systemInvestmentCostHandler,
        systemLoadingHandler
    };
};
