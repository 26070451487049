import React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/common';
import { removeSnackbar } from '../../store';
import Snackbar from '../common/Snackbar';

const Snackbars = () => {
    const dispatch = useAppDispatch();
    const snackbars = useAppSelector(state => state.snackbar.snackbars);

    const handleDisappear = (id: string | number) => {
        dispatch(removeSnackbar(id));
    };

    return (
        <div className='snackbars'>
            {snackbars.map(x => <Snackbar key={x.id} {...x} onDisappear={() => handleDisappear(x.id)} />)}
        </div>
    );
};

export default Snackbars;
