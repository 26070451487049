export enum ComponentType {
    ControlModeThrottle,
    ControlModeBypass,
    ControlModeConverter,
    ConverterTypeSinamics,
    ConverterTypeThirdParty,
    MotorTypeStandard,
    MotorTypeVsdStandard,
    MotorTypeVsdConverterDuty,
    MotorTypeThirdParty,
    MotorTypeExistingPoleChanging,
    SwitchgearTypeSirius,
    SwitchgearTypeThirdParty
}
