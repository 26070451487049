import React, { useCallback, useEffect, useMemo } from 'react';
import { TRANSLATIONS } from '../../../constants';
import { ApplicationType } from '../../../types';
import { useAppDispatch, useAppSelector, useTranslate } from '../../../hooks/common';
import Box from '../../common/Box';
import BoxTabSwitcher, { BoxTabSwitcherItem } from '../../common/BoxTabSwitcher';
import { IconType } from '../../common/Icon';
import EnergyPerformanceTab from './EnergyPerformanceTab';
import EnergySavingsTab from './EnergySavingsTab';
import LifetimeCostsTab from './LifetimeCostsTab';
import PowerlossCalculationTab from './PowerLossCalculationTab';
import Loader from '../../common/Loader';
import { useComparisonCard } from '../../../hooks/application/ComparisonCard';

export interface ComparisonCardProps {
    applicationType: ApplicationType;
    systemsLoading: boolean;
}

const ComparisonCard = ({ applicationType, systemsLoading }: ComparisonCardProps) => {
    const dispatch = useAppDispatch();
    const { translate } = useTranslate();
    const dark = useAppSelector(state => state.layout.darkMode);
    const isPumpSystem = applicationType === 'pump';
    const systemState = useAppSelector(state => isPumpSystem ? state.pumpSystem : state.fanSystem);
    const { chartDataLoading, selectedComparisonCardTabId, selectedComparisonCardTabIdHandler } = useComparisonCard({ applicationType, systemsLoading });
    const isPowerLossCalculationVisible = systemState.alternativeSystem?.iesSystemData || systemState.referenceSystem?.iesSystemData;

    const powerLossCalculation = useMemo(() => isPowerLossCalculationVisible
        ? [{
            icon: IconType.BarChart,
            id: 'power-loss-calculation',
            label: translate(TRANSLATIONS.generated['System.PowerLossCalculation']),
            key: '2'
        }]
        : [],
        [isPowerLossCalculationVisible, translate]
    );

    const powerLossCalculationTab = useMemo(() => isPowerLossCalculationVisible
        ? [{
            id: 'power-loss-calculation',
            content: <PowerlossCalculationTab applicationType={applicationType} />
        }]
        : [],
        [applicationType, isPowerLossCalculationVisible]
    );

    const comparisonTabs = useMemo((): BoxTabSwitcherItem[] => [
        {
            icon: IconType.LineChart,
            id: 'energy-savings',
            label: translate(TRANSLATIONS.generated['Common.EnergySavings']),
            key: '1'
        },
        ...powerLossCalculation,
        {
            icon: IconType.Calendar,
            id: 'lifetime-costs',
            label: translate(TRANSLATIONS.generated['System.LifetimeCosts']),
            key: '3'
        },
        {
            icon: IconType.Optimize,
            id: 'energy-performance',
            label: translate(TRANSLATIONS.generated['System.EnergyPerformance']),
            key: '4'
        }
    ], [powerLossCalculation, translate]);

    const tabContents = [
        { id: 'energy-savings', content: <EnergySavingsTab applicationType={applicationType} /> },
        { id: 'lifetime-costs', content: <LifetimeCostsTab applicationType={applicationType} /> },
        ...powerLossCalculationTab,
        { id: 'energy-performance', content: <EnergyPerformanceTab alternativeSystemInvestmentCosts={systemState.alternativeSystemInvestmentCosts ?? 0} applicationType={applicationType} /> }
    ];


    const handleSelectedComparisonTabChange = useCallback((selectedTabId: string) => {
        dispatch(selectedComparisonCardTabIdHandler(selectedTabId));
    }, [dispatch, selectedComparisonCardTabIdHandler]);


    useEffect(() => {
        if (!comparisonTabs.find(x => x.id === selectedComparisonCardTabId)) {
            dispatch(selectedComparisonCardTabIdHandler(comparisonTabs[0].id));
        }
    }, [comparisonTabs, dispatch, selectedComparisonCardTabId, selectedComparisonCardTabIdHandler]);

    return (
        <Box dark={dark}>
            <BoxTabSwitcher
                dark={dark}
                items={comparisonTabs}
                key='comparison-card-tab-switcher'
                onSelect={x => handleSelectedComparisonTabChange(comparisonTabs.find(y => y.id == x)?.id ?? comparisonTabs[0].id)}
                selectedId={selectedComparisonCardTabId}
            />
            <Loader loading={chartDataLoading} marginBottom={32} marginLeft={24} marginRight={24}>
                <div className='comparison-card-content'>
                    {tabContents.find(x => x.id === selectedComparisonCardTabId)?.content}
                </div>
            </Loader >
        </Box>
    );
};

export default ComparisonCard;
