import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Currency, RegionSettings } from '../models';
import { NumericFormatProps } from 'react-number-format';
import { NUMBER_FORMAT_OPTIONS } from '../constants/NumberFormatOptions';

export interface RegionSettingsState {
    regionSettings?: RegionSettings;
    defaultCurrency?: Currency;
    countryCodeSet: boolean;
    numberFormatOptions: NumericFormatProps;
    isInitialized: boolean;
}

const initialState: RegionSettingsState = {
    countryCodeSet: false,
    numberFormatOptions: NUMBER_FORMAT_OPTIONS,
    isInitialized: false
};

export const regionSettingsSlice = createSlice({
    name: 'regionSettings',
    initialState,
    reducers: {
        setCountryCodeSet: (state, action: PayloadAction<boolean>) => {
            state.countryCodeSet = action.payload;
        },
        setRegionSettings: (state, action: PayloadAction<RegionSettings>) => {
            state.regionSettings = action.payload;
        },
        addRegionSetting: (state, action: PayloadAction<Partial<RegionSettings>>) => {
            state.regionSettings = { ...state.regionSettings, ...action.payload };
        },
        setDefaultCurrency: (state, action: PayloadAction<Currency | undefined>) => {
            state.defaultCurrency = action.payload;
        },
        setNumberFormatOptions: (state, action: PayloadAction<NumericFormatProps>) => {
            state.numberFormatOptions = action.payload;
        },
        setRegionSettingsInitialized: (state, action: PayloadAction<boolean>) => {
            state.isInitialized = action.payload;
        }
    }
});

export const { setRegionSettings, addRegionSetting, setDefaultCurrency, setCountryCodeSet, setNumberFormatOptions, setRegionSettingsInitialized } = regionSettingsSlice.actions;
export const regionSettingsReducer = regionSettingsSlice.reducer;
