import { useCallback, useMemo } from 'react';
import { AdditionalInvestmentData, AdditionalInvestmentInputData } from '../../models';
import { TRANSLATIONS } from '../../constants';
import { priceExchanger } from '../../services';
import { useAppSelector, useTranslate } from '../common';

interface AdditionalInvestmentsParameters {
    additionalInvestmentItems: AdditionalInvestmentInputData[];
    onAdditionalInvestmentDataChange: (value: Partial<AdditionalInvestmentData>) => void;
}

export const useAdditionalInvestments = ({ onAdditionalInvestmentDataChange, additionalInvestmentItems }: AdditionalInvestmentsParameters) => {
    const { translate } = useTranslate();
    const defaultInvestmentLabel = translate(TRANSLATIONS.generated['System.AdditionalInvestment']);
    const { regionSettings, defaultCurrency } = useAppSelector(x => x.regionSettings);

    const handleCreateItem = useCallback(() => {
        if (additionalInvestmentItems.length < 3) {
            onAdditionalInvestmentDataChange({
                additionalInvestmentItems: [...additionalInvestmentItems, { label: defaultInvestmentLabel, value: '' }]
            });
        }
    }, [additionalInvestmentItems, onAdditionalInvestmentDataChange, defaultInvestmentLabel]);

    const handleDeleteItemByIndex = useCallback((index: number) => {
        const newArray = [...additionalInvestmentItems];
        newArray.splice(index, 1);

        onAdditionalInvestmentDataChange({
            additionalInvestmentItems: newArray
        });
    }, [additionalInvestmentItems, onAdditionalInvestmentDataChange]);

    const handleUpdateItemValue = useCallback((index: number, newValue: string) => {
        const newArray = [...additionalInvestmentItems];
        newArray[index] = {
            label: newArray[index].label,
            value: priceExchanger(parseFloat(newValue), regionSettings?.currency, defaultCurrency)?.toString() ?? '0'
        };

        onAdditionalInvestmentDataChange({
            additionalInvestmentItems: newArray
        });
    }, [additionalInvestmentItems, defaultCurrency, onAdditionalInvestmentDataChange, regionSettings?.currency]);

    const handleUpdateItemLabel = useCallback((index: number, newLabel: string) => {
        const newArray = [...additionalInvestmentItems];
        newArray[index] = {
            label: newLabel,
            value: newArray[index].value
        };
        onAdditionalInvestmentDataChange({
            additionalInvestmentItems: newArray
        });
    }, [additionalInvestmentItems, onAdditionalInvestmentDataChange]);

    const handleEnableEditClick = useCallback(() => {
        handleUpdateItemLabel(0, defaultInvestmentLabel);
        onAdditionalInvestmentDataChange({
            enableEdit: true
        });
    }, [defaultInvestmentLabel, handleUpdateItemLabel, onAdditionalInvestmentDataChange]);

    const handleIsModalOpenChange = useCallback((value: boolean) => {
        onAdditionalInvestmentDataChange({
            isModalOpen: value
        });
    }, [onAdditionalInvestmentDataChange]);

    const exchangedAdditionalInvestmentItems: AdditionalInvestmentInputData[] = useMemo(() =>
        additionalInvestmentItems.map(x => ({ label: x.label, value: priceExchanger(Number(x.value), defaultCurrency, regionSettings?.currency)?.toString() ?? '0' })),
    [additionalInvestmentItems, defaultCurrency, regionSettings?.currency]);

    return {
        handleCreateItem,
        handleDeleteItemByIndex,
        handleEnableEditClick,
        handleIsModalOpenChange,
        handleUpdateItemLabel,
        handleUpdateItemValue,
        exchangedAdditionalInvestmentItems
    };
};
