import React, { useMemo } from 'react';
import { numericFormatter } from 'react-number-format';
import { LifetimeCostsChartTabData, LifetimeCostsChartData } from '../../../models';
import { ApplicationType } from '../../../types';
import { TEXT_INPUT_DEBOUNCE_TIME, TRANSLATIONS } from '../../../constants';
import { getYearValidationMessage, isYear } from '../../../services';
import { useCurrency, useLifetimeCosts } from '../../../hooks';
import { useAppSelector, useTranslate, useValidate } from '../../../hooks/common';
import Checkbox from '../../common/Checkbox';
import TextInput from '../../common/TextInput';
import InputValidation from '../../common/InputValidation';
import ComparisonCardTab, { Feedback } from './ComparisonCardTab';
import LifetimeCostsChart from '../Charts/LifetimeCostsChart/LifetimeCostsChart';
import ComparisonCardTotalCostsOfOwnershipInfo from './ModalInfo/ComparisonCardTotalCostsOfOwnershipInfo';

export interface LifetimeCostsTabProps {
    applicationType: ApplicationType;
}

const LifetimeCostsTab = ({ applicationType }: LifetimeCostsTabProps) => {
    const { translate, dynamicTranslate } = useTranslate();
    const dark = useAppSelector(x => x.layout.darkMode);
    const { numberFormatOptions } = useAppSelector(x => x.regionSettings);
    const {
        handleChangeNumberOfYears,
        lifetimeCostsChartData,
        lifetimeCostsChartTabData,
        handleShowSavings,
        chartOptionParameters,
        commercialFeedback,
        isCommercialFeedbackPositive
    } = useLifetimeCosts({ applicationType });
    const { currencySymbol } = useCurrency();

    const feedback: Feedback = useMemo(() => ({
        feedbackTitle: translate(TRANSLATIONS.generated['System.MostEconomical']),
        positiveFeedback: isCommercialFeedbackPositive,
        feedbackMessage: commercialFeedback
    }), [translate, commercialFeedback, isCommercialFeedbackPositive]);

    const getYearTranslation = (years: string, translationForOneYear: string, translationForXYears: string): string =>
        years === '1'
            ? translationForOneYear
            : translationForXYears.replace('{{x}}', years);

    const showAmortizationTime = lifetimeCostsChartData ? lifetimeCostsChartData.intersectionPoints.length !== 0 : false;

    const {
        value: numberOfYearsInputValue,
        setValue: setNumberOfYearsInputValue
    } = useValidate(chartOptionParameters.lifetimeNumberOfYearsString, isYear, handleChangeNumberOfYears, TEXT_INPUT_DEBOUNCE_TIME);

    const getAlternativeSystemAttributes = (charData: LifetimeCostsChartTabData | undefined, lifetimeCostsChartData: LifetimeCostsChartData) => {
        if (charData && charData?.alternativeEnergyCostLifetime < charData?.referenceEnergyCostLifetime) {
            return [
                {
                    label: getYearTranslation(
                        chartOptionParameters.lifetimeNumberOfYearsString,
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCost']),
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCostForXYears'])
                    ),
                    value: numericFormatter(charData?.alternativeEnergyCostLifetime.toString() ?? '', numberFormatOptions),
                    unit: currencySymbol
                },
                {
                    label: translate(TRANSLATIONS.generated['Common.RequiredInvestmentCosts']),
                    value: numericFormatter((charData?.alternativeInvestmentCost ?? 0).toString(), numberFormatOptions),
                    unit: currencySymbol
                },
                {
                    label: getYearTranslation(
                        chartOptionParameters.lifetimeNumberOfYearsString,
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCostSavingIn1Year']),
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCostSavingInXYears'])
                    ),
                    value: numericFormatter(((charData?.referenceEnergyCostLifetime ?? 0) - (charData?.alternativeEnergyCostLifetime ?? 0)).toString(), numberFormatOptions),
                    unit: currencySymbol
                },
                {
                    label: translate(TRANSLATIONS.generated['System.InvestmentDifference']),
                    value: numericFormatter(((charData?.alternativeInvestmentCost ?? 0) - (charData?.referenceInvestmentCost ?? 0)).toString(), numberFormatOptions),
                    unit: currencySymbol
                },
                ...showAmortizationTime ? [{
                    label: translate(TRANSLATIONS.generated['System.AmortizationTime']),
                    value: numericFormatter((lifetimeCostsChartTabData?.amortizationResult?.amortization ?? 0).toString(), numberFormatOptions),
                    unit: dynamicTranslate(TRANSLATIONS.generated, lifetimeCostsChartTabData?.amortizationResult.unit)
                }] : [],
                {
                    label: getYearTranslation(
                        chartOptionParameters.lifetimeNumberOfYearsString,
                        translate(TRANSLATIONS.generated['Project.TotalSavingsIn1Year']),
                        translate(TRANSLATIONS.generated['Project.TotalSavingsIn'])
                    ),
                    value: numericFormatter(lifetimeCostsChartData?.barChartData[1].savings.toString(), numberFormatOptions),
                    unit: currencySymbol
                }
            ];
        } else {
            return [
                {
                    label: getYearTranslation(
                        chartOptionParameters.lifetimeNumberOfYearsString,
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCost']),
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCostForXYears'])
                    ),
                    value: numericFormatter(charData?.alternativeEnergyCostLifetime.toString() ?? '', numberFormatOptions),
                    unit: currencySymbol
                },
                {
                    label: translate(TRANSLATIONS.generated['Common.RequiredInvestmentCosts']),
                    value: numericFormatter((charData?.alternativeInvestmentCost ?? 0).toString(), numberFormatOptions),
                    unit: currencySymbol
                }
            ];
        }
    };

    const getReferenceSystemAttributes = (chartData: LifetimeCostsChartTabData | undefined, lifetimeCostsChartData: LifetimeCostsChartData) => {
        if (chartData && chartData?.referenceEnergyCostLifetime < chartData?.alternativeEnergyCostLifetime) {
            return [
                {
                    label: getYearTranslation(
                        chartOptionParameters.lifetimeNumberOfYearsString,
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCost']),
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCostForXYears'])
                    ),
                    value: numericFormatter(chartData?.referenceEnergyCostLifetime.toString() ?? '', numberFormatOptions),
                    unit: currencySymbol
                },
                {
                    label: translate(TRANSLATIONS.generated['Common.RequiredInvestmentCosts']),
                    value: numericFormatter((chartData?.referenceInvestmentCost ?? 0).toString() ?? '', numberFormatOptions),
                    unit: currencySymbol
                },
                {
                    label: getYearTranslation(
                        chartOptionParameters.lifetimeNumberOfYearsString,
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCostSavingIn1Year']),
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCostSavingInXYears'])
                    ),
                    value: numericFormatter(Math.abs((chartData?.referenceEnergyCostLifetime ?? 0) - (chartData?.alternativeEnergyCostLifetime ?? 0)).toString(), numberFormatOptions),
                    unit: currencySymbol
                },
                {
                    label: translate(TRANSLATIONS.generated['System.InvestmentDifference']),
                    value: numericFormatter(Math.abs((chartData?.alternativeInvestmentCost ?? 0) - (chartData?.referenceInvestmentCost ?? 0)).toString(), numberFormatOptions),
                    unit: currencySymbol
                }
            ];
        } else {
            return [
                {
                    label: getYearTranslation(
                        chartOptionParameters.lifetimeNumberOfYearsString,
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCost']),
                        translate(TRANSLATIONS.generated['System.ExpectedEnergyCostForXYears'])
                    ),
                    value: numericFormatter(chartData?.referenceEnergyCostLifetime.toString() ?? '', numberFormatOptions),
                    unit: currencySymbol
                },
                {
                    label: translate(TRANSLATIONS.generated['Common.RequiredInvestmentCosts']),
                    value: numericFormatter((chartData?.referenceInvestmentCost ?? 0).toString() ?? '', numberFormatOptions),
                    unit: currencySymbol
                }
            ];
        }
    };

    return lifetimeCostsChartData ?
        <ComparisonCardTab
            applicationType={applicationType}
            chart={
                <LifetimeCostsChart
                    data={lifetimeCostsChartData}
                    showFinancialSavingPotential={chartOptionParameters.showSavings}
                />
            }
            checkboxes={[
                <Checkbox
                    key='financial-savings'
                    label={translate(TRANSLATIONS.generated['System.FinancialSavingPotential'])}
                    checked={chartOptionParameters.showSavings}
                    onChange={handleShowSavings}
                    dark={dark}
                />
            ]}
            inputs={[
                <InputValidation
                    key='years-of-operation'
                    valid={isYear(numberOfYearsInputValue)}
                    message={getYearValidationMessage(translate)}
                    dark={dark}
                >
                    <TextInput
                        label={translate(TRANSLATIONS.generated['Common.Lifetime'])}
                        name='yearsOfOperation'
                        value={numberOfYearsInputValue}
                        onChange={setNumberOfYearsInputValue}
                        dark={dark}
                        numberFormatOptions={{
                            suffix: numberOfYearsInputValue === '1'
                                ? ` ${translate(TRANSLATIONS.generated['System.Year'])}`
                                : ` ${translate(TRANSLATIONS.generated['System.Years'])}`
                        }}
                        error={!isYear(numberOfYearsInputValue)}
                    />
                </InputValidation>
            ]}
            alternativeSystemAttributes={getAlternativeSystemAttributes(lifetimeCostsChartTabData, lifetimeCostsChartData)}
            referenceSystemAttributes={getReferenceSystemAttributes(lifetimeCostsChartTabData, lifetimeCostsChartData)}
            modalContent={<ComparisonCardTotalCostsOfOwnershipInfo />}
            feedback={feedback}
        />
        : <></>;
};

export default LifetimeCostsTab;
