import { configureStore } from '@reduxjs/toolkit';
import {
    pumpSystemChartReducer,
    fanSystemReducer,
    layoutReducer,
    pumpSystemReducer,
    pumpSystemsCommercialDataReducer,
    regionSettingsReducer,
    snackbarReducer,
    translationReducer,
    fanSystemsCommercialDataReducer,
    fanSystemChartReducer,
    systemReducer
} from '.';

export const store = configureStore({
    reducer: {
        fanSystem: fanSystemReducer,
        fanSystemChart: fanSystemChartReducer,
        fanSystemsCommercialData: fanSystemsCommercialDataReducer,
        layout: layoutReducer,
        pumpSystem: pumpSystemReducer,
        pumpSystemChart: pumpSystemChartReducer,
        pumpSystemsCommercialData: pumpSystemsCommercialDataReducer,
        regionSettings: regionSettingsReducer,
        snackbar: snackbarReducer,
        system: systemReducer,
        translation: translationReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
