import React, { KeyboardEvent, useState, useRef, ReactNode } from 'react';
import Icon, { IconType } from './Icon';

export interface ImageDropdownData {
    description: string;
    disabled?: boolean;
    descriptionImage?: ReactNode;
    image: ReactNode;
    key: number;
    title: string;
}

export interface ImageDropdownProps {
    disabled?: boolean;
    getAvailableSpace: () => number;
    onSelect: (value: ImageDropdownData) => void;
    value: ImageDropdownData;
    values: ImageDropdownData[];
    dark?: boolean;
}

const ImageDropdown = ({ disabled, getAvailableSpace, onSelect, value, values, dark }: ImageDropdownProps) => {
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const focusTimeoutRef = useRef(0);
    const gotFocusRef = useRef(false);
    const keyboardTimeoutRef = useRef(0);
    const searchTextRef = useRef('');
    const valuesRef = useRef<HTMLDivElement>(null);
    const width = `${getAvailableSpace()}px`;

    const openImageDropdown = () => {
        scrollTo(0);
        setOpen(true);
    };

    const closeImageDropdown = () => {
        setSelectedIndex(-1);
        setOpen(false);
    };

    const handleClick = () => {
        if (!gotFocusRef.current && !disabled) {
            open ? closeImageDropdown() : openImageDropdown();
        } else {
            clearTimeout(focusTimeoutRef.current);
            gotFocusRef.current = false;
        }
    };

    const handleBlur = () => {
        closeImageDropdown();
    };

    const scrollTo = (top: number) => {
        if (valuesRef.current) {
            valuesRef.current.scrollTop = top;
        }
    };

    const scrollToIndexTop = (index: number) => {
        if (valuesRef.current) {
            const selectValue = getSelectValue(index);

            if (index === values.length - 2 || selectValue.offsetTop <= valuesRef.current.scrollTop) {
                valuesRef.current.scrollTop = selectValue.offsetTop;
            }
        }
    };

    const getSelectValue = (index: number) => valuesRef.current?.getElementsByClassName('image-dropdown-value')[index] as HTMLDivElement;

    const scrollToIndexBottom = (index: number) => {
        if (valuesRef.current) {
            const selectValue = getSelectValue(index);

            if (index === 0 || selectValue.offsetTop + selectValue.offsetHeight >= valuesRef.current.scrollTop + valuesRef.current.offsetHeight) {
                valuesRef.current.scrollTop = selectValue.offsetTop + selectValue.offsetHeight - valuesRef.current.offsetHeight;
            }
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();

        switch (event.key) {
            case 'ArrowUp':
                event.preventDefault();

                if (open) {
                    const index = selectedIndex <= -1 ? values.length - 1 : selectedIndex - 1;

                    const reversedItems = [...values.slice(0, index + 1)].reverse();
                    const previousItem = reversedItems.find(item => !item?.disabled);

                    if (previousItem) {
                        const previousAvailableIndex = values.indexOf(previousItem);
                        setSelectedIndex(previousAvailableIndex);
                        scrollToIndexBottom(Math.min(previousAvailableIndex, values.length - 1));
                    } else {
                        const nextAvailableIndex = values.findIndex((item, i) => i <= index && !item?.disabled);
                        setSelectedIndex(nextAvailableIndex);
                        scrollToIndexTop(Math.max(nextAvailableIndex - 1, 0));
                    }
                }

                break;
            case 'ArrowDown':
                event.preventDefault();

                if (open) {
                    const index = selectedIndex >= values.length - 1 ? -1 : selectedIndex + 1;

                    if (values[index]?.disabled) {
                        const nextAvailableIndex = values.findIndex((item, i) => i > index && !item?.disabled);
                        setSelectedIndex(nextAvailableIndex);
                        scrollToIndexBottom(Math.min(nextAvailableIndex + 1, values.length - 1));
                    } else {
                        setSelectedIndex(index);
                        scrollToIndexTop(Math.max(index - 1, 0));
                    }
                }

                break;
            case 'Enter':
                if (open) {
                    if (0 <= selectedIndex && selectedIndex < values.length) {
                        onSelect(values[selectedIndex]);
                    }
                    closeImageDropdown();
                } else {
                    openImageDropdown();
                }

                break;
            case ' ':
                event.preventDefault();

                if (!open) {
                    openImageDropdown();
                }

                break;
            case 'Escape':
                if (open) {
                    closeImageDropdown();
                }

                break;
        }
    };

    const handleValueMouseEnter = (index: number) => {
        setSelectedIndex(index);
    };

    const handleValueClick = (selectedValue: ImageDropdownData, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();

        if (!selectedValue.disabled) {
            onSelect(selectedValue);
        }
        closeImageDropdown();
    };

    const handleKeyPress = () => {
        clearTimeout(keyboardTimeoutRef.current);

        if (selectedIndex < 0) {
            setSelectedIndex(selectedIndex);
            keyboardTimeoutRef.current = window.setTimeout(() => searchTextRef.current = '', 1000);
        }
    };

    return (
        <div
            className={`image-dropdown ${open ? 'open' : ''} ${dark ? 'dark' : ''} ${disabled ? 'disabled' : ''}`}
            onBlur={handleBlur}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            tabIndex={-1}
            onKeyPress={handleKeyPress}
        >
            <div className={'image-dropdown-opener'}>
                <div className={`image-dropdown-value ${disabled ? 'disabled' : ''}`}>
                    {value.image}
                    {!disabled && (
                        <div className='image-dropdown-arrow'>
                            <Icon type={IconType.DropDown} />
                        </div>
                    )}
                </div>
            </div>
            <div ref={valuesRef} style={{ width: width }} className={`image-dropdown-values ${open ? `image-dropdown-values-${Math.min(values.length, 3)}` : ''}`}>
                {values.map((x, i) => (
                    <div
                        className={`image-dropdown-value ${selectedIndex === i && !x.disabled ? 'selected' : ''} ${x.disabled ? 'disabled' : ''}`}
                        key={x.key}
                        onClick={e => handleValueClick(x, e)}
                        onMouseEnter={() => handleValueMouseEnter(i)}
                    >
                        {x.descriptionImage}
                        <div className='image-dropdown-value-text-container'>
                            <div className='image-dropdown-value-title'>{x.title}</div>
                            <div className='image-dropdown-value-description'>{x.description}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImageDropdown;
