import { useAppDispatch, useAppSelector } from '../common';
import { ApplicationType } from '../../types';
import { getApplicationActions } from '../../services/ApplicationActions';

export interface EnergySavingsParameters {
    applicationType: ApplicationType
}

export const useEnergySavings = ({ applicationType }: EnergySavingsParameters) => {
    const dispatch = useAppDispatch();
    const isPumpType = applicationType === 'pump';
    const chartOptionParameters = useAppSelector(state => isPumpType
        ? state.pumpSystemChart.energySavingsChartTabParams : state.fanSystemChart.energySavingsChartTabParams);
    const { energySavingsParamsHandler } = getApplicationActions(applicationType);

    const handleSystem = () => {
        dispatch(energySavingsParamsHandler({ ...chartOptionParameters, system: !chartOptionParameters.system }));
    };

    const handleShowOperationProfile = () => {
        dispatch(energySavingsParamsHandler({ ...chartOptionParameters, showOperationProfile: !chartOptionParameters.showOperationProfile }));
    };
   
    const handleEnergySaving = () => {
        dispatch(energySavingsParamsHandler({ ...chartOptionParameters, energySaving: !chartOptionParameters.energySaving }));
    };

    return {
        chartOptionParameters,
        handleSystem,
        handleShowOperationProfile,
        handleEnergySaving
    };
};
