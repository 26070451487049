import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CHART_INITIAL_STATE } from '../constants/ChartInitialState';
import { EnergyPerformanceChartTabData, EnergySavingsChartTabData, LifetimeCostsChartData, LifetimeCostsChartTabData } from '../models';
import { PowerlossCalculationChartData } from '../models';
import { EnergySavingsChartData } from '../models';
import { EnergyPerformanceChartData } from '../models';
import { EnergySavingsChartTabParams } from '../models/chart/EnergySavingsParams';
import { EnergyPerformanceChartTabParams } from '../models/chart/EnergyPerformanceParams';
import { LifetimeCostsChartTabParams } from '../models/chart/LifetimeCostsChartTabParams';
import { PowerlossCalculationChartTabParams } from '../models/chart/PowerlossCalculationChartTabParams';

export const pumpSystemChartSlice = createSlice({
    name: 'pumpSystemChart',
    initialState: CHART_INITIAL_STATE,
    reducers: {
        setPumpEnergySavingsChartData: (state, action: PayloadAction<EnergySavingsChartData>) => {
            state.energySavingsChartData = action.payload;
        },
        setPumpEnergySavingsChartTabData: (state, action: PayloadAction<EnergySavingsChartTabData>) => {
            state.energySavingsChartTabData = action.payload;
        },
        setPumpEnergySavingsChartTabParams: (state, action: PayloadAction<EnergySavingsChartTabParams>) => {
            state.energySavingsChartTabParams = action.payload;
        },
        setPumpEnergyPerformanceChartData: (state, action: PayloadAction<EnergyPerformanceChartData>) => {
            state.energyPerformanceChartData = action.payload;
        },
        setPumpEnergyPerformanceChartTabData: (state, action: PayloadAction<EnergyPerformanceChartTabData>) => {
            state.energyPerformanceChartTabData = action.payload;
        },
        setPumpEnergyPerformanceChartTabParams: (state, action: PayloadAction<EnergyPerformanceChartTabParams>) => {
            state.energyPerformanceChartTabParams = action.payload;
        },
        setPumpLifetimeCostsChartData: (state, action: PayloadAction<LifetimeCostsChartData>) => {
            state.lifetimeCostsChartData = action.payload;
        },
        setPumpLifetimeCostsChartTabData: (state, action: PayloadAction<LifetimeCostsChartTabData>) => {
            state.lifetimeCostsChartTabData = action.payload;
        },
        setPumpLifetimeCostsChartTabParams: (state, action: PayloadAction<LifetimeCostsChartTabParams>) => {
            state.lifetimeCostsChartTabParams = action.payload;
        },
        setPumpPowerlossCalculationChartData: (state, action: PayloadAction<PowerlossCalculationChartData>) => {
            state.powerlossCalculationChartData = action.payload;
        },
        setPumpChartDataLoading: (state, action: PayloadAction<boolean>) => {
            state.chartDataLoading = action.payload;
        },
        setPumpPowerLossCalculationChartTabParams: (state, action: PayloadAction<PowerlossCalculationChartTabParams>) => {
            state.powerlossCalculationChartTabParams = action.payload;
        },
        setPumpSelectedComparisonCardTabId: (state, action: PayloadAction<string>) => {
            state.selectedComparisonCardTabId = action.payload;
        }
    }
});

export const {
    setPumpChartDataLoading,
    setPumpEnergyPerformanceChartData,
    setPumpEnergyPerformanceChartTabData,
    setPumpEnergyPerformanceChartTabParams,
    setPumpEnergySavingsChartData,
    setPumpEnergySavingsChartTabData,
    setPumpEnergySavingsChartTabParams,
    setPumpLifetimeCostsChartData,
    setPumpLifetimeCostsChartTabData,
    setPumpLifetimeCostsChartTabParams,
    setPumpPowerLossCalculationChartTabParams,
    setPumpPowerlossCalculationChartData,
    setPumpSelectedComparisonCardTabId
} = pumpSystemChartSlice.actions;
export const pumpSystemChartReducer = pumpSystemChartSlice.reducer;
