import React, { useCallback, useMemo, useState } from 'react';
import { CHART_ALTERNATIVE, CHART_ALTERNATIVE_DARK, CHART_REFERENCE, CHART_REFERENCE_DARK, TRANSLATIONS } from '../../../constants';
import { ApplicationType } from '../../../types';
import { getApplicationActions } from '../../../services';
import { useAppDispatch, useAppSelector, useTranslate } from '../../../hooks/common';
import Icon, { IconType } from '../../common/Icon';
import Box from '../../common/Box';
import ColorfulBar from '../../common/ColorfulBar';
import Flex from '../../common/Flex';
import IconButton from '../../common/IconButton';
import Modal from '../../common/Modal';
import Text from '../../common/Text';
import AppearanceAnimationWrapper from '../../common/AppearanceAnimationWrapper';
import ChartItemMarker from '../Charts/ChartElements/ChartItemMarker';

export interface SystemAttribute {
    label: string;
    value: string;
    unit?: string;
    icon?: IconType;
}

export interface AdditionalAttributeBlock {
    attributeBlock: SystemAttribute[];
    attributeBlockLabel: string;
    attributeBlockValue?: string;
    attributeBlockUnit?: string;
    color?: 'reference' | 'alternative';
}

export interface Feedback {
    feedbackTitle: string;
    positiveFeedback: boolean;
    feedbackMessage: string;
}

export interface ComparisonCardTabProps {
    attributeLabel?: string;
    alternativeAttributeLabel?: string;
    alternativeAttributeValue?: string;
    alternativeAttributeUnit?: string;
    alternativeSystemAttributes: SystemAttribute[];
    applicationType: ApplicationType;
    chart: JSX.Element;
    checkboxes?: JSX.Element[];
    inputs?: JSX.Element[];
    feedback?: Feedback;
    referenceAttributeLabel?: string;
    referenceAttributeValue?: string;
    referenceAttributeUnit?: string,
    referenceSystemAttributes: SystemAttribute[];
    modalContent: JSX.Element;
    additionalAttributeBlocks?: AdditionalAttributeBlock[];
}

const ComparisonCardTab = ({ attributeLabel, additionalAttributeBlocks, alternativeAttributeLabel, alternativeAttributeValue, alternativeAttributeUnit, alternativeSystemAttributes, applicationType, chart, checkboxes, feedback, inputs, modalContent, referenceAttributeLabel, referenceAttributeValue, referenceAttributeUnit, referenceSystemAttributes }: ComparisonCardTabProps) => {
    const dark = useAppSelector(x => x.layout.darkMode);
    const dispatch = useAppDispatch();
    const { translate } = useTranslate();
    const [tabInfoModalOpen, setTabInfoModalOpen] = useState(false);
    const isPumpSystem = applicationType === 'pump';
    const fullScreenChart = useAppSelector(x => isPumpSystem ? x.pumpSystem.fullScreenChart : x.fanSystem.fullScreenChart);
    const { fullScreenChartHandler } = getApplicationActions(applicationType);
    const alternativeColor = useMemo(() => dark ? CHART_ALTERNATIVE_DARK : CHART_ALTERNATIVE, [dark]);
    const referenceColor = useMemo(() => dark ? CHART_REFERENCE_DARK : CHART_REFERENCE, [dark]);

    const handleTabInfoClick = useCallback(() => setTabInfoModalOpen(true), []);
    const handleTabInfoClose = useCallback(() => setTabInfoModalOpen(false), []);

    const handleFullScreenClick = useCallback(() => dispatch(fullScreenChartHandler(!fullScreenChart)), [dispatch, fullScreenChart]);

    const renderAttributeList = (attributes: SystemAttribute[]) =>
        attributes.map(attribute =>
            <div key={attribute.label} className='comparison-card-tab-item'>
                <Text type='normal' dark={dark}>{attribute.label}</Text>
                <div>{attribute.value}{attribute.unit ? ` ${attribute.unit}` : ''}{attribute.icon ? ' ' : ''}{attribute.icon && <Icon type={attribute.icon} />}</div>
            </div>
        );

    return (
        <AppearanceAnimationWrapper>
            <div className='comparison-card-tab' >
                <Flex justification='space-between' gap={16} style={{ marginBottom: 24, paddingTop: 16 }}>
                    <Text type='subtitle-very-small' dark={dark}>{translate(TRANSLATIONS.main.visualisation)}</Text>
                    <Flex gap={8}>
                        <IconButton iconType={fullScreenChart ? IconType.FullScreenExit : IconType.FullScreen} dark={dark} size={16} layer={2} onClick={handleFullScreenClick} />
                        <IconButton iconType={IconType.Information} dark={dark} size={16} layer={2} onClick={handleTabInfoClick} />
                    </Flex>
                </Flex>
                {chart}
                {checkboxes && <div className='checkboxes' >{checkboxes}</div>}
                {inputs && <Flex direction='column' alignment='stretch' rowGap={8}>{inputs}</Flex>}
                {attributeLabel && <Text dark={dark} type='subtitle-very-small'>{attributeLabel}</Text>}
                <div className='comparison-card-tab-block'>
                    <Flex alignment='stretch' direction='row' columnGap={6}>
                        <ColorfulBar color='reference' dark={dark} />
                        <div className='system-content' style={{ width: '100%' }}>
                            <div className='comparison-card-tab-item'>
                                <Flex gap={8} alignment={'baseline'}>
                                    <Text type='subtitle-very-small' color='reference' dark={dark} style={{ paddingBottom: 6 }}>{referenceAttributeLabel ?? translate(TRANSLATIONS.generated['System.ReferenceSystem'])}</Text>
                                    <ChartItemMarker type='diamond' size={8} color={referenceColor} />
                                </Flex>
                                {referenceAttributeValue ? (
                                    <Text type='subtitle-very-small' color='reference' dark={dark} style={{ paddingBottom: 6 }}>{referenceAttributeValue} {referenceAttributeUnit}</Text>
                                ) : (<></>)}
                            </div>

                            <div className='system-content-item-list' >
                                {renderAttributeList(referenceSystemAttributes)}
                            </div>
                        </div>
                    </Flex>
                </div>
                <div className='comparison-card-tab-block'>
                    <Flex alignment='stretch' direction='row' columnGap={6}>
                        <ColorfulBar color='alternative' dark={dark} />
                        <div className='system-content' >
                            <div className='comparison-card-tab-item'>
                                <Flex gap={8} alignment={'baseline'}>
                                    <Text type='subtitle-very-small' color='alternative' dark={dark} style={{ paddingBottom: 6 }}>{alternativeAttributeLabel ?? translate(TRANSLATIONS.generated['System.AlternativeSystem'])}</Text>
                                    <ChartItemMarker type='triangle' size={10} color={alternativeColor} />
                                </Flex>
                                {alternativeAttributeValue ? (
                                    <Text type='subtitle-very-small' color='alternative' dark={dark} style={{ paddingBottom: 6 }}>{alternativeAttributeValue} {alternativeAttributeUnit}</Text>
                                ) : (<></>)}
                            </div>
                            <div className='system-content-item-list' >
                                {renderAttributeList(alternativeSystemAttributes)}
                            </div>
                        </div>
                    </Flex>
                </div>
                {additionalAttributeBlocks && additionalAttributeBlocks.map((x, i) => <div key={i} className='comparison-card-tab-block'>
                    <Flex alignment='stretch' direction='row' columnGap={6}>
                        {x.color && <ColorfulBar color={x.color} dark={dark} />}
                        <div className='system-content' style={{ width: '100%' }}>
                            <div className={x.attributeBlockValue ? 'comparison-card-tab-item' : ''}>
                                <Text type='subtitle-very-small' color={x.color} dark={dark} style={{ paddingBottom: 6 }}>{x.attributeBlockLabel ?? ''}</Text>
                                <Text type='subtitle-very-small' color={x.color} dark={dark} style={{ paddingBottom: 6 }}>{x.attributeBlockValue} {x.attributeBlockUnit}</Text>
                            </div>
                            <div className='system-content-item-list' >
                                {renderAttributeList(x.attributeBlock)}
                            </div>
                        </div>
                    </Flex>
                </div>)}
                {feedback && <div className='comparison-card-tab-block' >
                    <Box dark={dark} backgroundColor={feedback.positiveFeedback ? 'green' : 'yellow'} style={{ padding: '16px' }}>
                        <Text type='subtitle-very-small' dark={dark}>{feedback.feedbackTitle}</Text>
                        <Text type='small' dark={dark} style={{ paddingTop: 10, lineHeight: '24px', fontSize: 16 }}>{feedback.feedbackMessage}</Text>
                    </Box>
                </div>}
                <div className='comparison-card-tab-block' >
                    <Text type='subordinate' dark={dark}>
                        {translate(TRANSLATIONS.generated['Disclaimer.Content'])}
                    </Text>
                </div>
                <Modal open={tabInfoModalOpen} allowClickAway dark={dark} movable onClose={handleTabInfoClose} >{modalContent}</Modal>
            </div>
        </AppearanceAnimationWrapper>
    );
};

export default ComparisonCardTab;
