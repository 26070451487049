import React from 'react';
import { TRANSLATIONS } from '../../../../constants';
import { Language } from '../../../../enums';
import { useAppSelector, useTranslate } from '../../../../hooks/common';
import Text from '../../../common/Text';
import { ApplicationType } from '../../../../types';

export interface CommercialViewConfigurationInfoProps {
    applicationType: ApplicationType;
}

const CommercialViewConfigurationInfo = ({ applicationType }: CommercialViewConfigurationInfoProps) => {
    const dark = useAppSelector(state => state.layout.darkMode);
    const language = useAppSelector(state => state.translation.language);
    const { translate } = useTranslate();
    const applicationTypeTitleCase = applicationType === 'pump' ? 'Pump' : 'Fan';

    const infoEnglish = (
        <>
            <ul>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}CommercialControlMode.Content`][0])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}CommercialControlMode.Content`][1])}
                    </Text>
                    <ul>
                        <li>
                            <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                                {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}CommercialControlMode.Content`][2])}
                            </Text>
                        </li>
                    </ul>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}CommercialControlMode.Content`][3])}
                    </Text>
                    <ul>
                        <li>
                            <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                                {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}CommercialControlMode.Content`][4])}
                            </Text>
                        </li>
                    </ul>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}CommercialControlMode.Content`][5])}
                    </Text>
                    <ul>
                        <li>
                            <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                                {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}CommercialControlMode.Content`][6])}
                            </Text>
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    );

    const infoGerman = (
        <>
            <ul>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}CommercialControlMode.Content`][0])}
                        <strong>{translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}CommercialControlMode.Content`][1])}</strong>
                        {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}CommercialControlMode.Content`][2])}
                        <strong>{translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}CommercialControlMode.Content`][3])}</strong>
                        {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}CommercialControlMode.Content`][4])}
                    </Text>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        <strong>{translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}CommercialControlMode.Content`][5])}</strong>
                    </Text>
                    <ul>
                        <li>
                            <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                                {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}CommercialControlMode.Content`][6])}
                            </Text>
                        </li>
                    </ul>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        <strong> {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}CommercialControlMode.Content`][7])}</strong>
                    </Text>
                    <ul>
                        <li>
                            <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                                {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}CommercialControlMode.Content`][8])}
                            </Text>
                        </li>
                    </ul>
                </li>
                <li>
                    <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                        <strong>{translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}CommercialControlMode.Content`][9])}</strong>
                    </Text>
                    <ul>
                        <li>
                            <Text dark={dark} type='normal' style={{ paddingTop: 8 }}>
                                {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}CommercialControlMode.Content`][10])}
                            </Text>
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    );

    return (
        <>
            <Text dark={dark} type='subtitle' style={{ paddingBottom: 8 }}>
                {translate(TRANSLATIONS.generated[`Help.${applicationTypeTitleCase}CommercialControlMode.Title`])}
            </Text>
            {language === Language.En ? infoEnglish : infoGerman}
        </>
    );
};

export default CommercialViewConfigurationInfo;
