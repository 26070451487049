import React, { useCallback, useMemo } from 'react';
import { InputAttributes, NumericFormatProps } from 'react-number-format';
import { NumberInputItem } from '../../../models';
import { DataType } from '../../../enums';
import { MOTOR_EFFICIENCY_INPUT, SWITCHGEAR_PRIMARY_POWER_LOSS, SWITCHGEAR_SECONDARY_POWER_LOSS, TEXT_INPUT_DEBOUNCE_TIME, TRANSLATIONS } from '../../../constants';
import { getNotNegativeValidationMessage, getRangeValidationMessage, isInRange, isNotNegative } from '../../../services';
import { useTranslate, useValidate } from '../../../hooks/common';
import InputValidation from '../../common/InputValidation';
import TextInput from '../../common/TextInput';

export interface NumberInputElementProps {
    input: NumberInputItem;
    dark: boolean;
    handleSystemInputChange: (inputId: string, value: string, dataType?: DataType) => void;
}

const NumberInputElement = ({ input, dark, handleSystemInputChange }: NumberInputElementProps) => {
    const handleNumberInputElementChange = useCallback((value: string) => handleSystemInputChange(input.id, value, input.dataType), [handleSystemInputChange, input.dataType, input.id]);
    const { dynamicTranslate, translate } = useTranslate();
    const numberFormatOptions: NumericFormatProps<InputAttributes> = useMemo(() => ({
        thousandSeparator: ',',
        allowNegative: false,
        decimalSeparator: '.',
        decimalScale: 2,
        allowLeadingZeros: false,
        suffix: ` ${dynamicTranslate(TRANSLATIONS.generated, input.unit)}`
    }), [dynamicTranslate, input.unit]);

    const isValid = (value: string): boolean => {
        switch (input.id) {
            case MOTOR_EFFICIENCY_INPUT:
                return isInRange(value, 0.1, 100);
            case SWITCHGEAR_PRIMARY_POWER_LOSS:
            case SWITCHGEAR_SECONDARY_POWER_LOSS:
                return isNotNegative(value);
            default:
                return true;
        }
    };

    const { value: inputValue, setValue: setInputValue } = useValidate(input.value.toString(), isValid, handleNumberInputElementChange, TEXT_INPUT_DEBOUNCE_TIME);

    const getValidationMessage = (): string => {
        switch (input.id) {
            case MOTOR_EFFICIENCY_INPUT:
                return getRangeValidationMessage(translate, '0.1', '100');
            case SWITCHGEAR_PRIMARY_POWER_LOSS:
            case SWITCHGEAR_SECONDARY_POWER_LOSS:
                return getNotNegativeValidationMessage(translate);
            default:
                return '';
        }
    };

    return (
        <InputValidation
            valid={isValid(inputValue)}
            message={getValidationMessage()}
            dark={dark}
        >
            <TextInput
                label={dynamicTranslate(TRANSLATIONS.generated, input.name)}
                value={inputValue}
                dark={dark}
                disabled={input.isDisabled}
                name={input.name}
                numberFormatOptions={numberFormatOptions}
                onChange={setInputValue}
                error={!isValid(inputValue)}
            />
        </InputValidation>

    );
};

export default NumberInputElement;
