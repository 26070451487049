import { useCallback, useMemo } from 'react';
import { calculateAdditionalInvestments, getSystemActions } from '../../services';
import { ApplicationType, SystemType } from '../../types';
import { useAppDispatch, useAppSelector, useTranslate } from '../common';
import { TRANSLATIONS } from '../../constants';
import { GovernmentFundingCorrectionParams } from '../../models';

interface CommercialViewUtilitiesParameters {
    applicationType: ApplicationType
    systemType: SystemType
}

export const useCommercialViewUtilities = ({ applicationType, systemType }: CommercialViewUtilitiesParameters) => {
    const dispatch = useAppDispatch();
    const { translate } = useTranslate();

    const { governmentFundingDataHandler, additionalInvestmentHandler } = getSystemActions(applicationType, systemType);
    const defaultInvestmentLabel = translate(TRANSLATIONS.generated['System.AdditionalInvestment']);
    const { referenceSystemCommercialData, alternativeSystemCommercialData } = useAppSelector(state => applicationType == 'pump' ? state.pumpSystemsCommercialData : state.fanSystemsCommercialData);
    const commercialData = useMemo(() => systemType === 'reference'
        ? referenceSystemCommercialData
        : alternativeSystemCommercialData,
        [systemType, alternativeSystemCommercialData, referenceSystemCommercialData]);

    const keepGrantSumAtOrBelowMaxLimit = useCallback((newPriceParams: Partial<GovernmentFundingCorrectionParams>) => {
        const priceParams: GovernmentFundingCorrectionParams = {
            motorCustomerPrice: commercialData.motorPrices.customerPrice,
            motorExpenditure: commercialData.motorPrices.expenditureForRepairs,
            controlUnitCustomerPrice: commercialData.controlUnitPrices.customerPrice,
            controlUnitExpenditure: commercialData.controlUnitPrices.expenditureForRepairs,
            investmenInput: commercialData.additionalInvestments.additionalInvestmentItems,
            ...newPriceParams
        };

        const newInvestments = Number(priceParams.motorCustomerPrice) + Number(priceParams.motorExpenditure)
            + Number(priceParams.controlUnitCustomerPrice) + Number(priceParams.controlUnitExpenditure) + calculateAdditionalInvestments (priceParams.investmenInput);
        if (Number(commercialData.governmentFunding.grantSum) > newInvestments) {
            dispatch(governmentFundingDataHandler({
                grantRate: '100',
                grantSum: newInvestments?.toString() ?? '0'
            }));
        }
    }, [commercialData.governmentFunding.grantSum, dispatch, governmentFundingDataHandler, commercialData.additionalInvestments, commercialData.motorPrices, commercialData.controlUnitPrices]);

    const clearGovernmentFunding = useCallback(() => {
        dispatch(governmentFundingDataHandler({
            enableEdit: false,
            grantRate: '0',
            grantSum: '0'
        }));
    }, [dispatch, governmentFundingDataHandler]);

    const clearAdditionalInvestment = useCallback(() => {
        const emptyItems = [{ label: defaultInvestmentLabel, value: '' }];
        keepGrantSumAtOrBelowMaxLimit({ investmenInput: emptyItems });
        dispatch(additionalInvestmentHandler({
            enableEdit: false,
            additionalInvestmentItems: emptyItems
        }));
    }, [defaultInvestmentLabel, additionalInvestmentHandler, dispatch, keepGrantSumAtOrBelowMaxLimit]);

    return {
        clearGovernmentFunding,
        clearAdditionalInvestment,
        keepGrantSumAtOrBelowMaxLimit
    };
   
};
