import React from 'react';
import { TRANSLATIONS } from '../../constants';
import { useAppSelector, useTranslate } from '../../hooks/common';
import ContentContainer from '../common/ContentContainer';
import Flex from '../common/Flex';
import Grid from '../common/Grid';
import Image from '../common/Image';
import marketingImage from '../../../assets/images/MARKETING.png';
import marketingImageDark from '../../../assets/images/MARKETING_DARK.png';

const MarketingSection = () => {
    const { translate } = useTranslate();
    const dark = useAppSelector(state => state.layout.darkMode);

    return (
        <ContentContainer style={{ paddingTop: 240, marginBottom: 224 }}>
            <Grid columns={2} columns1500={1} columnGap={120} rowGap={80}>
                <div>
                    <div style={{ fontSize: 48, fontWeight: 900 }}>{translate(TRANSLATIONS.main.marketingSectionTitle)}</div>
                    <div style={{ paddingTop: 24 }}>
                        <ul style={{ fontSize: 18, fontWeight: 700, lineHeight: '28px' }}>
                            {Object.entries(TRANSLATIONS.main.marketingSectionListItems).map(([k, v]) => (
                                <li key={k}>
                                    {translate(v)}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <Flex>
                    <Image source={dark ? marketingImageDark : marketingImage} />
                </Flex>
            </Grid>
        </ContentContainer>
    );
};

export default MarketingSection;
