/* eslint-disable react/no-unknown-property */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TRANSLATIONS } from '../../../constants';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import Button, { ButtonType } from '../../common/Button';
import Text from '../../common/Text';
import ContentContainer from '../../common/ContentContainer';
import { setActiveTab } from '../../../store';

const CookieNotice = () => {
    const dark = useAppSelector(state => state.layout.darkMode);
    const dispatch = useDispatch();
    const { translate } = useTranslate();

    useEffect(() => {
        dispatch(setActiveTab(undefined));
    }, []);

    const renderText = () => {
        const notice: JSX.Element[] = [];

        for (let i = 1; i <= 6; i++) {
            notice.push(<p>{translate(TRANSLATIONS.cookie.notice[i.toString() as keyof typeof TRANSLATIONS.cookie.notice])}</p>);
        }

        notice.push(<p><b>{translate(TRANSLATIONS.cookie.necessaryTitle)}</b></p>);
        
        for (let i = 1; i <= 3; i++){
            notice.push(<p>{translate(TRANSLATIONS.cookie.necessaryDescription[i.toString() as keyof typeof TRANSLATIONS.cookie.necessaryDescription])}</p>);
        }

        notice.push(<p><b>{translate(TRANSLATIONS.cookie.performanceTitle)}</b></p>);

        for (let i = 1; i <= 3; i++){
            notice.push(<p>{translate(TRANSLATIONS.cookie.performanceDescription[i.toString() as keyof typeof TRANSLATIONS.cookie.performanceDescription])}</p>);
        }

        notice.push(<p><b>{translate(TRANSLATIONS.cookie.functionalityTitle)}</b></p>);

        for (let i = 1; i <= 2; i++){
            notice.push(<p>{translate(TRANSLATIONS.cookie.functionalityDescription[i.toString() as keyof typeof TRANSLATIONS.cookie.functionalityDescription])}</p>);
        }

        notice.push(<p><b>{translate(TRANSLATIONS.cookie.marketingTitle)}</b></p>);

        for (let i = 1; i <= 2; i++){
            notice.push(<p>{translate(TRANSLATIONS.cookie.marketingDescription[i.toString() as keyof typeof TRANSLATIONS.cookie.marketingDescription])}</p>);
        }

        for (let i = 1; i <= 2; i++){
            notice.push(<p>{translate(TRANSLATIONS.cookie.noticeFinal[i.toString() as keyof typeof TRANSLATIONS.cookie.noticeFinal])}</p>);
        }
        
        return notice;
    };
    
    const renderNotice = () => {
        return (
            <ContentContainer style={{ marginTop: '30px' }}>
                <Text type='title'>Cookie Notice</Text>
              <Button type={ButtonType.Primary} onClick={() => eval('UC_UI.showSecondLayer();')}>
                  Manage Preferences
              </Button>
              <ContentContainer style={{ marginTop: '30px' }}>
                  {renderText()}
              </ContentContainer>
              <div className='uc-embed' uc-data='all' uc-embedding-type='category' uc-styling='true'></div>
          </ContentContainer>  
        );
    };
    
    const renderPage = () => {
        return (
            <div className={`compass-section ${dark ? 'dark' : ''}`}>
                {renderNotice()}
            </div>
        );
    };

    return renderPage();
};
export default CookieNotice;
