import React, { CSSProperties, Fragment, useCallback, useMemo, useState } from 'react';
import { InputAttributes, NumericFormatProps } from 'react-number-format';
import { AllocationProfile, OperatingHour } from '../../../models';
import { ApplicationType } from '../../../types';
import { SPEED_RANGES_50, SPEED_RANGES_60, TEXT_INPUT_DEBOUNCE_TIME, TRANSLATIONS } from '../../../constants';
import { getRangeValidationMessage, isInRange } from '../../../services';
import { useHook } from '../../../hooks';
import { useAppSelector, useTranslate, useValidate } from '../../../hooks/common';
import Box from '../../common/Box';
import Flex from '../../common/Flex';
import Grid from '../../common/Grid';
import { IconType } from '../../common/Icon';
import IconButton from '../../common/IconButton';
import InputValidation from '../../common/InputValidation';
import Modal from '../../common/Modal';
import Select from '../../common/Select';
import SinaSaveImage from '../../common/SinaSaveImage';
import Text from '../../common/Text';
import TextInput from '../../common/TextInput';
import BasicViewApplicationCardInfo from './ModalInfo/BasicViewApplicationCardInfo';

interface BasicViewApplicationCardProps {
    applicationType: ApplicationType
}

const BasicViewApplicationCard = ({ applicationType }: BasicViewApplicationCardProps) => {
    const dark = useAppSelector(state => state.layout.darkMode);
    const isPumpSystem = applicationType === 'pump';
    const { translate } = useTranslate();
    const { application, supplyType } = useAppSelector(state => isPumpSystem ? state.pumpSystem : state.fanSystem);
    const { regionSettings } = useAppSelector(state => state.regionSettings);
    const { handleShaftPowerChange, getOperatingHourValue, handleOperatingHourChange, handleSpeedChange, getAllocationProfileName, handleAllocationChange } = useHook(x => x.systemsLoad({ applicationType }));
    const applicationMode = useAppSelector(state => isPumpSystem ? state.pumpSystem.applicationMode : state.fanSystem.applicationMode);
    const applicationModeLabel = applicationMode === 'Default' ? translate(TRANSLATIONS.generated['Common.Default']) : translate(TRANSLATIONS.generated['Common.Custom']);

    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const handleInfoModalOpen = useCallback(() => setInfoModalOpen(true), []);
    const handleInfoModalClose = useCallback(() => setInfoModalOpen(false), []);
    const imageStyle: CSSProperties = useMemo(() => ({
        maxWidth: 200
    }), []);

    const numberFormatOptions: NumericFormatProps<InputAttributes> = useMemo(() => ({
        thousandSeparator: ',',
        allowNegative: false,
        decimalSeparator: '.',
        decimalScale: 1,
        allowLeadingZeros: false
    }), []);

    const allocationProfile = useMemo(() => application?.operationProfile.allocationProfile ?? {} as AllocationProfile, [application?.operationProfile.allocationProfile]);

    const allocationProfiles = useMemo(() => application?.allocationProfiles ?? [], [application?.allocationProfiles]);

    const operatingHour = useMemo(() => application?.operationProfile.operatingHour ?? {} as OperatingHour, [application?.operationProfile.operatingHour]);

    const operatingHours = useMemo(() => application?.operatingHours ?? [], [application?.operatingHours]);

    const getLocalizedUnit = useMemo(() => {
        return regionSettings?.countryCode === 'US' ?
            ` ${translate(TRANSLATIONS.generated['System.Units.HP'])}` : ` ${translate(TRANSLATIONS.generated['System.Units.Kilowatt'])}`;
    }, [regionSettings]);

    const isShaftPowerValid = (value: string): boolean => isInRange(value, 0.1, 1000);

    const getSpeedRanges = () => supplyType?.frequency === 50 ? SPEED_RANGES_50 : SPEED_RANGES_60;

    const isSpeedValid = (value: string): boolean => {
        const number = Number(value);

        return supplyType?.frequency === undefined || getSpeedRanges().some(x => number >= x.min && number <= x.max);
    };

    const {
        value: shaftPowerInputValue,
        setValue: setShaftPowerInputValue
    } = useValidate(application?.shaftPower.toString() ?? '', isShaftPowerValid, handleShaftPowerChange, TEXT_INPUT_DEBOUNCE_TIME);

    const {
        value: speedInputValue,
        setValue: setSpeedInputValue
    } = useValidate(application?.speed.toFixed() ?? '', isSpeedValid, handleSpeedChange, TEXT_INPUT_DEBOUNCE_TIME);

    const getSpeedValidationMessage = (): JSX.Element => {
        const onePerMinutes = translate(TRANSLATIONS.generated['System.Units.Oneperminutes']);

        return (
            <>
                {translate(TRANSLATIONS.generated['Errors.SpeedNotInRange']).replace('{{FREQUENCY}}', supplyType?.frequency.toString() ?? '')}
                {getSpeedRanges().map((x, i) => <Fragment key={i}><br />{`${x.min} - ${x.max} ${onePerMinutes}`}</Fragment>)}
            </>
        );
    };
    
    return (
        <Box dark={dark} style={{ padding: 16 }}>
            <Flex justification='space-between'>
                <Text dark={dark} type='box-title' >{translate(isPumpSystem ? TRANSLATIONS.generated['System.Pump'] : TRANSLATIONS.generated['System.Fan'])}: {applicationModeLabel}</Text>
                <IconButton iconType={IconType.Information} size={16} layer={2} dark={dark} onClick={handleInfoModalOpen} />
            </Flex>
            <Flex columnGap={10} alignment='start' style={{ paddingTop: 24 }}>
                <SinaSaveImage imageName={isPumpSystem ? 'Pump' : 'Fan'} dark={dark} style={imageStyle} />
                <Grid columns={2} columnGap={16} rowGap={16} columns992={1} style={{ paddingLeft: 16, width: '100%' }}>
                    <InputValidation
                        valid={isShaftPowerValid(shaftPowerInputValue)}
                        message={getRangeValidationMessage(translate, '0.1', '1000')}
                        dark={dark}
                    >
                        <TextInput
                            dark={dark}
                            value={shaftPowerInputValue}
                            name='shaftPower'
                            numberFormatOptions={{ ...numberFormatOptions, suffix: getLocalizedUnit }}
                            label={translate(TRANSLATIONS.generated['System.RequiredShaftPowerP'])}
                            onChange={setShaftPowerInputValue}
                            error={!isShaftPowerValid(shaftPowerInputValue)}
                        />
                    </InputValidation>
                    <Select
                        dark={dark}
                        value={operatingHour}
                        values={operatingHours}
                        label={translate(TRANSLATIONS.generated['System.OperatingHoursPerYear'])}
                        onSelect={handleOperatingHourChange}
                        mapToString={getOperatingHourValue}
                    />
                    <InputValidation
                        valid={isSpeedValid(speedInputValue)}
                        message={getSpeedValidationMessage()}
                        dark={dark}
                    >
                        <TextInput
                            dark={dark}
                            value={speedInputValue}
                            name={isPumpSystem ? 'pumpSpeed' : 'fanSpeed'}
                            numberFormatOptions={{ ...numberFormatOptions, suffix: ` ${translate(TRANSLATIONS.generated['System.Units.Oneperminutes'])}` }}
                            label={translate(isPumpSystem ? TRANSLATIONS.generated['System.PumpSpeedN'] : TRANSLATIONS.generated['System.FanSpeedN'])}
                            onChange={setSpeedInputValue}
                            error={!isSpeedValid(speedInputValue)}
                        />
                    </InputValidation>
                    <Select
                        dark={dark}
                        value={allocationProfile}
                        values={allocationProfiles}
                        label={translate(TRANSLATIONS.generated['System.Allocation'])}
                        onSelect={handleAllocationChange}
                        mapToString={getAllocationProfileName} />
                </Grid>
            </Flex>
            <Modal open={infoModalOpen} onClose={handleInfoModalClose} dark={dark} allowClickAway movable>
                <BasicViewApplicationCardInfo applicationType={applicationType} />
            </Modal>
        </Box>
    );
};

export default BasicViewApplicationCard;
