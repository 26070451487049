import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, useTranslate } from '../common';
import { ApplicationType } from '../../types';
import { getApplicationActions } from '../../services/ApplicationActions';
import { TEXT_INPUT_DEBOUNCE_TIME, TRANSLATIONS } from '../../constants';
import { useDebounceCallback } from '../common/DebounceCallback';

export interface LifeTimeCostsProps {
    applicationType: ApplicationType;
}

export const useLifetimeCosts = ({ applicationType }: LifeTimeCostsProps) => {
    const dispatch = useAppDispatch();
    const { translate } = useTranslate();
    const isPumpSystem = applicationType === 'pump';
    const { lifetimeCostsChartData, lifetimeCostsChartTabData } = useAppSelector(state => isPumpSystem ? state.pumpSystemChart : state.fanSystemChart);
    const { lifetimeCostsParamsHandler } = getApplicationActions(applicationType);
    const chartOptionParameters = useAppSelector(state => isPumpSystem
        ? state.pumpSystemChart.lifetimeCostsChartTabParams : state.fanSystemChart.lifetimeCostsChartTabParams);
    const [commercialFeedback, setCommercialFeedback] = useState('');
    const [isCommercialFeedbackPositive, setIsCommercialFeedbackPositive] = useState(true);

    const handleChangeNumberOfYears = (yrsOfOperation: string) => {
        changeNumberOfYears(yrsOfOperation, true);
    };

    const changeNumberOfYears = useDebounceCallback(useCallback((yrsOfOperation: string, userInput: boolean) => {
        if (isNaN(Number.parseInt(yrsOfOperation))) {
            dispatch(lifetimeCostsParamsHandler({ ...chartOptionParameters, lifetimeNumberOfYearsString: yrsOfOperation }));
        } else {
            dispatch(lifetimeCostsParamsHandler({ ...chartOptionParameters, lifetimeNumberOfYears: Number.parseInt(yrsOfOperation), lifetimeNumberOfYearsString: yrsOfOperation, lifetimeNumberOfYearsUserInput: userInput }));
        }
    }, [dispatch, chartOptionParameters, lifetimeCostsParamsHandler]), TEXT_INPUT_DEBOUNCE_TIME);

    const handleShowSavings = () => {
        dispatch(lifetimeCostsParamsHandler({ ...chartOptionParameters, showSavings: !chartOptionParameters.showSavings }));
    };

    useEffect(() => {
        if (!lifetimeCostsChartTabData?.amortizationResult.amortizationYears) {
            return;
        }
        if (lifetimeCostsChartTabData?.amortizationResult.amortizationYears <= 0) {
            // Cases for no possible break even point
            if (lifetimeCostsChartTabData?.referenceEnergyCost > lifetimeCostsChartTabData.alternativeEnergyCost) {
                setCommercialFeedback(translate(TRANSLATIONS.generated['System.InfoCard.Case2']));
                setIsCommercialFeedbackPositive(true);
            } else {
                setCommercialFeedback(translate(TRANSLATIONS.generated['System.InfoCard.Case3']));
                setIsCommercialFeedbackPositive(false);
            }
            return;
        }
        if (lifetimeCostsChartTabData?.amortizationResult.amortizationYears < chartOptionParameters.lifetimeNumberOfYears) {
            // Cases for a visible break even point
            if (lifetimeCostsChartTabData?.referenceEnergyCost > lifetimeCostsChartTabData.alternativeEnergyCost) {
                setCommercialFeedback(translate(TRANSLATIONS.generated['System.InfoCard.Case1A']));
                setIsCommercialFeedbackPositive(true);
            } else {
                setCommercialFeedback(translate(TRANSLATIONS.generated['System.InfoCard.Case4A']));
                setIsCommercialFeedbackPositive(false);
            }
        } else {
            // Cases for the break even point outside the scope of the chart
            if (lifetimeCostsChartTabData.referenceInvestmentCost > lifetimeCostsChartTabData.alternativeInvestmentCost) {
                setCommercialFeedback(translate(TRANSLATIONS.generated['System.InfoCard.Case4B']).replace('{{paybacktime}}', lifetimeCostsChartTabData.amortizationResult.amortizationYears.toString()));
                setIsCommercialFeedbackPositive(true);
            } else {
                setCommercialFeedback(translate(TRANSLATIONS.generated['System.InfoCard.Case1B']).replace('{{paybacktime}}', lifetimeCostsChartTabData.amortizationResult.amortizationYears.toString()).replace('{{x}}', chartOptionParameters.lifetimeNumberOfYears.toString()));
                setIsCommercialFeedbackPositive(false);
            }
        }
    }, [
        lifetimeCostsChartData?.intersectionPoints,
        translate,
        chartOptionParameters.lifetimeNumberOfYears,
        lifetimeCostsChartTabData?.amortizationResult,
        lifetimeCostsChartTabData?.referenceEnergyCost,
        lifetimeCostsChartTabData?.alternativeEnergyCost,
        lifetimeCostsChartTabData?.alternativeInvestmentCost,
        lifetimeCostsChartTabData?.referenceInvestmentCost
    ]);

    useEffect(() => {
        if (lifetimeCostsChartData?.months) {
            const chartYears = Math.round(lifetimeCostsChartData.months[lifetimeCostsChartData.months.length - 1] / 12);
            if (chartYears !== chartOptionParameters.lifetimeNumberOfYears || chartOptionParameters.lifetimeNumberOfYearsUserInput === true) {
                changeNumberOfYears(chartYears.toString(), false);
            }
        }
    }, [lifetimeCostsChartTabData?.amortizationResult.amortizationYears, lifetimeCostsChartData?.months, changeNumberOfYears]);
   
    return {
        handleChangeNumberOfYears,
        lifetimeCostsChartData,
        lifetimeCostsChartTabData,
        handleShowSavings,
        chartOptionParameters,
        commercialFeedback,
        isCommercialFeedbackPositive
    };
};
