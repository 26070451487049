import React, { useRef, FocusEvent, useState } from 'react';
import { useOpenAnimations } from '../../hooks/common';
import BoxTabSwitcherDropdownItem from './BoxTabSwitcherDropdownItem';
import Icon, { IconType } from './Icon';
import MenuDividerItem from './MenuDividerItem';

export interface BoxTabSwitcherItem {
    icon: IconType;
    id: string;
    label: string;
    key?: string;
}

interface BoxTabSwitcherProps {
    items: BoxTabSwitcherItem[];
    onSelect: (id: string) => void;
    selectedId: string;
    dark?: boolean;
}

const BoxTabSwitcher = ({ items, onSelect, selectedId, dark }: BoxTabSwitcherProps) => {
    const buttonRef = useRef<HTMLDivElement>(null);
    const [boxTabSwitcherOpen, setBoxTabSwitcherOpen] = useState(false);
    const [animationClass, handleAnimationEnd] = useOpenAnimations(boxTabSwitcherOpen);
    const selected = items.find(x => x.id === selectedId);

    const handleBlur = (event: FocusEvent<HTMLDivElement>) => {
        if (!event.relatedTarget || !buttonRef.current || !buttonRef.current.contains(event.relatedTarget as HTMLElement)) {
            setBoxTabSwitcherOpen(false);
        }
    };

    const handleSelect = (id: string) => {
        onSelect(id);
        setBoxTabSwitcherOpen(false);
    };

    return (
        <div ref={buttonRef} className={`box-tab-switcher ${dark ? 'dark' : ''} ${animationClass}`} tabIndex={-1} onBlur={handleBlur} >
                {<div className='box-tab-switcher-selected-item' onClick={() => setBoxTabSwitcherOpen(!boxTabSwitcherOpen)}>
                        <div className='box-tab-switcher-selected-item-content' >
                            <div className='box-tab-switcher-selected-item-icon'>
                                {selected && <Icon type={selected.icon} />}
                            </div>
                            <div className='box-tab-switcher-selected-item-label'>
                                {selected && selected.label}
                            </div>
                        </div>
                        <div className='box-tab-switcher-selected-item-arrow'>
                            <Icon type={IconType.ArrowheadDown} />
                        </div>
                </div>}
                <div className='box-tab-switcher-dropdown-container'>
                    <div className='box-tab-switcher-dropdown' onClick={e => e.stopPropagation()} onAnimationEnd={handleAnimationEnd}>
                        {items.filter(x => x.id !== selectedId).map((x, i) =>
                            <div key={x.key}>
                                {i !== 0 && <MenuDividerItem dark={dark} key={`${x.label}-divider`} />}
                                <BoxTabSwitcherDropdownItem
                                    dark={dark}
                                    icon={x.icon}
                                    key={x.id}
                                    label={x.label}
                                    onClick={() => handleSelect(x.id)}
                                />
                            </div>)
                        }
                    </div>
                </div>
        </div>
    );
};

export default BoxTabSwitcher;
