import { useCallback, useMemo } from 'react';
import { GovernmentFundingData } from '../../models';
import { priceExchanger } from '../../services';
import { useAppSelector } from '../common';

interface GovernmentFundingParameters {
    totalInvestment: number;
    exchangedTotalInvestment: number;
    governmentFundingData: GovernmentFundingData;
    onGovernmentFundingDataChange: (value: Partial<GovernmentFundingData>) => void;
}

export const useGovernmentFunding = ({ governmentFundingData, onGovernmentFundingDataChange, totalInvestment, exchangedTotalInvestment }: GovernmentFundingParameters) => {
    const { regionSettings, defaultCurrency } = useAppSelector(x => x.regionSettings);
    const grantSum = useMemo(() =>
        priceExchanger(
            Number(governmentFundingData.grantSum) ?? (totalInvestment * (Number(governmentFundingData.grantRate) / 100)),
            defaultCurrency,
            regionSettings?.currency
        )?.toString() ?? '0',
        [defaultCurrency, governmentFundingData.grantRate, governmentFundingData.grantSum, regionSettings?.currency, totalInvestment]);
    const grantRate = useMemo(() => 
        exchangedTotalInvestment !== 0 ? ((Number(grantSum) / exchangedTotalInvestment) * 100).toString() : governmentFundingData.grantRate.toString(),
        [exchangedTotalInvestment, governmentFundingData.grantRate, grantSum]);
    const nettoInvestment = totalInvestment - Number(governmentFundingData.grantSum);
    const exchangedNettoInvestment = priceExchanger(nettoInvestment, defaultCurrency, regionSettings?.currency);

    const handleGrantRateChange = useCallback((grantRate: string) => {
        const grantSum = totalInvestment * (Number(grantRate) / 100);

        onGovernmentFundingDataChange({
            grantRate: grantRate,
            grantSum: grantSum.toString() ?? '0'
        });
    }, [onGovernmentFundingDataChange, totalInvestment]);

    const handleGrantSumChange = useCallback((grantSum: string) => {
        const exchangedGrantSum = priceExchanger(Number(grantSum) ?? 0, regionSettings?.currency, defaultCurrency) ?? 0;

        onGovernmentFundingDataChange({
            grantRate: (exchangedGrantSum ?? 0 / totalInvestment * 100).toString(),
            grantSum: exchangedGrantSum?.toString()
        });
    }, [defaultCurrency, onGovernmentFundingDataChange, regionSettings?.currency, totalInvestment]);

    const handleEnableEditChange = useCallback((value: boolean) => {
        onGovernmentFundingDataChange({
            enableEdit: value
        });
    }, [onGovernmentFundingDataChange]);

    const handleModalOpenChange = useCallback((value: boolean) => {
        onGovernmentFundingDataChange({
                isModalOpen: value
        });
    }, [onGovernmentFundingDataChange]);

    return {
        grantRate,
        grantSum,
        handleEnableEditChange,
        handleGrantRateChange,
        handleGrantSumChange,
        handleModalOpenChange,
        nettoInvestment,
        exchangedNettoInvestment
    };
};
