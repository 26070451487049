import { ApplicationType } from '../types';
import {
    setFanAllocationProfile,
    setFanAlternativeSystem,
    setFanAlternativeSystemCommercialData,
    setFanAlternativeSystemInvestmentCosts,
    setFanAlternativeSystemRequestParameters,
    setFanApplicationMode,
    setFanOperatingHour,
    setFanPowerLossCalculationChartTabParams,
    setFanReferenceSystem,
    setFanReferenceSystemCommercialData,
    setFanReferenceSystemInvestmentCosts,
    setFanReferenceSystemRequestParameters,
    setFanRequestParameters,
    setFanShaftPower,
    setFanSpeed,
    setFanSupplyType,
    setFanSupplyTypes,
    setFanSystemsLoading,
    setPumpAllocationProfile,
    setPumpAlternativeSystem,
    setPumpAlternativeSystemCommercialData,
    setPumpAlternativeSystemInvestmentCosts,
    setPumpAlternativeSystemRequestParameters,
    setPumpApplicationMode,
    setPumpOperatingHour,
    setPumpReferenceSystem,
    setPumpReferenceSystemCommercialData,
    setPumpReferenceSystemInvestmentCosts,
    setPumpReferenceSystemRequestParameters,
    setPumpRequestParameters,
    setPumpShaftPower,
    setPumpSpeed,
    setPumpSupplyType,
    setPumpSupplyTypes,
    setPumpSystemsLoading,
    setPumpPowerLossCalculationChartTabParams,
    setPumpEnergyPerformanceChartData,
    setFanEnergyPerformanceChartData,
    setPumpEnergyPerformanceChartTabData,
    setFanEnergyPerformanceChartTabData,
    setPumpEnergySavingsChartData,
    setFanEnergySavingsChartData,
    setPumpEnergySavingsChartTabData,
    setFanEnergySavingsChartTabData,
    setPumpLifetimeCostsChartData,
    setFanLifetimeCostsChartData,
    setPumpLifetimeCostsChartTabData,
    setFanLifetimeCostsChartTabData,
    setPumpPowerlossCalculationChartData,
    setFanPowerlossCalculationChartData,
    setPumpChartDataLoading,
    setFanChartDataLoading,
    setPumpSelectedComparisonCardTabId,
    setFanSelectedComparisonCardTabId,
    setPumpFullScreenChart,
    setFanFullScreenChart,
    setPumpEnergySavingsChartTabParams,
    setFanEnergySavingsChartTabParams,
    setPumpEnergyPerformanceChartTabParams,
    setFanEnergyPerformanceChartTabParams,
    setPumpLifetimeCostsChartTabParams,
    setFanLifetimeCostsChartTabParams,
    setDefaultPumpInputs,
    setDefaultFanInputs,
    setPumpAlternativeSystemInitialHash,
    setFanAlternativeSystemInitialHash,
    setPumpReferenceSystemInitialHash,
    setFanReferenceSystemInitialHash
} from '../store';

import { getActionCreatorForCondition } from './Utilities';

export const getApplicationActions = (applicationType: ApplicationType) => {
    const isPumpApplication = applicationType === 'pump';
    const applicationModeHandler = getActionCreatorForCondition(isPumpApplication, setPumpApplicationMode, setFanApplicationMode);
    const applicationRequestHandler = getActionCreatorForCondition(isPumpApplication, setPumpRequestParameters, setFanRequestParameters);
    const allocationProfileHandler = getActionCreatorForCondition(isPumpApplication, setPumpAllocationProfile, setFanAllocationProfile);
    const alternativeSystemCommercialDataHandler = getActionCreatorForCondition(isPumpApplication, setPumpAlternativeSystemCommercialData, setFanAlternativeSystemCommercialData);
    const alternativeSystemHandler = getActionCreatorForCondition(isPumpApplication, setPumpAlternativeSystem, setFanAlternativeSystem);
    const alternativeSystemInvestmentCostHandler = getActionCreatorForCondition(isPumpApplication, setPumpAlternativeSystemInvestmentCosts, setFanAlternativeSystemInvestmentCosts);
    const alternativeSystemRequestParameterHandler = getActionCreatorForCondition(isPumpApplication, setPumpAlternativeSystemRequestParameters, setFanAlternativeSystemRequestParameters);
    const operatingHourHandler = getActionCreatorForCondition(isPumpApplication, setPumpOperatingHour, setFanOperatingHour);
    const powerLossCalculationChartTabParamsHandler = getActionCreatorForCondition(isPumpApplication, setPumpPowerLossCalculationChartTabParams, setFanPowerLossCalculationChartTabParams);
    const referenceSystemCommercialDataHandler = getActionCreatorForCondition(isPumpApplication, setPumpReferenceSystemCommercialData, setFanReferenceSystemCommercialData);
    const referenceSystemHandler = getActionCreatorForCondition(isPumpApplication, setPumpReferenceSystem, setFanReferenceSystem);
    const referenceSystemInvestmentCostHandler = getActionCreatorForCondition(isPumpApplication, setPumpReferenceSystemInvestmentCosts, setFanReferenceSystemInvestmentCosts);
    const referenceSystemRequestParameterHandler = getActionCreatorForCondition(isPumpApplication, setPumpReferenceSystemRequestParameters, setFanReferenceSystemRequestParameters);
    const shaftPowerHandler = getActionCreatorForCondition(isPumpApplication, setPumpShaftPower, setFanShaftPower);
    const speedHandler = getActionCreatorForCondition(isPumpApplication, setPumpSpeed, setFanSpeed);
    const supplyTypeHandler = getActionCreatorForCondition(isPumpApplication, setPumpSupplyType, setFanSupplyType);
    const supplyTypesHandler = getActionCreatorForCondition(isPumpApplication, setPumpSupplyTypes, setFanSupplyTypes);
    const systemsLoadingHandler = getActionCreatorForCondition(isPumpApplication, setPumpSystemsLoading, setFanSystemsLoading);
    const energyPerformanceChartDataHandler = getActionCreatorForCondition(isPumpApplication, setPumpEnergyPerformanceChartData, setFanEnergyPerformanceChartData);
    const energyPerformanceChartTabDataHandler = getActionCreatorForCondition(isPumpApplication, setPumpEnergyPerformanceChartTabData, setFanEnergyPerformanceChartTabData);
    const energySavingsChartDataHandler = getActionCreatorForCondition(isPumpApplication, setPumpEnergySavingsChartData, setFanEnergySavingsChartData);
    const energySavingsChartTabDataHandler = getActionCreatorForCondition(isPumpApplication, setPumpEnergySavingsChartTabData, setFanEnergySavingsChartTabData);
    const lifetimeCostsChartDataHandler = getActionCreatorForCondition(isPumpApplication, setPumpLifetimeCostsChartData, setFanLifetimeCostsChartData);
    const lifetimeCostsChartTabDataHandler = getActionCreatorForCondition(isPumpApplication, setPumpLifetimeCostsChartTabData, setFanLifetimeCostsChartTabData);
    const powerlossCalculationChartDataHandler = getActionCreatorForCondition(isPumpApplication, setPumpPowerlossCalculationChartData, setFanPowerlossCalculationChartData);
    const chartDataLoadingHandling = getActionCreatorForCondition(isPumpApplication, setPumpChartDataLoading, setFanChartDataLoading);
    const selectedComparisonCardTabIdHandler = getActionCreatorForCondition(isPumpApplication, setPumpSelectedComparisonCardTabId, setFanSelectedComparisonCardTabId);
    const fullScreenChartHandler = getActionCreatorForCondition(isPumpApplication, setPumpFullScreenChart, setFanFullScreenChart);
    const energySavingsParamsHandler = getActionCreatorForCondition(isPumpApplication, setPumpEnergySavingsChartTabParams, setFanEnergySavingsChartTabParams);
    const energyPerformanceParamsHandler = getActionCreatorForCondition(isPumpApplication, setPumpEnergyPerformanceChartTabParams, setFanEnergyPerformanceChartTabParams);
    const lifetimeCostsParamsHandler = getActionCreatorForCondition(isPumpApplication, setPumpLifetimeCostsChartTabParams, setFanLifetimeCostsChartTabParams);
    const defaultApplicationInputsHandler = getActionCreatorForCondition(isPumpApplication, setDefaultPumpInputs, setDefaultFanInputs);
    const alternativeSystemInitialHashHandler = getActionCreatorForCondition(isPumpApplication, setPumpAlternativeSystemInitialHash, setFanAlternativeSystemInitialHash);
    const referenceSystemInitialHashHandler = getActionCreatorForCondition(isPumpApplication, setPumpReferenceSystemInitialHash, setFanReferenceSystemInitialHash);

    return {
        applicationModeHandler,
        applicationRequestHandler,
        allocationProfileHandler,
        alternativeSystemCommercialDataHandler,
        alternativeSystemHandler,
        alternativeSystemInvestmentCostHandler,
        alternativeSystemRequestParameterHandler,
        operatingHourHandler,
        powerLossCalculationChartTabParamsHandler,
        referenceSystemCommercialDataHandler,
        referenceSystemHandler,
        referenceSystemInvestmentCostHandler,
        referenceSystemRequestParameterHandler,
        shaftPowerHandler,
        speedHandler,
        supplyTypeHandler,
        supplyTypesHandler,
        systemsLoadingHandler,
        energyPerformanceChartDataHandler,
        energyPerformanceChartTabDataHandler,
        energySavingsChartDataHandler,
        energySavingsChartTabDataHandler,
        lifetimeCostsChartDataHandler,
        lifetimeCostsChartTabDataHandler,
        lifetimeCostsParamsHandler,
        powerlossCalculationChartDataHandler,
        chartDataLoadingHandling,
        selectedComparisonCardTabIdHandler,
        fullScreenChartHandler,
        energySavingsParamsHandler,
        energyPerformanceParamsHandler,
        defaultApplicationInputsHandler,
        alternativeSystemInitialHashHandler,
        referenceSystemInitialHashHandler
    };
};
