import React, { CSSProperties, ReactNode } from 'react';

interface SpacingContainerProps {
    children?: ReactNode;
    spacing?: number;
    style?: CSSProperties;
}

const SpacingContainer = ({ children, spacing, style }: SpacingContainerProps) => (
    <div className='spacing-container' style={{ ...style, ['--spacing' as string]: `${spacing ?? 8}px` }}>
        {children}
    </div>
);

export default SpacingContainer;
